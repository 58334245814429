export const developmentPlanTranslations = {
  es: {
    title: 'Plan de Desarrollo',
    titleOf: 'Plan de Desarrollo de:',
    dominanceText: 'Cuadrante elegido como foco de desarrollo:',
    talentsToDevelop: 'Talentos a desarrollar',
    actionsText: 'Para desarrollar estas competencias, te sugerimos poner en práctica las siguientes acciones específicas:'
  },
  en: {
    title: 'Development Plan',
    titleOf: 'Development Plan of:',
    dominanceText: 'Focused quadrant:',
    talentsToDevelop: 'Talents to develop',
    actionsText: 'To develop these skills, we recommend the following practice actions:'
  }
}