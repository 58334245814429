/* eslint-disable @typescript-eslint/naming-convention */
import { FC, useState, useEffect } from 'react'
import { useNavigate, Link } from 'react-router-dom'
import EditIcon from 'assets/icons/EditCoin'
import DisassociateIcon from 'assets/icons/DIsassociate'
import DeleteIcon from 'assets/icons/DeleteIcon'
import DownloadIcon from 'assets/icons/DownloadIcon'
import PlusWithCircle from 'assets/icons/PlusWithCircle'
import FinishIcon from 'assets/icons/FinishIcon'
import RollBackIcon from 'assets/icons/RollBackIcon'
import NegativeWithRedCircle from 'assets/icons/NegativeWithRedCircle'
import { useNotification } from 'lib/context/notification.context'

import { useSelectedItems } from '../../../../screens/Platform-administrator/EvaluatedSpace/context/context'

import { superAdminRoutes, platformAdminRoutes, specialAdminRoutes } from 'router/routes'
import {
  Container,
  RowContainer,
  TextBoldBlue,
  ContainerActionItem,
  Row,
  DisassociateText,
  DeleteText,
  TextBoldViolet,
  DisabledBoldText,
  ContainerActions,
  TextInterpretation,
} from './styled'
import { StrokedButton, Button } from '../../Buttons'
import { ADMIN, EVALUATE_USER_ROLE, LEAD_ADMIN } from './constants'
import { IAction, IActionSendReminder } from '../store/entities'
import { useTranslation } from 'react-i18next'

interface IActionEdit {
  id: string | number
  level_id: number
}

/**
 * @description Action to edit a company
 * @param item
 * */
export const ActionEditCompany = (item: IActionEdit) => {
  const navigate = useNavigate()
  const { id } = item
  return (
    <Container>
      <TextBoldViolet onClick={() => navigate(`${superAdminRoutes.CompanyInfo}?companyId=${id}`)}>
        Ver más
      </TextBoldViolet>
      <RowContainer onClick={() => navigate(`${superAdminRoutes.UpdateCompany}?companyId=${id}`)}>
        <EditIcon />
        <TextBoldBlue>Editar</TextBoldBlue>
      </RowContainer>
    </Container>
  )
}

/**
 * @description Action to edit a skill
 */
export const ActionEditSkill = (item: IActionEdit) => {
  const { id, level_id: LevelId } = item
  return (
    <Container>
      <Link to={`/${specialAdminRoutes.UpdateSkill}?id=${id}&level=${LevelId}`}>
        <RowContainer>
          <EditIcon />
          <TextBoldBlue>Editar</TextBoldBlue>
        </RowContainer>
      </Link>
    </Container>
  )
}

export const ActionEditSkillQuestion = (item: IActionEdit) => {
  const { id } = item
  return (
    <Container>
      <Link to={`${superAdminRoutes.UpdateSkillQuestion}?id=${id}`} style={{ cursor: 'pointer' }}>
        <RowContainer>
          <EditIcon />
          <TextBoldBlue>Editar</TextBoldBlue>
        </RowContainer>
      </Link>
    </Container>
  )
}

interface IActionEditQuestionnaire {
  id: string | number
  skill: string // name of the questionnaire
}
/**
 * @description Action to edit a questionnaire
 */
export const ActionEditQuestionnaire = (props: IActionEditQuestionnaire) => {
  const { id, skill } = props
  return (
    <Link to={superAdminRoutes.SettingQuestionaire} state={{ questionnaireId: id, questionnaireName: skill }}>
      <ContainerActionItem>
        <EditIcon />
        <TextBoldBlue>Editar</TextBoldBlue>
      </ContainerActionItem>
    </Link>
  )
}

interface IActionEditAndDisassociateQuestions {
  id: string | number
  type_question: string
  questionnaireId: number
  chapter_id: number
  onDissasociate: (id: number | string) => void
}

/**
 * @description Action to edit and disassociate a question
 * @param props
 * */
export const ActionEditAndDisassociateQuestions = (props: IActionEditAndDisassociateQuestions) => {
  const { id, type_question: typeQuestion, questionnaireId, chapter_id: chapterId, onDissasociate } = props
  return (
    <Row width="56%" marginLeft="46%">
      <Link
        to={superAdminRoutes.UpdateQuestionQuestionaire}
        state={{ questionId: id, typeQuestion, questionnaireId, chapterId }}
      >
        <ContainerActionItem>
          <EditIcon />
          <TextBoldBlue>Editar</TextBoldBlue>
        </ContainerActionItem>
      </Link>
      <ContainerActionItem onClick={() => onDissasociate(id)}>
        <DisassociateIcon />
        <DisassociateText>Desasociar pregunta</DisassociateText>
      </ContainerActionItem>
    </Row>
  )
}

interface IActionAddTalent {
  onAddTalent: (props: any) => void
  onViewTalent: (props: any) => void
  idTalentsSelected: number[]
  id: number
}

/**
 * @description Action add talent
 */
export const ActionAddTalent = (props: IActionAddTalent) => {
  const { onAddTalent, onViewTalent, idTalentsSelected } = props

  function isTalentSelected(idTalent: number) {
    return idTalentsSelected.includes(idTalent)
  }

  return (
    <Row width="60%" marginLeft="44%">
      <RowContainer>
        <TextBoldViolet onClick={() => onViewTalent(props)}>Ver</TextBoldViolet>
      </RowContainer>

      <RowContainer>
        <StrokedButton
          disabled={isTalentSelected(props.id) || idTalentsSelected.length >= 3}
          onClick={() => !isTalentSelected(props.id) && onAddTalent(props)}
        >
          Agregar
        </StrokedButton>
      </RowContainer>
    </Row>
  )
}

interface IActionDesassociateTalent {
  onDeleteTalent: (props: any) => void
  onViewTalent: () => void
}

/**
 * @description Action to desassociate a talent of an development plan
 */
export const ActionDesassociateTalent = (props: IActionDesassociateTalent) => {
  const { onDeleteTalent, onViewTalent } = props
  return (
    <Row width="56%" marginLeft="46%">
      <RowContainer>
        <TextBoldViolet onClick={onViewTalent}>Ver</TextBoldViolet>
      </RowContainer>
      <RowContainer onClick={() => onDeleteTalent(props)}>
        <DeleteIcon />
        <DeleteText>Eliminar</DeleteText>
      </RowContainer>
    </Row>
  )
}

/**
 * @description Action to view info of user by company
 * */
export const ActionViewInfoUserByCompany = (props: any) => {
  const navigate = useNavigate()

  const { active, complete, edit, ...rest } = props

  const user_name = rest?.first_name + ' ' + rest?.last_name

  const body = { user_name, ...rest }

  return (
    <RowContainer
      onClick={() => {
        navigate(superAdminRoutes.InfoEvaluatedUserByCompany, { state: { userData: body } })
      }}
    >
      <TextBoldViolet style={{ alignSelf: 'flex-end' }}>Ver</TextBoldViolet>
    </RowContainer>
  )
}

interface IActionEditUser {
  onSelectUserId: (id: number) => void
  id: number
  role: string
  company_id: number
  userId: number
  process_ids: number[]
  first_name: string
}

/**
 * @description Action to view o edit an user
 */
export const ActionViewEditUser = (props: IActionEditUser) => {
  const { onSelectUserId, userId } = props

  const viewByUser =
    props?.role === ADMIN.platform || props?.role === LEAD_ADMIN
      ? superAdminRoutes.PlatformAdminsByCompany
      : props?.role === EVALUATE_USER_ROLE
      ? superAdminRoutes.InfoEvaluatedUserByCompany
      : superAdminRoutes.InfoEvaluatedUserByCompany

  const stateUser =
    props?.role === ADMIN.platform || props?.role === LEAD_ADMIN
      ? { companyId: props.company_id }
      : props?.role === EVALUATE_USER_ROLE
      ? {
          userData: {
            companyId: props.company_id,
            id: props.userId,
            process_id: props.process_ids[0],
            user_name: props.first_name,
          },
        }
      : null

  return (
    <Row width="80%" marginLeft="30%">
      {props.role === ADMIN.platform || props.role === LEAD_ADMIN ? (
        <></>
      ) : (
        <RowContainer>
          <Link to={viewByUser || '/'} state={stateUser}>
            <TextBoldViolet>Ver</TextBoldViolet>
          </Link>
        </RowContainer>
      )}
      {(props.role === ADMIN.platform || props.role === LEAD_ADMIN) && (
        <RowContainer
          onClick={() => {
            if (onSelectUserId) onSelectUserId(userId)
          }}
        >
          <EditIcon />
          <TextBoldBlue>Editar</TextBoldBlue>
        </RowContainer>
      )}
    </Row>
  )
}

// :::::::::: PLATFORM ADMIN :::::::::::::: //

/**
 * @description Action to view or download a report of a space of evaluated users
 */
export const ActionViewDownloadReportSpaceUserEvaluated = (
  props: IAction & { complete: number; handleDownload: (id: number) => void }
) => {
  const selectedItems = useSelectedItems()
  const isDisabled = selectedItems.size > 1
  // const isDisabledCompleted = props.complete === 0

  return (
    <Row width="108%">
      <RowContainer>
        {isDisabled ? (
          <TextBoldViolet isDisabled={true}>Ver</TextBoldViolet>
        ) : (
          <Link
            to={platformAdminRoutes.DetailEvaluatedUser}
            state={{ userId: props.id, processId: props.process, completed: props.complete }}
          >
            <TextBoldViolet>Ver</TextBoldViolet>
          </Link>
        )}
      </RowContainer>
      {/* <RowContainer>
        <DownloadIcon style={{ color: isDisabled || isDisabledCompleted ? '#A9A9A9' : '#2D749C' }} />
        <TextBoldBlue
          onClick={() => {
            if (isDisabled || isDisabledCompleted) {
              return
            }
            props.handleDownload(props.id)
          }}
          isDisabled={isDisabled || isDisabledCompleted}
        >
          Descargar reportes
        </TextBoldBlue>
      </RowContainer> */}
    </Row>
  )
}

interface IActionAddSkill {
  handleAddSkill: (props: any) => void
  onEditSkill: (props: any) => void
  skillsSelected: number[]
  id: number
}

/**
 * @description Action add skill to development plan
 */
export const ActionAddSkillToDevelop = (props: IActionAddSkill) => {
  const { handleAddSkill, onEditSkill, skillsSelected } = props

  function isSkillSelected(idSkill: number) {
    if (!skillsSelected) return false
    const idSkills = skillsSelected.map((skill: any) => skill.id)
    return idSkills.includes(idSkill)
  }

  return (
    <Row width="60%" marginLeft="44%">
      <RowContainer>
        <TextBoldViolet onClick={() => onEditSkill(props)}>Ver</TextBoldViolet>
      </RowContainer>

      <RowContainer>
        <StrokedButton
          disabled={isSkillSelected(props.id) || skillsSelected.length >= 3}
          onClick={() => !isSkillSelected(props.id) && handleAddSkill(props)}
        >
          Agregar
        </StrokedButton>
      </RowContainer>
    </Row>
  )
}

interface IActionAddSkill {
  onDeleteSkill: (props: any) => void
  onViewSkill: (props: any) => void
  skillsSelected: number[]
  id: number
}
/**
 * @description Action delete skill to development plan
 */
export const ActionDeleteSkillToDevelop = (props: IActionAddSkill) => {
  const { onDeleteSkill, onEditSkill } = props

  return (
    <Row width="60%" marginLeft="44%">
      <RowContainer>
        <TextBoldViolet onClick={() => onEditSkill(props)}>Ver</TextBoldViolet>
      </RowContainer>
      <RowContainer>
        <DeleteIcon />
        <DeleteText onClick={() => onDeleteSkill(props)}>Eliminar</DeleteText>
      </RowContainer>
    </Row>
  )
}
interface IActionAddTalent {
  onDeleteTalent: (props: any) => void
  onViewTalent: (props: any) => void
  talentsSelected: number[]
  id: number
}

/**
 * @description Action des-select talent to development plan
 */
export const ActionDeleteTalentToDevelop = (props: IActionAddTalent) => {
  const { onDeleteTalent, onViewTalent } = props

  return (
    <Row width="60%" marginLeft="44%">
      <RowContainer>
        <TextBoldViolet onClick={() => onViewTalent(props)}>Ver</TextBoldViolet>
      </RowContainer>

      <RowContainer>
        <DeleteIcon />
        <DeleteText onClick={() => onDeleteTalent(props)}>Eliminar</DeleteText>
      </RowContainer>
    </Row>
  )
}

interface IActionEditProcess {
  onFinishProcess: (id: number) => void
  id: number
}
/**
 * @description Action edit draft process
 */
export const ActionEditDraftProcess = (item: IActionEditProcess) => {
  const { onFinishProcess, id } = item

  return (
    <Container>
      <RowContainer onClick={() => onFinishProcess(id)}>
        <FinishIcon />
        <TextBoldBlue>Terminar</TextBoldBlue>
      </RowContainer>
    </Container>
  )
}

type IDownloadProcess = {
  id: number
  users: number[]
  name: string
  competencesTest: boolean
  betesaTest: boolean
}

interface IActionViewDownloadAddUsers {
  onAddUsers: (id: number) => void
  onViewProcess: (id: number) => void
  onDownloadProcess: (data: IDownloadProcess) => void
  id: number
  users: number[]
  name: string
  competences_test: boolean
  betesa_test: boolean
  isDisabled?: boolean
}

/**
 * @description Action to view, download and add users to process
 */
export const ActionViewDownloadAddUsers = (props: IActionViewDownloadAddUsers) => {
  const {
    onAddUsers,
    onViewProcess,
    onDownloadProcess,
    id,
    users,
    name,
    competences_test: competencesTest,
    betesa_test: betesaTest,
    isDisabled
  } = props

  return (
    <Container style={{ marginLeft: '-90%', width: '160%' }}>
      <RowContainer width="20%">
        <TextBoldViolet onClick={() => onViewProcess(id)} style={{ margin: '0px', fontSize: '0.8rem' }}>
          Ver
        </TextBoldViolet>
      </RowContainer>
      <RowContainer width="40%">
        <DownloadIcon style={{ color: isDisabled ? '#A9A9A9' : '#2D749C' }} />
        <TextBoldBlue
          onClick={() => !isDisabled && onDownloadProcess({ id, users, name, betesaTest, competencesTest })}
          style={{ fontSize: '0.8rem' }}
          isDisabled={isDisabled}
        >
          Descargar resumen
        </TextBoldBlue>
      </RowContainer>
      <RowContainer width="30%">
        <PlusWithCircle />
        <TextBoldBlue onClick={() => onAddUsers(id)} style={{ fontSize: '0.8rem' }}>
          Administrar usuarios
        </TextBoldBlue>
      </RowContainer>
    </Container>
  )
}

interface IActionViewDownloadReportProcessCreated {
  listIdUsers: Set<number>
  onDownloadReport: (id: number) => void
  id: number
  processId: number
}

export const ActionViewDownloadReportProcessCreated = (props: IActionViewDownloadReportProcessCreated) => {
  const {
    // listIdUsers,
    id,
    // onDownloadReport,
    processId,
  } = props
  const navigate = useNavigate()

  return (
    <Container style={{ marginLeft: '0', paddingLeft: '0%', width: '115%' }}>
      <RowContainer width="5%">
        <TextBoldViolet
          onClick={() =>
            navigate(platformAdminRoutes.DetailEvaluatedUser, { state: { userId: id, processId: processId } })
          }
          style={{ margin: '0px 10px' }}
        >
          Ver
        </TextBoldViolet>
      </RowContainer>
      {/* {!listIdUsers.size ? (
        <RowContainer width="95%">
          <DownloadIcon />
          <TextBoldBlue onClick={() => onDownloadReport(id)}>Descargar reportes</TextBoldBlue>
        </RowContainer>
      ) : (
        <RowContainer width="95%">
          <DownloadIcon style={{ color: '#BDBDBD' }} />
          <TextBoldBlue style={{ color: '#BDBDBD' }}>Descargar reportes</TextBoldBlue>
        </RowContainer>
      )} */}
    </Container>
  )
}

type Users = {
  userId: number
  processId: number
}

interface IReminderButtonProps {
  onSendReminder: (email: string) => void
  email: string
  testReminder: null | string
  reminderSent: any
  onWarning: () => void
  disabled: boolean
}

export const ReminderButton = ({ onSendReminder, email, testReminder, onWarning, disabled }: IReminderButtonProps) => {
  const [isReminderSent, setIsReminderSent] = useState(false)
  const [isDisabled, setIsDisabled] = useState(false)

  useEffect(() => {
    if (testReminder === null) {
      setIsReminderSent(false)
      setIsDisabled(false)
      return
    }

    const testReminderDate = new Date(testReminder)
    const currentDate = new Date()
    const timeDifference = currentDate.getTime() - testReminderDate.getTime()
    const isAtLeast24HoursAfter = timeDifference >= 24 * 60 * 60 * 1000
    setIsDisabled(!isAtLeast24HoursAfter)
    setIsReminderSent(isAtLeast24HoursAfter)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleSendReminderClick = () => {
    if (isReminderSent && isDisabled) {
      onWarning()
    } else {
      onSendReminder(email)
      setIsReminderSent(true)
      setIsDisabled(true)
    }
  }

  return (
    <TextBoldViolet onClick={handleSendReminderClick} style={{ margin: '0px 2px' }} isDisabled={isDisabled || disabled}>
      Enviar recordatorio
    </TextBoldViolet>
  )
}
interface IActionSendReminderAndReturnCreditToInventory {
  onSendReminder: (email: string) => void
  onReturnCreditToInventory: (props: Users) => void
  email: string
  userId: number
  processId: number
  testReminder: null | string
  usersWithReminderState: any
  disabled: boolean
}

export const ActionSendReminderAndReturnCreditToInventory = (props: IActionSendReminderAndReturnCreditToInventory) => {
  const {
    onReturnCreditToInventory,
    onSendReminder,
    email,
    userId,
    processId,
    testReminder,
    usersWithReminderState,
    disabled,
  } = props
  const dataUsers = usersWithReminderState?.usersWithReminderState
  const userWithReminder = dataUsers.find((user: { email: string }) => user.email === email)
  const { getWarning } = useNotification()

  const showWarning = () => {
    getWarning('Recordatorio enviado anteriormente')
  }

  return (
    <Container style={{ marginLeft: '0', paddingLeft: '0%', width: '110%' }}>
      <RowContainer width="40%">
        <ReminderButton
          onSendReminder={onSendReminder}
          email={email}
          testReminder={testReminder}
          reminderSent={userWithReminder?.isReminderSent}
          onWarning={showWarning}
          disabled={disabled}
        />
      </RowContainer>
      <RowContainer width="60%">
        <RollBackIcon />
        <TextBoldBlue isDisabled={disabled} onClick={() => onReturnCreditToInventory({ userId, processId })}>
          Devolver al inventario
        </TextBoldBlue>
      </RowContainer>
    </Container>
  )
}

export const ActionSendReminder = (props: IActionSendReminder) => {
  const { onSendReminder, email } = props

  return (
    <Container style={{ marginLeft: '0', paddingLeft: '0%', width: '110%' }}>
      <RowContainer>
        <Button onClick={() => onSendReminder(email)}>Enviar recordatorio</Button>
      </RowContainer>
    </Container>
  )
}

/**
 * @description Action add skills in create process
 */

export const ActionAddSkillCreateProcess = (props: IActionAddSkill) => {
  const { t } = useTranslation()
  const { handleAddSkill, onViewSkill, skillsSelected } = props
  const { getWarning } = useNotification()

  function isSkillSelected(idSkill: number) {
    if (!skillsSelected) return false
    const idSkills = skillsSelected.map((skill: any) => skill.id)
    return idSkills.includes(idSkill)
  }

  return (
    <Row width="105%">
      <RowContainer>
        <TextBoldViolet onClick={() => onViewSkill(props)}>Ver</TextBoldViolet>
      </RowContainer>
      <RowContainer>
        <Button
          disabled={isSkillSelected(props.id) || skillsSelected.length >= 12}
          onClick={() => !isSkillSelected(props.id) && handleAddSkill(props)}
          onDisabledClick={() => {
            if (skillsSelected.length >= 12) {
              getWarning(t('global.common.addSkillsErrorMoreThanMessage', { number: 12 }))
            }

            if (isSkillSelected(props.id) && skillsSelected.length < 12) {
              getWarning(t('global.common.addSkillsErrorMoreThanMessage'))
            }
          }}
        >
          {isSkillSelected(props.id) ? 'Agregada' : 'Agregar'}
        </Button>
      </RowContainer>
    </Row>
  )
}

/**
 * @description Action delete skill in create process
 */
export const ActionDeleteSkillCreateProcess = (props: IActionAddSkill) => {
  const { onDeleteSkill } = props
  return (
    <Row width="90%" marginLeft="10%">
      <RowContainer width="100%">
        <DeleteIcon />
        <DeleteText onClick={() => onDeleteSkill(props)}>Eliminar</DeleteText>
      </RowContainer>
    </Row>
  )
}

interface IActionEditAndDeleteSkillSelected {
  onDeleteSkill: (props: any) => void
  onEditSkill: (props: any) => void
}

/**
 * @description Action delete and edit betesa skill selected in create process
 */
export const ActionEditAndDeleteBetesaSkillSelected = (props: IActionEditAndDeleteSkillSelected) => {
  const { onDeleteSkill, onEditSkill } = props
  return (
    <Row width="60%" marginLeft="44%">
      <RowContainer>
        <EditIcon />
        <TextBoldBlue onClick={() => onEditSkill(props)}>Editar</TextBoldBlue>
      </RowContainer>
      <RowContainer>
        <div style={{ cursor: 'pointer' }} onClick={() => onDeleteSkill(props)}>
          <DeleteIcon />
        </div>
      </RowContainer>
    </Row>
  )
}

interface IActionRequestDeletePlatformAdmin {
  onRequestDeletePlatformAdmin: (userId: number) => void
  active: boolean
  userId: number
  request_status: 'pending' | 'approved' | 'rejected'
}

/**
 * @description Action to request to supend a platform admin
 */
export const ActionRequestSuspensionPlatformAdmin = (props: IActionRequestDeletePlatformAdmin) => {
  const { onRequestDeletePlatformAdmin, active, userId, request_status: requestStatus } = props

  return (
    <Row width="90%" marginLeft="10%">
      {active && (requestStatus === null || requestStatus === 'rejected') ? (
        <RowContainer width="100%">
          <NegativeWithRedCircle />
          <DeleteText onClick={() => onRequestDeletePlatformAdmin(userId)}>Solicitar eliminación</DeleteText>
        </RowContainer>
      ) : active && requestStatus === 'pending' ? (
        <RowContainer width="100%">
          <DisabledBoldText>Suspensión solicitada</DisabledBoldText>
        </RowContainer>
      ) : requestStatus === 'approved' ? (
        <RowContainer width="100%">
          <DisabledBoldText>Usuario suspendido</DisabledBoldText>
        </RowContainer>
      ) : (
        <RowContainer width="100%">
          <DisabledBoldText>Usuario sin activar</DisabledBoldText>
        </RowContainer>
      )}
    </Row>
  )
}

/**
 * @description Action to supend an user admin platform
 */

interface IActionSuspendUserAdminPlatform {
  onSuspendUserAdminPlatform: (props: any) => void
  onActivateUserAdminPlatform: (props: any) => void
  active: boolean
  user_id: number
  request_status: 'pending' | 'approved' | 'rejected'
}

export const ActionSuspendUserAdminPlatform = (props: IActionSuspendUserAdminPlatform) => {
  const { onSuspendUserAdminPlatform, onActivateUserAdminPlatform, request_status: RequestStatus } = props

  return (
    <Row width="90%" marginLeft="10%">
      {RequestStatus === 'pending' ? (
        <RowContainer width="100%">
          <NegativeWithRedCircle />
          <DeleteText onClick={() => onSuspendUserAdminPlatform(props)}>Suspender</DeleteText>
        </RowContainer>
      ) : (
        <RowContainer width="100%">
          <PlusWithCircle />
          <TextBoldBlue onClick={() => onActivateUserAdminPlatform(props)}>Reactivar usuario</TextBoldBlue>
        </RowContainer>
      )}
    </Row>
  )
}

interface InterpretationButtonProps {
  betesa_result_id: number
  company_name: string
  user_name: string
}

export const InterpretationButton: FC<InterpretationButtonProps> = ({ betesa_result_id }) => {
  return (
    <ContainerActions>
      <Link to={`/interpretation/detail?id=${betesa_result_id}`}>
        <TextInterpretation>Interpretar</TextInterpretation>
      </Link>
    </ContainerActions>
  )
}

export default InterpretationButton
