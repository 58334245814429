import loader from 'assets/gift/loader.gif'
import { Container, Image } from './styled'
import { P } from '../Text'
import { useTranslation } from 'react-i18next'

interface LoaderProps {
  text?: string
  style?: React.CSSProperties
}

const Loader = ({ text, style }: LoaderProps) => {
  const { t } = useTranslation()
  return (
    <Container style={style}>
      <Image src={loader} alt="loader bluesite" />
      <P color="#C6016B" margin="10px 0px">
        {text ? text : t('global.common.loadingText')}
      </P>
    </Container>
  )
}

export default Loader
