import { FC, useState, useEffect } from 'react'
import { useNavigate } from 'react-router'
import styled from 'styled-components'
//services
import changePassword from 'services/forgotPassword/changePassword'
import sendForgotEmailService from 'services/forgotPassword/sendEmail'

// Components
import ForgotForm from '../../components/Forms/Forgot'
import Logo from '../../components/UI/Logo'
import { P, H2 } from '../../components/UI/Text'
import Separator from 'views/components/UI/Separator/Index'
import ChangePasswordForm from 'views/components/Forms/changePassword'
import { useNotification } from 'lib/context/notification.context'
import { useTranslation } from 'react-i18next'

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 30px;
  @media (max-width: 1080px) {
    width: 120%;
    text-align: center;
  }
`

const WithOutParams = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`

const ForgotFormContainer: FC = () => {
  const { t } = useTranslation()
  const { getWarning, getSuccess, getError } = useNotification()
  const [params, setParams] = useState<any>({})
  const navigate = useNavigate()
  const paramValue = localStorage.getItem('i18nextLng')

  const [emailSended, setEmailSended] = useState<boolean>(false)
  const [changePasswordSuccess, setChangePasswordSuccess] = useState<boolean>(false)

  // effects
  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search)
    setParams(Object.fromEntries(searchParams))
  }, [])

  const handleSendEmailForgot = (values: any): void => {
    const email = values.email
    sendForgotEmailService(email, paramValue || 'es')
      .then(() => {
        setEmailSended(true)
        getSuccess(t('global.common.forgotPassword.notifications.sendSuccess'))
        navigate('/')
      })
      .catch((error) => {
        if (error.response.status === 400) {
          getError(t('global.notifications.emailNotExist'))
        }
        if (error.response.status === 420) {
          getWarning(t('global.notifications.emailNotMain'))
        }
        if (error.response.status === 421) {
          getError(t('global.notifications.userNotActive'))
        }
      })
  }

  const handleChangePassword = (pass: string): void => {
    const token = params.t

    changePassword({
      password: pass,
      token: token,
    })
      .then(() => {
        getSuccess(t('global.common.forgotPassword.notifications.success'))
        setChangePasswordSuccess(true)
        setTimeout(() => {
          navigate('/')
        }, 3000)
      })
      .catch(() => {
        getWarning(t('global.common.forgotPassword.notifications.error'))
      })
  }

  return (
    <Container>
      <Logo width="80%" />
      <Separator height="1rem" />
      {Object.keys(params).length < 1 ? (
        <WithOutParams>
          {!emailSended ? (
            <>
              <Separator height="2rem" />
              <H2 sx={{ width: 400, margin: '0px 0px 20px 0px', alignSelf: 'center' }}>
                {t('global.common.forgotPassword.title')}
              </H2>
              <P sx={{ width: '90%', textAlign: 'center', alignSelf: 'center' }}>
                {t('global.common.forgotPassword.description')}
              </P>
              <Separator height="2rem" />
              <ForgotForm onSubmit={(e) => handleSendEmailForgot(e)} />
            </>
          ) : (
            <>
              <Separator height="2rem" />
              <H2 sx={{ width: 400, margin: '0px 0px 20px 0px', alignSelf: 'center' }}>
                {t('global.common.forgotPassword.emailSended')}
              </H2>
              <P sx={{ width: '90%', textAlign: 'center', alignSelf: 'center' }}>
                {t('global.common.forgotPassword.emailSendedDescription')}
              </P>
            </>
          )}
        </WithOutParams>
      ) : (
        <>
          {!changePasswordSuccess ? (
            <ChangePasswordForm onSubmit={(e) => handleChangePassword(e.password)} />
          ) : (
            <>
              <Separator height="2rem" />
              <H2 sx={{ width: 400, margin: '0px 0px 20px 0px', alignSelf: 'center' }}>
                {t('global.common.forgotPassword.changedPassword')}
              </H2>
              <P sx={{ width: '90%', textAlign: 'center', alignSelf: 'center' }}>
                {t('global.common.forgotPassword.changedPasswordDescription')}
              </P>
            </>
          )}
        </>
      )}
    </Container>
  )
}

export default ForgotFormContainer
