import {Container, SubContainer,  ItemContainer, ItemTagContainer, ItemValueContainer, TextPriority, CircleContainer} from './styled';
import { QuadrantData  } from 'views/containers/BrainQuadrant/constants';
import { parseQuadrant, orderQuadrants } from './main';
import { useTranslation } from 'react-i18next';

interface ItemProps {
    quadrant: 'FI' | 'FD' | 'BI' | 'BD';
    minRange: number;
    maxRange: number;
    priority: number;
    isReportParam?: boolean
}

const Item = ({quadrant, minRange, maxRange, priority, isReportParam= false}: ItemProps) => {
    const { t } = useTranslation();
    return (
        <ItemContainer quadrant={quadrant} isReport={isReportParam}>
           {
                (quadrant === 'FI' || quadrant === 'BI') ? (
                    <ItemTagContainer isReportParam={isReportParam}>
                        <CircleContainer isReportParam={isReportParam}>{t(`global.common.betesa.quadrantsAbbr.${quadrant}`)}</CircleContainer>
                    </ItemTagContainer>
                ) :
                <div></div>
           }
           
            <ItemValueContainer isReportParam={isReportParam}>
                <TextPriority isReportParam={isReportParam}> {t('betesaReport.adjustmentLevelPage.priority')} {priority} </TextPriority>
                <span>{t('betesaReport.adjustmentLevelPage.range')} {minRange} - {maxRange}</span> 
            </ItemValueContainer>
            {
                (quadrant === 'FD' || quadrant === 'BD') ? (
                    <ItemTagContainer isReportParam={isReportParam}>
                        <CircleContainer  isReportParam={isReportParam}>{t(`global.common.betesa.quadrantsAbbr.${quadrant}`)}</CircleContainer>
                    </ItemTagContainer>
                ) :
                <div></div>
            }
        </ItemContainer>
    );
}

interface Props {
    data : QuadrantData[]
    isReport?: boolean;
    style?: React.CSSProperties;
}


//Main component 
function CartesianAxisBrainQuadrant({data, isReport, style}: Props) {

    const quadrantsData = orderQuadrants(data)

    return (
        <Container isReportParam style={style}>
           <SubContainer>
                {quadrantsData.map((item: QuadrantData, index) => (
                    <Item
                        key={index}
                        quadrant={item.quadrant.length > 2 ? parseQuadrant(item.quadrant) as any : item.quadrant as any}
                        minRange={item.min}
                        maxRange={item.max}
                        priority={item.priority}
                        isReportParam={isReport}
                    />
                ))}
            </SubContainer>
        </Container>
    );
}

export default CartesianAxisBrainQuadrant;