import { useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { sendTestsToUsers as sendTestToUserService } from 'views/screens/Platform-administrator/services'
import { useNotification } from 'lib/context/notification.context'
import { cleanProcess } from 'ducks/createProcess'
import { useTranslation } from 'react-i18next'
/* eslint-disable */

interface DataToSend {
  process_id: number
  users_data: { user_id: number; language?: string }[]
  company_id: number
  type: string
  count?: number
  email_template: {
    textOne?: string
    textTwo?: string
    textThree?: string
    textFour?: string
    textThreeOptions?: string[]
    date?: string
    logo?: string
  }
}

interface WaitingResult {
  status: 'waiting' | 'success' | 'error'
  message?: string
}

interface SuccessResult {
  status: 'OK'
  response: any
}

interface ErrorResult {
  status: 'error'
  message: string
}

export type Result = WaitingResult | SuccessResult | ErrorResult

interface SendToUsersResult {
  handleSendExamToUsers: (data: DataToSend, totalCalls?: number, completedCalls?: number) => void
  loadingSendExamToUser: boolean
  resultSendExamToUser: Result
  setLoading: (loading: boolean) => void
}

export const useSendExamToUsers = (): SendToUsersResult => {
  const { t } = useTranslation()
  const { getSuccess, getError, getWarning } = useNotification()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [loadingSendExamToUser, setLoading] = useState(false)
  const [resultSendExamToUser, setResult] = useState<Result | any>({ status: 'waiting', message: '' })

  const handleSendExamToUsers = useCallback((data: DataToSend, totalCalls?: number, completedCalls?: number) => {
    setLoading(true)

    sendTestToUserService(data)
      .then((response) => {
        if (
          typeof response.users === 'string' &&
          response.users === 'The exam has already been sent to all selected users'
        ) {
          setResult({ status: 'error', message: response.users })
          setLoading(false)
          if (completedCalls === totalCalls || (!totalCalls && !completedCalls)) {
            getWarning(t('global.notifications.testAlreadySent'))
            navigate('/')
          }
          return
        }
        if (typeof response.users !== 'string' && response.status !== 'error') {
          setResult({ status: 'success', response })
          dispatch(cleanProcess())
          setLoading(false)
          if (completedCalls === totalCalls || (!totalCalls && !completedCalls)) {
            getSuccess('Se enviaron las pruebas a los usuarios')
          }
          navigate('/')
        } else {
          setLoading(false)
          setResult({ status: 'error', message: response.users })
        }
      })
      .catch((error) => {
        console.error('error al enviar las pruebas', error)
        setResult({ status: 'error', message: 'Error al enviar las pruebas' })
        if (completedCalls === totalCalls || (!totalCalls && !completedCalls)) {
          getError('Error al enviar las pruebas')
        }
        setLoading(false)
      })
  }, [])

  return { handleSendExamToUsers, loadingSendExamToUser, resultSendExamToUser, setLoading }
}
