import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { fetchGetBetesaQuestions } from '../../services/fetch-questions-betesa'
import { data } from './constants'
import {
  selectCurrentQuestion,
  selectDataQuestion,
  selectTestStarted,
  selectShowChapterInfo,
  setDataQuestion,
  setTestStarted,
  setShowChapterInfo,
  selectCurrentChapter,
  setBetesaResultId,
  setCurrentQuestion,
  setAnsweredQuestions,
} from '../../store/slice'
import { TestLayout } from 'views/layouts'
import QuestionComponent from './Questions/questionRender'

import {
  Title,
  BtnNext,
  CurrentChapterImg,
  ParagraphChapter,
  SubTitle,
  ImgGrid,
  Container,
  MobileParagraphChapter,
  ParagraphInstructionsQuestionChapter,
} from './styled'
import { LinearProgress, linearProgressClasses, styled } from '@mui/material'
import { userTestIdSelector } from 'ducks/evaluatedUser'
import { fetchBetesaChapter } from '../../services/fetch-betesa-chapter'
import CobrandingLogo from 'views/components/UI/Logo/logo-cobranding'
import { cobrandingLogoSelector } from 'ducks/ui'
import { useNotification } from 'lib/context/notification.context'
import { useTranslation } from 'react-i18next'

const QuestionsTalents = () => {
  const { t } = useTranslation()
  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 400 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
    },
  }))

  const currentQuestion = useSelector(selectCurrentQuestion)
  const dataQuestion = useSelector(selectDataQuestion)
  const testStarted = useSelector(selectTestStarted)
  const showChapterInfo = useSelector(selectShowChapterInfo)
  const currentChapter = useSelector(selectCurrentChapter)
  const userTestId = useSelector(userTestIdSelector)
  const dispatch = useDispatch()
  const companyLogo = useSelector(cobrandingLogoSelector)
  const [previousUserTestId, setPreviousUserTestId] = useState(null)
  const { getError } = useNotification()
  const { previousAnswers } = useSelector((state: any) => state.questions)

  const useTotalQuestions = (currentChapterNumber: number | null, currentChapterQuestions: any[] | null) => {
    const [totalQuestions, setTotalQuestions] = useState(0)
    useEffect(() => {
      if (!currentChapterNumber || !currentChapterQuestions) return
      setTotalQuestions(currentChapterQuestions.length)
    }, [currentChapterNumber, currentChapterQuestions])

    return totalQuestions
  }

  const questionsPerChapter =
    dataQuestion && currentChapter ? dataQuestion[`chapter_${currentChapter + 1}`] || [] : null
  const totalQuestions = useTotalQuestions(currentChapter, questionsPerChapter)

  const progress = currentQuestion === totalQuestions ? 100 : ((currentQuestion + 1) / totalQuestions) * 100

  const handleStartTest = () => {
    dispatch(setTestStarted(true))
    dispatch(setShowChapterInfo(false))
  }

  useEffect(() => {
    const getBetesaAllQuestions = async () => {
      try {
        const response = await fetchGetBetesaQuestions(userTestId)
        dispatch(setDataQuestion(response))
      } catch (error) {
        console.error(`Hubo un error obteniendo las preguntas: ${error}`)
      }
    }
    getBetesaAllQuestions()
  }, [dispatch, userTestId])

  useEffect(() => {
    const fetchChapterInfo = async () => {
      try {
        const responseChapter = await fetchBetesaChapter(userTestId)
        if (
          responseChapter.data?.betesa_test_id &&
          currentQuestion === 0 &&
          Object.keys(previousAnswers).length === 0
        ) {
          dispatch(setAnsweredQuestions(responseChapter.data.previousCurrentChapterAnswers))
          dispatch(setCurrentQuestion(responseChapter.data.currentChapterCompletedCount))
        }
        if (!responseChapter.data?.betesa_test_id) {
          dispatch(setBetesaResultId(0))
        }
      } catch (error) {
        getError('Ha ocurrido un error al obtener las respuestas previas.')
      }
    }

    if (userTestId !== previousUserTestId) {
      fetchChapterInfo()
      setPreviousUserTestId(userTestId)
    }
  }, [dispatch, userTestId, previousUserTestId, currentQuestion, previousAnswers, getError])

  if (previousUserTestId !== userTestId) {
    dispatch(setTestStarted(false))
    dispatch(setShowChapterInfo(true))
  }

  return (
    <TestLayout cobrandingLogoUrl={companyLogo}>
      <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
        {companyLogo && <CobrandingLogo logoUrl={companyLogo} hideMobile />}
      </div>
      {!testStarted && showChapterInfo && currentChapter && (
        <Container>
          <Title>{t('evaluatedUser.questionnaire.title')}</Title>
          <SubTitle style={{ marginTop: 5 }}>
            {t('evaluatedUser.betesaQuestionnaire.chapterText')}
            {currentChapter}: {t(data[currentChapter - 1].chapterName)}
          </SubTitle>
          <ParagraphChapter>{t(data[currentChapter - 1].chapterDescription)}</ParagraphChapter>
          <ParagraphChapter>{t('evaluatedUser.betesaQuestionnaire.instructionsLegendText')}</ParagraphChapter>
          <ImgGrid>
            <CurrentChapterImg src={data[currentChapter - 1].chapterImg} />
          </ImgGrid>
          <BtnNext style={{ alignSelf: 'flex-end' }} onClick={handleStartTest}>
            {t('global.common.startTestButtonText')}
          </BtnNext>
        </Container>
      )}
      {testStarted && !showChapterInfo && currentChapter ? (
        <Container>
          <SubTitle style={{}}>
            {t('evaluatedUser.betesaQuestionnaire.chapterTitle')}
            {t(data[currentChapter - 1].chapterName)}
          </SubTitle>
          <p style={{ marginTop: 0 }}>
            {currentQuestion < totalQuestions ? currentQuestion + 1 : currentQuestion} {t('evaluatedUser.betesaQuestionnaire.of')} <span>{totalQuestions}</span>
          </p>
          <BorderLinearProgress variant="determinate" value={progress} key={`${currentChapter}-${currentQuestion}`} />
          {currentChapter <= 4 ? (
            <>
              <ParagraphInstructionsQuestionChapter>
                {t(data[currentChapter - 1].instructions)}
              </ParagraphInstructionsQuestionChapter>
              <MobileParagraphChapter>
                {t('evaluatedUser.betesaQuestionnaire.mobileInstructions')}
              </MobileParagraphChapter>
            </>
          ) : (
            <ParagraphChapter>{t(data[currentChapter - 1].instructions)}</ParagraphChapter>
          )}
          <QuestionComponent />
        </Container>
      ) : null}
    </TestLayout>
  )
}

export default QuestionsTalents
