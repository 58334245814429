/* eslint-disable @typescript-eslint/no-unused-vars */
import { useTranslation } from 'react-i18next'
import skillDimensions from '../../assets/dimensions.png'
import {
  ContainerTable,
  Subtitle,
  HeadingTable,
  TableCell,
  PreviewTable,
  ContainerT,
  TableFooter,
  Description,
  FirstPageImage,
  FooterText,
} from '../styled'

const TableResult = ({ data }: any) => {
  const { t } = useTranslation()
  // const margin = data?.data?.length < 8 ? '48px' : '0'

  const parseLevel = (level: number) => {
    switch (level) {
      case 2:
        return 'Estratégico'
      case 3:
        return 'Táctico'
      case 4:
        return 'Operativo'
      case 5:
        return 'Estratégico-Táctico'
      default:
        return 'No definido'
    }
  }

  return (
    <ContainerT>
      <ContainerTable>
        <Description>
          Considerar las puntuaciones de las tres dimensiones en conjunto con la habilidad blanda evaluada es
          fundamental para obtener una interpretación precisa de la prueba. Este análisis combinado brinda una visión
          completa de la capacidad de mantener sostenidamente los comportamientos asociados con la competencia.
        </Description>
        <FirstPageImage src={skillDimensions} alt="Imagen de las dimensiones de las habilidades blandas" />
        {/*  <Subtitle marginBottom={margin}>Resumen de Resultados</Subtitle>

        <PreviewTable cellPadding="0" cellSpacing="0">
          <tbody>
            <tr>
              <HeadingTable style={{ width: '50%' }}>Grupo de {t('global.common.skillText')}</HeadingTable>
              <HeadingTable>Mediana</HeadingTable>
            </tr>
            {data?.data &&
              data?.data.map(({ groupName, average }: any) => (
                <tr key={`${groupName}-${average}`}>
                  <HeadingTable>{groupName}</HeadingTable>
                  <TableCell>{Math.ceil(average)}</TableCell>
                </tr>
              ))}
          </tbody>
        </PreviewTable> */}
        <TableFooter marginTop="22px">
          <FooterText style={{ textAlign: 'center', display: 'inline', fontSize: 16 }}>
            Nivel: {parseLevel(data.level_id)}
          </FooterText>
        </TableFooter>
      </ContainerTable>
    </ContainerT>
  )
}
export default TableResult
