import React, { FC } from 'react'
import EsquemaBetesa from 'assets/images/EsquemaBetesa.png'
import EsquemaBetesaEn from 'assets/images/EsquemaBetesaEn.png'
import { Page } from 'views/components/report/styled'
import Headpage from '../../header'
import { Title, WrapperContent, ContainerScheme, SchemaBetesaImg, Text } from './styled'
import FooterPage from '../../footer-page'
import parse from 'html-react-parser'
import { useTranslation } from 'react-i18next'

interface DescriptionPageProps {
  introduction?: any
  User?: any
  currentPage: number
  totalPages: number
  answerDate: string
  lang?: string
}

const DescriptionPage: FC<DescriptionPageProps> = ({ introduction, User, currentPage, totalPages, answerDate, lang }) => {
  const { t } = useTranslation()
  const language = localStorage.getItem('i18nextLng')
  return (
    <Page>
      <Headpage isBetesa={true} />
      <WrapperContent>
        <Title>{t('betesaReport.betesaSchemeTitle')}</Title>
        <ContainerScheme>
          <SchemaBetesaImg src={language && language === 'en' ? EsquemaBetesaEn : EsquemaBetesa} alt="Esquema betesa" />
        </ContainerScheme>

        <Text>{parse(introduction?.introduction_scheme)}</Text>
      </WrapperContent>
      <FooterPage
        page={currentPage + 1}
        totalPages={totalPages}
        name={User?.first_name}
        lastName={User?.last_name}
        answerDate={answerDate}
        language={lang}
      />
    </Page>
  )
}

export default DescriptionPage
