import Niñez from 'views/components/assets/child.png'
import TiempoLibre from 'views/components/assets/chapter-2.png'
import VidaLaboral from 'views/components/assets/vidaLaboral.png'
import Autopercepcion from 'views/components/assets/Autopercepcion.png'
import EntornosDeProductividad from 'views/components/assets/EntornosDeProductividad.png'
import EstadoEmocional from 'views/components/assets/EstadoEmocional.png'
import Estresores from 'views/components/assets/Estresores.png'

export const data = [
  {
    chapterImg: Niñez,
    chapterName: 'evaluatedUser.betesaQuestionnaire.cap1.chapterName',
    chapterDescription: 'evaluatedUser.betesaQuestionnaire.cap1.chapterDescription',
    instructions: 'evaluatedUser.betesaQuestionnaire.cap1.instructions',
  },
  {
    chapterImg: TiempoLibre,
    chapterName: 'evaluatedUser.betesaQuestionnaire.cap2.chapterName',
    chapterDescription: 'evaluatedUser.betesaQuestionnaire.cap2.chapterDescription',
    instructions: 'evaluatedUser.betesaQuestionnaire.cap2.instructions',
  },
  {
    chapterImg: VidaLaboral,
    chapterName: 'evaluatedUser.betesaQuestionnaire.cap3.chapterName',
    chapterDescription: 'evaluatedUser.betesaQuestionnaire.cap3.chapterDescription',
    instructions: 'evaluatedUser.betesaQuestionnaire.cap3.instructions',
  },
  {
    chapterImg: Autopercepcion,
    chapterName: 'evaluatedUser.betesaQuestionnaire.cap4.chapterName',
    chapterDescription: 'evaluatedUser.betesaQuestionnaire.cap4.chapterDescription',
    instructions: 'evaluatedUser.betesaQuestionnaire.cap4.instructions',
  },
  {
    chapterImg: EntornosDeProductividad,
    chapterName: 'evaluatedUser.betesaQuestionnaire.cap5.chapterName',
    chapterDescription: 'evaluatedUser.betesaQuestionnaire.cap5.chapterDescription',
    instructions: 'evaluatedUser.betesaQuestionnaire.cap5.instructions',
  },
  {
    chapterImg: EstadoEmocional,
    chapterName: 'evaluatedUser.betesaQuestionnaire.cap6.chapterName',
    chapterDescription: 'evaluatedUser.betesaQuestionnaire.cap6.chapterDescription',
    instructions: 'evaluatedUser.betesaQuestionnaire.cap6.instructions',
  },
  {
    chapterImg: Estresores,
    chapterName: 'evaluatedUser.betesaQuestionnaire.cap7.chapterName',
    chapterDescription: 'evaluatedUser.betesaQuestionnaire.cap7.chapterDescription',
    instructions: 'evaluatedUser.betesaQuestionnaire.cap7.instructions',
  },
]

export const questionsOptions = [
  {
    id: 1,
    name: 'evaluatedUser.betesaQuestionnaire.betesaDragAndDropOptions.opt1',
    weight: 4,
    active: false,
  },
  {
    id: 2,
    name: 'evaluatedUser.betesaQuestionnaire.betesaDragAndDropOptions.opt2',
    weight: 3,
    active: false,
  },
  {
    id: 3,
    name: 'evaluatedUser.betesaQuestionnaire.betesaDragAndDropOptions.opt3',
    weight: 2,
    active: false,
  },
  {
    id: 4,
    name: 'evaluatedUser.betesaQuestionnaire.betesaDragAndDropOptions.opt4',
    weight: 0,
    active: false,
  },
]

export const quadrants = {
  FI: 0,
  FD: 0,
  BI: 0,
  BD: 0,
}
export const colorMap = {
  cardOne: '#DBF0CE',
  cardTwo: '#D1E6F1',
  cardThree: '#FFEEDA',
  cardFour: '#EEDBFE',
}

export function getColor(index: number) {
  switch (index) {
    case 1:
      return '#DBF0CE'
    case 2:
      return '#D1E6F1'
    case 3:
      return '#FFEEDA'
    case 4:
      return '#EEDBFE'
    default:
      return ''
  }
}
