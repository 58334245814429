import axios from 'axios'

/**
 * @description service to restore password
 * @param email
 */
const forgot = async (email: string, language: string) => {
  const apiUrl = `${process.env.REACT_APP_MAIN_API}/auth/password-recovery`

  // config post request
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Accept: '*/*',
    },
  }

  // body post request
  const body = {
    email: email,
    language,
  }

  try {
    const response = await axios.post(apiUrl, body, config)

    return response
  } catch (error) {
    throw error
  }
}

export default forgot
