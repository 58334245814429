import DownloadIcon from 'assets/icons/DownloadIcon'
import { useFinishProcess } from 'hooks/process/useFinishProcess/useFinishProcess'
import { useFetchAllProcesses } from 'hooks/process/useGetAllProcessCurrentCompany/useGetAllProcessCurrentCompany'
import useReportDownloaderByExcel from 'hooks/process/useGetExcelReportByProcess/use-get-excel-report-by-process'
import { useFetchProcesses } from 'hooks/process/useGetProcess/useGetProcess'
import { useNotification } from 'lib/context/notification.context'
import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { platformAdminRoutes } from 'router/routes'
import { getProcessDetail } from 'services/common/process/createProcess/create-process-service'
import BluesiteTable from 'views/components/UI/BluesiteTable'
import { ActionViewDownloadAddUsers } from 'views/components/UI/BluesiteTable/Actions/GridActions'
import { TextBoldBlue } from 'views/components/UI/BluesiteTable/Actions/styled'
import Loader from 'views/components/UI/Loader'
import { SubtitleBold } from 'views/components/UI/Text'
import { MainLayout } from 'views/layouts'

import HighlightOffIcon from '@mui/icons-material/HighlightOff'

import SelectSearch from '../../Analytics/selectSearch'
import { HEADERS_SENT_PROCESSES } from '../constants'
import {
  Container,
  DownloadWrapper,
  EmptyStateText,
  HeaderWrapper,
  LoaderWrapper,
  TableContainer,
  ViewWrapper,
  Wrapper,
  WrapperEmptyState,
} from './styled'
import { DownloadProcess, IDownloadProcess } from '../store'
import { StrokedButton } from 'views/components/UI/Buttons'
import ModalDateFilter from './components/ModalDateFilter'

const DeliveredProcesses = () => {
  const navigate = useNavigate()
  const { handleFinishProcess } = useFinishProcess()
  const { getError } = useNotification()

  const { listProcess, fetchProcesses, loadingListProcesses, paginationListProcess } = useFetchProcesses()
  const { fetchProcesses: fetchAllProcess } = useFetchProcesses()
  const { allProcess, fetchAllProcesses } = useFetchAllProcesses()

  const { fetchGetReportsByExcel } = useReportDownloaderByExcel()
  const [loading, setLoading] = useState(false)
  const [processSelected, setProcessSelected] = useState<number | ''>('')
  const [currentPage, setCurrentPage] = useState(1)
  const [processIds, setProcessIds] = useState<Set<number>>(new Set())
  const [isDisabled, setIsDisabled] = useState(false)
  const [showModalDateFilter, setShowModalDateFilter] = useState(false)

  useEffect(() => {
    if (processSelected === '') {
      fetchAllProcesses()
    }
  }, [processSelected, fetchAllProcesses])

  useEffect(() => {
    if (processSelected) {
      fetchProcesses({ sent: true, processId: processSelected })
    } else {
      fetchProcesses({ sent: true })
    }
  }, [allProcess, processSelected, fetchProcesses, fetchAllProcess])

  useEffect(() => {
    setIsDisabled(processIds.size > 0)
  }, [processIds])

  function handleViewProcess(id: number) {
    navigate(platformAdminRoutes.DetailProcessDelivered, { state: { id } })
  }

  const handleSelectProcess = (processId: number) => {
    setProcessSelected(processId)
    setCurrentPage(1)
  }

  const handleDateFilterButtonClick = () => {
    setShowModalDateFilter(true)
  }

  const handleDownloadReports = ({
    processId,
    processName,
    useBetesa,
    useCompetence,
    isForAllProcess,
    initDate,
    endDate,
  }: DownloadProcess) => {
    setLoading(true)

    fetchGetReportsByExcel({
      processId: processId ?? 0,
      useBetesa,
      useCompetence,
      processName: processName ?? '',
      isForAllProcess,
      initDate: initDate ?? '',
      endDate: endDate ?? '',
      processIds: Array.from(processIds) ?? [],
    }).then(() => setLoading(false))
  }

  const handleDownloadReportsAllProcesses = () => {
    handleDownloadReports({
      useBetesa: false,
      useCompetence: false,
      isForAllProcess: true,
    })
  }

  const handleAddUsers = useCallback(
    (processId: number) => {
      getProcessDetail(processId)
        .then((response: any) => {
          setLoading(false)
          handleFinishProcess(response.process)
          navigate(platformAdminRoutes.CreateUsers, { state: { id: processId, isSendedProcess: true } })
        })
        .catch(() => {
          getError('Ha ocurrido un error al cargar este proceso')
          setLoading(false)
        })
    },
    [navigate, getError, handleFinishProcess]
  )

  const handlePagination = useCallback(
    (page: number) => {
      if (processSelected) return
      fetchProcesses({ sent: true, page })
      setCurrentPage(page)
    },
    [fetchProcesses, setCurrentPage, processSelected]
  )

  return (
    <MainLayout>
      <Container>
        <HeaderWrapper>
          <h2>Procesos creados</h2>
          <StrokedButton onClick={handleDateFilterButtonClick} type="button">
            Descargar resumen por rango de fechas
          </StrokedButton>
        </HeaderWrapper>
        {!loadingListProcesses && !loading ? (
          <>
            {listProcess?.length > 0 ? (
              <>
                <Wrapper>
                  <ViewWrapper>
                    <SubtitleBold>Visualizar:</SubtitleBold>
                    <SelectSearch
                      showCheckboxes={false}
                      noChip={true}
                      label="Todos los procesos"
                      options={allProcess || []}
                      value={processSelected}
                      onChange={(e: any) => {
                        handleSelectProcess(e as number)
                      }}
                      limitTags={1}
                    />
                    <HighlightOffIcon
                      style={{ cursor: 'pointer', color: 'gray' }}
                      onClick={() => {
                        setProcessSelected('')
                      }}
                    />
                  </ViewWrapper>
                  <DownloadWrapper onClick={handleDownloadReportsAllProcesses}>
                    <DownloadIcon />
                    <TextBoldBlue style={{ fontSize: '1rem' }}>
                      {isDisabled
                        ? 'Descargar resumen de los procesos seleccionados'
                        : 'Descargar resumen de todos los procesos'}
                    </TextBoldBlue>
                  </DownloadWrapper>
                </Wrapper>
                <TableContainer>
                  <BluesiteTable
                    data={listProcess}
                    headers={HEADERS_SENT_PROCESSES}
                    searchBar={false}
                    searchLabel=""
                    ActionComponent={ActionViewDownloadAddUsers}
                    totalPages={paginationListProcess.totalPages}
                    currentPage={currentPage}
                    handlePagination={handlePagination}
                    checks={true}
                    showNumbers={true}
                    totalItems={paginationListProcess.totalResults}
                    selectedItems={processIds}
                    setSelectedItems={(e) => setProcessIds(e)}
                    extraDataAction={{
                      onAddUsers: (e: number) => handleAddUsers(e),
                      onViewProcess: (e: number) => handleViewProcess(e),
                      onDownloadProcess: (e: IDownloadProcess) =>
                        handleDownloadReports({
                          processId: e.id,
                          processName: e.name,
                          useBetesa: e.betesaTest,
                          useCompetence: e.competencesTest,
                        }),
                      isDisabled,
                    }}
                  />
                </TableContainer>
              </>
            ) : (
              <WrapperEmptyState>
                <EmptyStateText>Esta compañía no tiene procesos creados</EmptyStateText>
              </WrapperEmptyState>
            )}
          </>
        ) : (
          <LoaderWrapper>
            <Loader />
          </LoaderWrapper>
        )}
        <ModalDateFilter
          showConfirmModal={showModalDateFilter}
          setShowModal={setShowModalDateFilter}
          downloadFunction={(e: any) => {
            handleDownloadReports({
              ...e,
              useBetesa: false,
              useCompetence: false,
              isForAllProcess: true,
            })
          }}
        />
      </Container>
    </MainLayout>
  )
}

export default DeliveredProcesses
