import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  padding: 40px 0px 20px 0px;
`

export const TableContainer = styled.div`
  margin-right: 30px;
`

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px
  align-items: center;
  margin-top: 30px;
  margin-bottom: 30;
`

export const ViewWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
`

export const DownloadWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
  cursor: pointer;
`

export const WrapperEmptyState = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
`

export const EmptyStateText = styled.h3`
  color: rgb(198, 1, 107);
`

export const LoaderWrapper = styled.div`
  margin: 80px 0px;
`

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`
