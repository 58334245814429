import axios from 'lib/axios'

interface BodyPermodaIntegration {
  docto_ident: string
  nombre: string
  apellido: string
  file: string
  filetype: string
  nameFile: string
}

export const fetchSendBase64 = async (body: BodyPermodaIntegration) => {
  const apiTest = `/send/base64`

  const response: Response = await axios
    .post(apiTest, body)
    .then((res) => {
      return res.data
    })
    .catch((err) => {
      return err
    })

  return response
}
