import { useTranslation } from 'react-i18next'
import { Circle } from '../styled'

const AlertCircle = ({ userAlertLevelName }: any) => {
  const { t } = useTranslation()
  const namesMap = {
    E: 'betesaReport.alertLevels.E',
    EI: 'betesaReport.alertLevels.EI',
    'EE-II': 'betesaReport.alertLevels.EEII',
    IE: 'betesaReport.alertLevels.IE',
    I: 'betesaReport.alertLevels.I',
  }

  return (
    <>
      {Object.entries(namesMap).map(([shortName, displayName]) => (
        <Circle active={shortName === userAlertLevelName ? true : false} key={Math.random().toString(36).substring(7)}>
          <h4 style={{ fontSize: 8 }}>{t(displayName)}</h4>
        </Circle>
      ))}
    </>
  )
}
export default AlertCircle
