import { colorGraphTextEN, colorGraphTextES, SKILLS_NAME } from 'constants/global.constants'

export const globalTranslations = {
  es: {
    common: {
      welcome: 'Te damos la bienvenida',
      credentialsText: 'Ingresa tus credenciales para iniciar sesión',
      skillText: SKILLS_NAME,
      loginPasswordText: 'Ingresa tu contraseña',
      loginEmailText: 'Ingresa tu correo',
      loginButton: 'Iniciar sesión',
      forgotPasswordButton: 'Olvide mi contraseña',
      betesaTalentsText: 'Talentos Naturales',
      pendingTestsText: 'Pruebas pendientes por realizar.',
      startTestButtonText: 'Iniciar',
      resumeTestButtonText: 'Retomar',
      processText: 'Proceso',
      selectQuadrantText: 'Selecciona uno',
      selectQuadrant: 'Selecciona un cuadrante.',
      selectQuadrantTitle: 'Elige el cuadrante que deseas desarrollar',
      noCompetenceQuadrant: 'No hay competencias a desarrollar en este cuadrante.',
      viewButtonText: 'Ver',
      addButtonText: 'Agregar',
      saveButtonText: 'Guardar',
      deleteButtonText: 'Eliminar',
      userResultsLegendGraph: 'Resultado del usuario',
      idealProfileText: 'Perfil ideal',
      quadrantText: 'Cuadrante',
      pluralQuadrantText: 'Cuadrantes',
      colorGraphText: colorGraphTextES,
      downloadReportText: 'Descargar reporte',
      nextButtonText: 'Siguiente',
      previousButtonText: 'Anterior',
      loadingText: 'Cargando...',
      endButtonText: 'Finalizar',
      nextChapterText: 'Siguiente capítulo',
      sendButtonText: 'Enviar',
      cancelButtonText: 'Cancelar',
      registerDateLocale: 'es',
      betesa: {
        quadrants: {
          FI: 'Frontal Izquierdo',
          BI: 'Basal Izquierdo',
          BD: 'Basal Derecho',
          FD: 'Frontal Derecho',
        },
        quadrantsAbbr: {
          FI: 'FI',
          BI: 'BI',
          BD: 'BD',
          FD: 'FD',
        },
      },
      alertLevels: {
        E: 'Extrovertido',
        EI: 'Extravertido Introvertido',
        EEII: 'EE:II',
        IE: 'Introvertido Extravertido',
        I: 'Introvertido',
      },
      forgotPassword: {
        title: 'Recuperar contraseña',
        description: 'Ingresa tu correo para enviarte la información para recuperar tu contraseña',
        emailSended: 'Correo enviado',
        emailSendedDescription:
          'Te hemos enviado un correo electrónico con las instrucciones para recuperar tu contraseña.',
        changedPassword: 'Contraseña cambiada',
        changedPasswordDescription: 'Tu contraseña ha sido cambiada correctamente',
        inputs: {
          email: 'Correo electrónico',
        },
        buttons: {
          send: 'Recuperar contraseña',
        },
        notifications: {
          sendSuccess: 'Datos enviados correctamente. Por favor, revisa tu correo electrónico registrado',
          success: 'Contraseña cambiada correctamente',
          error: 'Error al cambiar la contraseña',
        },
      },
      recoveryPassword: {
        title: 'Ingrese su nueva contraseña',
        requirements: {
          one: 'Una mayúscula',
          two: 'Una minuscula',
          three: 'Un caracter especial (!#$%&*-/?_~)',
          four: 'Al menos 8 caracteres',
        },
        inputs: {
          password: 'Contraseña',
          confirmPassword: 'Confirmar contraseña',
        },
        buttons: {
          save: 'Cambiar contraseña',
        },
      },
      documentTypes: {
        dni: 'Documento de identidad',
        immigrationDocument: 'Documento de extranjería',
        passport: 'Pasaporte',
        identityCard: 'Tarjeta de identidad',
      },
    },
    notifications: {
      addSkillAlmostMessage: `Debes agregar al menos {{ number }} competencias`,
      addSkillsErrorMessage: `No puedes tener menos de {{ number }} competencias`,
      addSkillsErrorMoreThanMessage: `No puedes tener más de {{ number }} competencias`,
      notAddSameSkillErrorMessage: `No puedes agregar la misma competencia dos veces`,
      getSkillsErrorMessage: `No se pudieron obtener las competencias`,
      loginEmailErrorMessage: `El email que has ingresado no coincide. Revisa que sea correcto`,
      loginInactiveUserErrorMessage: `Este usuario no está activo, por favor revisa tu correo y activa tu cuenta`,
      loginEmailNotMainErrorMessage: `El email que has ingresado no es el principal de tu cuenta, por favor intenta con otro`,
      loginEmailOrPasswordErrorMessage: `El email o la contraseña que has ingresado no coinciden, vuelve a intentar`,
      documentAlreadyRegistered: `Este documento no coincide con tu usuario.`,
      userAlreadyActive: `El usuario con este número de documento ya se encuentra activo`,
      emailNotExist: `No existe una cuenta con ese correo electrónico`,
      emailNotMain: `El correo electrónico ingresado no es el principal de la cuenta`,
      userNotActive: `Esta cuenta no está activa. Asegúrate de activarla`,
      createAccountError: 'Ocurrió un error inesperado, por favor intenta más tarde.',
      createAccountSuccess: 'Cuenta creada con éxito',
      sendDataSuccess: 'Datos enviados con éxito',
      updateUserError: 'El email o el documento que ingresaste ya están registrados',
      updateUserSuccess: 'Usuario actualizado con éxito',
      updateUserErrorUnexpected: 'Ocurrió un error inesperado al actualizar el usuario',
      addUsersBeforeContinue: 'Debes agregar al menos un usuario para continuar',
      addedUserSuccess: 'Se agregó el usuario correctamente',
      buyMoreCredits: 'Debes comprar más créditos para agregar más usuarios',
      userDeletedSuccess: 'Se eliminó el usuario seleccionado',
      addedMoreUsersSuccess: 'Se agregaron los usuarios correctamente',
      duplicatedUserError: `Se encontraron {{ duplicatesCount }} usuarios duplicados y no fueron agregados`,
      userDataError: 'Error al obtener los datos del usuario',
      planAlreadyCreated: 'Ya se creó el plan de desarrollo y puedes descargarlo',
      planDownloaded: 'Tu plan de desarrollo se descargó exitosamente',
      planGenerated: 'Tu plan de desarrollo se generó exitosamente',
      userActiveNoRegister: 'Este usuario ya ha sido activado',
      addUserSuccess: 'Usuario agregado con éxito',
      testAlreadySent: 'Las pruebas ya fueron enviadas a los usuarios.',
      generateReportSuccess: 'El reporte se está generando, espera unos segundos e intenta nuevamente',
      generateReportError: 'Error al descargar el reporte',
      editionWarning: 'Recuerda que solo debes editar y no reemplazar la información de tus usuarios',
    },
    register: {
      beforeLoginText: 'Antes de iniciar sesión',
      beforeLoginText2: 'Antes de iniciar las pruebas',
      beforeLoginText3: ', por favor completa los siguientes datos.',
      createYourPasswordPlaceholder: 'Crea tu contraseña',
      confirmationPasswordPlaceholder: 'Confirma tu contraseña',
      oneMay: 'Una mayúscula',
      oneMin: 'Una minúscula',
      oneSpecial: 'Un carácter especial (!#$%&*-/?_~)',
      minimumI8Characters: 'Debe contener al menos 8 caracteres',
      alreadyHaveAccount: '¿Ya tienes una cuenta? Inicia sesión',
      documentType: 'Tipo de documento',
      documentNumber: 'Número de documento',
      backButton: 'Atrás',
      userNameInputPlaceholder: 'Nombre del usuario',
      lastNameInputPlaceholder: 'Apellidos del usuario',
      birthday: 'Fecha de nacimiento',
      activateAccountButtonText: 'Activar cuenta',
    },
  },
  en: {
    common: {
      welcome: 'Welcome',
      credentialsText: 'Enter your credentials to log in',
      skillText: SKILLS_NAME,
      loginPasswordText: 'Enter your password',
      loginEmailText: 'Enter your email',
      loginButton: 'Log in',
      forgotPasswordButton: 'Forgot password',
      betesaTalentsText: 'Natural Talents',
      pendingTestsText: 'Pending tests.',
      startTestButtonText: 'Start',
      resumeTestButtonText: 'Resume',
      processText: 'Process',
      selectQuadrantText: 'Select one',
      selectQuadrant: 'Select a quadrant.',
      selectQuadrantTitle: 'Select quadrant to be developed',
      noCompetenceQuadrant: 'There are no competencies to develop in this quadrant.',
      viewButtonText: 'View',
      addButtonText: 'Add',
      saveButtonText: 'Save',
      deleteButtonText: 'Delete',
      userResultsLegendGraph: 'User results',
      idealProfileText: 'Ideal Profile',
      quadrantText: 'Quadrant',
      pluralQuadrantText: 'Quadrants',
      colorGraphText: colorGraphTextEN,
      downloadReportText: 'Download report',
      nextButtonText: 'Next',
      previousButtonText: 'Back',
      loadingText: 'Loading...',
      endButtonText: 'End',
      nextChapterText: 'Next chapter',
      sendButtonText: 'Send',
      cancelButtonText: 'Cancel',
      registerDateLocale: 'en',
      betesa: {
        quadrants: {
          FI: 'Frontal Left',
          BI: 'Basal Left',
          BD: 'Basal Right',
          FD: 'Frontal Right',
        },
        quadrantsAbbr: {
          FI: 'FL',
          BI: 'BL',
          BD: 'BR',
          FD: 'FR',
        },
      },
      alertLevels: {
        E: 'Extrovert',
        EI: 'Extrovert Introvert',
        'EE-II': 'EE:II',
        IE: 'Introvert Extrovert',
        I: 'Introvert',
      },
      forgotPassword: {
        title: 'Forgot password',
        description: 'Enter your email and we will send you a link to reset your password.',
        emailSended: 'Email sended',
        emailSendedDescription: 'Check your inbox and follow the instructions to reset your password.',
        changedPassword: 'Password changed',
        changedPasswordDescription: 'You can now log in with your new password.',
        inputs: {
          email: 'Email',
        },
        buttons: {
          send: 'Reset password',
        },
        notifications: {
          sendSuccess: 'We have sent you an email with instructions to reset your password.',
          success: 'Password changed',
          error: 'Error changing password',
        },
      },
      recoveryPassword: {
        title: 'Recovery password',
        requirements: {
          one: 'At least 1 uppercase letter',
          two: 'At least 1 lowercase letter',
          three: 'At least 1 special character (!#$%&*-/?_~)',
          four: 'At least 8 characters',
        },
        inputs: {
          password: 'Password',
          confirmPassword: 'Confirm password',
        },
        buttons: {
          save: 'Change password',
        },
      },
      documentTypes: {
        dni: 'ID',
        immigrationDocument: 'Immigration Document',
        passport: 'Passport',
        identityCard: 'Identity Card',
      },
    },
    notifications: {
      addSkillAlmostMessage: `You must add at least {{ number }} skills.`,
      addSkillsErrorMessage: `You cannot have fewer than {{ number }} skills.`,
      addSkillsErrorMoreThanMessage: `You cannot have more than {{ number }} skills.`,
      notAddSameSkillErrorMessage: `You cannot add the same skill twice`,
      getSkillsErrorMessage: `Skills could not be obtained.`,
      loginEmailErrorMessage: `The email you entered does not match. Check to make sure it's correct`,
      loginInactiveUserErrorMessage: `This user is not active. Please check your email and activate your account`,
      loginEmailNotMainErrorMessage: `The email you have entered is not the main one for your account. Please try a different one`,
      loginEmailOrPasswordErrorMessage: `The email or password you entered does not match. Please try again`,
      documentAlreadyRegistered: `The document does not match your user.`,
      userAlreadyActive: `The user with this document number is already active`,
      emailNotExist: `There is no account with that email address`,
      emailNotMain: `The entered email is not the main account email`,
      userNotActive: `This account is not active. Make sure to activate it`,
      createAccountError: 'An unexpected error occurred, please try again later.',
      createAccountSuccess: 'Account created successfully',
      sendDataSuccess: 'Data sent successfully',
      updateUserError: 'The email or document you have entered is already registered',
      updateUserSuccess: 'User updated successfully',
      updateUserErrorUnexpected: 'An unexpected error occurred while updating the user',
      addUsersBeforeContinue: 'You must add at least one user to continue',
      addedUserSuccess: 'The user was added successfully',
      buyMoreCredits: 'Please buy more credits to add more users',
      userDeletedSuccess: 'The selected user was deleted',
      addedMoreUsersSuccess: 'More users were added successfully',
      duplicatedUserError: `{{ duplicatesCount }} duplicate users were found and not added`,
      userDataError: 'Error obtaining user data',
      planAlreadyCreated: 'The development plan has already been created and can be downloaded',
      planDownloaded: 'Your development plan was downloaded successfully',
      planGenerated: 'Your development plan was generated successfully',
      userActiveNoRegister: 'This user has already been activated',
      addUserSuccess: 'User added successfully',
      testAlreadySent: 'The tests have already been sent to the users.',
      generateReportSuccess: 'The report is being generated, please wait a few seconds and try again',
      generateReportError: 'Error downloading the report',
      editionWarning: 'Remember that you should only edit and not replace the information of your users',
    },
    register: {
      beforeLoginText: 'Before logging in',
      beforeLoginText2: 'Before performing tests',
      beforeLoginText3: ', please fill in the following information.',
      createYourPasswordPlaceholder: 'Create your password',
      confirmationPasswordPlaceholder: 'Confirm your password',
      oneMay: 'One uppercase letter',
      oneMin: 'One lowercase letter',
      oneSpecial: 'One special character (!#$%&*-/?_~)',
      minimumI8Characters: 'At least 8 characters',
      alreadyHaveAccount: 'Already have an account? Log in',
      documentType: 'Document type',
      documentNumber: 'Document number',
      backButton: 'Back',
      userNameInputPlaceholder: 'User name',
      lastNameInputPlaceholder: 'Last name',
      birthday: 'Birthday',
      activateAccountButtonText: 'Activate account',
    },
  },
}
