import { useEffect, useState } from 'react'
import Radar from 'views/components/Graphics/Radar'
import { DataSkillsResults } from 'views/screens/Evaluated-User/store'
import { fetchResultsSkills } from 'views/screens/Evaluated-User/services/fetch-results-skills'
import { SubContainer } from '../../styled'
import { useTranslation } from 'react-i18next'
import Loader from 'views/components/UI/Loader'

interface Props {
  userId: number
  testId: number
}

const ResultsSKills = (props: Props) => {
  const { t } = useTranslation()
  const { userId, testId } = props
  const [isLoading, setIsLoading] = useState(false)
  const [userData, setUserData] = useState<DataSkillsResults | null>(null)

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true)
        const data = await fetchResultsSkills({ userId, testId })

        const dataUser = {
          labels: data.user_result.map((item: any) => item.skill_name),
          datasets: [
            {
              label: 'Usuario',
              data: data.user_result.map((item: any) => item.overall_score),
              backgroundColor: 'rgba(54, 162, 235, 0.2)',
              borderColor: 'rgba(54, 162, 235, 1)',
              borderWidth: 1,
            },
          ],
        }
        setUserData(dataUser)
        setIsLoading(false)
      } catch (error) {
        console.error(error)
      }
    }

    fetchData()
  }, [testId, userId])

  return (
    <SubContainer width="100%">
      {!isLoading && userData ? (
        <Radar
          labelResult={t('global.common.userResultsLegendGraph')}
          userScoreResult={userData.datasets[0].data as number[]}
          userResultData={[]}
          userResultLabels={userData.labels}
        />
      ) : (
        <Loader />
      )}
    </SubContainer>
  )
}

export default ResultsSKills
