import { useRef } from 'react'
import { Block } from '../../../components/UI/Contents'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'

// Components
import { Container, Row, ForgotPassword, ErrorText } from './styled'
import { Button } from '../../../components/UI/Buttons'
import Input from '../../UI/TextInput'
import { useTranslation } from 'react-i18next'

const SignInSchema = Yup.object().shape({
  password: Yup.string()
    .min(5, '¡La contraseña es muy corta!')
    .max(50, '¡La contraseña es muy larga!')
    .required('¡La contraseña es requerida!'),
  email: Yup.string().email('¡El correo no es válido!').required('¡El correo es requerido!'),
})

interface IProps {
  onSubmit: (values: any) => void
}

// MAIN
/**
 * @description Componente formulario de login
 */
const LoginForm = (props: IProps) => {
  const { t } = useTranslation()

  const { onSubmit } = props

  const initialValues = useRef({
    email: '',
    password: '',
  })

  const handleSubmit = (values: any): void => {
    onSubmit(values)
  }
  return (
    <Container>
      <Formik h validationSchema={SignInSchema} onSubmit={handleSubmit} initialValues={initialValues.current}>
        {({ errors, touched, values, handleChange, isValid }) => (
          <Form style={{ width: '100%' }}>
            <Block display="flex" flexDirection="column">
              <Block m="5px 0px 25px 0px">
                <Input
                  value={values.email}
                  onChange={handleChange('email')}
                  type="text"
                  name="email"
                  label={t('global.common.loginEmailText')}
                />
                {errors.email && touched.email ? (
                  <div>
                    <ErrorText>{errors.email}</ErrorText>
                  </div>
                ) : null}
              </Block>
              <Block m="0px 0px 30px 0px">
                <Input
                  value={values.password}
                  onChange={handleChange('password')}
                  type="password"
                  name="password"
                  label={t('global.common.loginPasswordText')}
                  error={errors.password && touched.password ? true : false}
                />
                {errors.password && touched.password ? (
                  <div>
                    <ErrorText>{errors.password}</ErrorText>
                  </div>
                ) : null}
              </Block>
              <div style={{ display: 'flex', flexDirection: 'row', verticalAlign: 'middle' }}>
                <Row style={{ paddingTop: 10, marginRight: '4px' }}>
                  <ForgotPassword href="/forgot">{t('global.common.forgotPasswordButton')}</ForgotPassword>
                </Row>
                <Row>
                  <Button
                    style={{
                      width: '100%',
                    }}
                    disabled={!isValid}
                    type="submit"
                  >
                    {t('global.common.loginButton')}
                  </Button>
                </Row>
              </div>
            </Block>
          </Form>
        )}
      </Formik>
    </Container>
  )
}

export default LoginForm
