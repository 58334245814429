import axios from 'axios'
import { CompetenceData } from '../store/entities-development-plan-betesa'

export const fetchDevelopmentPlanBetesa = async (id: string, userTestId?: number, quadrant = "FI") => {
  console.log('fetchDevelopmentPlanBetesa', id, quadrant, userTestId);
  const queryParams = [];
  if (quadrant) {
    queryParams.push(`quadrant=${quadrant}`);
  }
  if (userTestId) {
    queryParams.push(`userTestId=${userTestId}`);
  }

  // Unir los parámetros con '&', y solo agregar '?' si hay parámetros
  const queryString = queryParams.length > 0 ? `?${queryParams.join('&')}` : '';
  const url = `${process.env.REACT_APP_MAIN_API}/development_plan/actions/results/${id}${queryString}`;

  try {
    const response = (await axios.get(url)).data.data;
    response.id = id;
    return response;
  } catch (error) {
    throw new Error('Error fetching development plan data');
  }
}

export const fetchSelectedCompetenceBetesa = async (data: CompetenceData) => {
  const url = `${process.env.REACT_APP_MAIN_API}/feed_back`
  try {
    const response = await axios.post(url, data)
    return response
  } catch (error) {
    console.error(error)
  }
}

export const fetchFeedBack = async (user_test_id: number) => {
  const url = `${process.env.REACT_APP_MAIN_API}/feed_back/actions/${user_test_id}`
  try {
    const response = await axios.get(url)
    return response
  } catch (error) {
    throw new Error('Error fetching get assignment tests')
  }
}
