import React, { FC, useState } from 'react';
import { Wrapper, WrapperIcon } from './styles.styled';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Menu, MenuItem } from '@mui/material';

interface LanguageDropDownProps {
  handleAllLanguages?: any
}
const LanguageDropDown: FC<LanguageDropDownProps> = ({
  handleAllLanguages
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (language: string) => {
    handleAllLanguages(language);
    handleClose();
  };

  return (
    <Wrapper>
      <WrapperIcon onClick={handleClick}>
        <ArrowDropDownIcon style={{ fontSize: 16 }}/>
      </WrapperIcon>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={() => handleMenuItemClick('es')}>Todos Español</MenuItem>
        <MenuItem onClick={() => handleMenuItemClick('en')}>Todos Inglés</MenuItem>
      </Menu>
    </Wrapper>
  );
};

export default LanguageDropDown;