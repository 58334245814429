import jsPDF from 'jspdf'
import { useLocation, useNavigate } from 'react-router-dom'
import { useState, useEffect, FC, useRef } from 'react'
import { useSelector } from 'react-redux'
import { fetchReportBetesaWithToken } from 'services/report'
import { ReportBetesaSchema } from 'views/components/report/ReportBetesaSchema'
import { ReportSchema } from 'views/components/report/skill-report-schema'
import { Resume } from 'services/skills-report/resume'
import { Groups } from 'services/skills-report/groups'
import { GeneralTextByDimensions } from 'services/skills-report/general-text-by-dimensions'
import { DimensionScore } from 'services/skills-report/dimension-score'
// import postReportSaveReport from 'services/report/fetchPostReport'
import FetchPercentils from 'services/report/fetchPercentils'
import ModalLoader from 'views/components/UI/ModalLoader'
import { ReportData } from './store'
import { authSelector } from 'ducks/auth'
import useCompanyLogo from 'hooks/cobranding/get-company-logo/get-company-logo-hook'
import { IdealProfile } from 'services/skills-report/ideal-profile'
import { useNotification } from 'lib/context/notification.context'
import { PreviewContainer } from './styles.styled'
import { SKILLS_NAME } from 'constants/global.constants'
import { t } from 'i18next'

const PreviewGenerateReport: FC = () => {
  const location = useLocation()
  const { search } = useLocation()
  const queryParams = new URLSearchParams(search)
  const testId = location.state?.test_id || queryParams.get('test_id')
  const type = location.state?.type || queryParams.get('type')
  const userId = location.state?.user_id || queryParams.get('user_id')
  const userTestId = location.state?.user_test_id || queryParams.get('user_test_id')
  const processId = location.state?.process_id || queryParams.get('process_id')
  const lang = location.state?.lang || queryParams.get('lang')
  const { role } = useSelector(authSelector)
  const [idealProfile, setIdealProfile] = useState({} as any)
  const { token } = useSelector((state: any) => state.user)
  const [betesa, setBetesa] = useState({} as any)
  const [resume, setResume] = useState({} as any)
  const [groups, setGroups] = useState({} as any)
  const [generalTextByDimensions, setGeneralTextByDimensions] = useState({} as any)
  const [dimensionScore, setDimensionScore] = useState({} as ReportData)
  const [isReady, setIsReady] = useState(false)
  const [percentilData, setPercentilData] = useState({} as any)
  const [loading, setLoading] = useState<boolean>(false)
  const reportTemplateRef = useRef<HTMLDivElement>(null)
  const [hasCalledGetLogo, setHasCalledGetLogo] = useState(false)
  const { getCompanyLogo } = useCompanyLogo()
  const { getSuccess } = useNotification()
  const navigate = useNavigate()

  useEffect(() => {
    setTimeout(() => {
      if (!hasCalledGetLogo && token && processId) {
        setHasCalledGetLogo(true)
        getCompanyLogo({ processId: processId, isBase64: true, token })
      }
    }, 100)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getCompanyLogo, processId, token, hasCalledGetLogo])

  useEffect(() => {
    setIsReady(false)
    if (type === 'betesa') {
      setLoading(true)
      fetchReportBetesaWithToken(userId, testId)
        .then((res: any) => {
          if (res.data) {
            console.log(res.data)
            setBetesa(res.data)
            setIsReady(true)
          }
        })
        .catch((err) => {
          setIsReady(false)
          console.error(err)
        })
    } else if (type === 'skills' && token && userTestId && userId) {
      setLoading(true)
      Promise.all([
        Resume(token, userTestId),
        Groups(token, userTestId, false),
        GeneralTextByDimensions(token),
        FetchPercentils(token, userId, testId),
      ])
        .then(([resResume, resGroups, resGeneralTextByDimensions, resPercentilData]) => {
          setResume(resResume.data)
          setGroups(resGroups.data)
          setGeneralTextByDimensions(resGeneralTextByDimensions.data)
          setPercentilData(resPercentilData)
          setIsReady(true)
          setLoading(true)
        })
        .catch(() => {
          setIsReady(false)
        })
    }
  }, [testId, userId, type, token, userTestId])

  const generatePDF = async (reportTemplateRefLocal: React.RefObject<HTMLDivElement>) => {
    return new Promise(async (resolve) => {
      const doc = new jsPDF({
        orientation: 'p',
        unit: 'px',
        format: [595, 842],
        compress: true,
        precision: 2,
      })

      doc.html(reportTemplateRefLocal.current !== null ? reportTemplateRefLocal.current : '', {
        async callback(pdfDoc: any) {
          const numberOfPages = pdfDoc.internal.pages.length - 1
          if (numberOfPages > 1) {
            pdfDoc.deletePage(numberOfPages)
          }

          const pdfBlob = await pdfDoc.output('blob')
          const pdfBlobWithType = new Blob([pdfBlob], { type: 'application/pdf' })
          resolve(pdfBlobWithType)

          const getNamePdf = () => {
            if (type === 'betesa') {
              return `Betesa_${betesa.User?.first_name}_${betesa.User?.last_name}_${testId}.pdf`
            }
            const userName = `${resume?.first_name}_${resume?.last_name}`
            return `${SKILLS_NAME}_${userName}_${testId}.pdf`
          }

          setTimeout(async () => {
            await pdfDoc.save(getNamePdf())
            if (window.innerWidth < 768) {
              getSuccess(t('betesaReport.notifications.success'))
              navigate('/download-results')
            } else {
              getSuccess(t('betesaReport.notifications.preview'))
            }
          }, 1000)
        },
      })
    })
  }
  /******** Send PDF to API ********/
  // const sendPDFFileToAPI = async (apiToken: string, pdfFile: any, user_id: number, test_id: number) => {
  //   const formData: any = new FormData()
  //   formData.append('result', pdfFile, 'result.pdf')
  //   formData.append('user_id', user_id)
  //   formData.append('test_id', test_id)
  //   formData.append('bucket_test', true)
  //   formData.append('is_admin_report', role !== 'user')
  //   if (type === 'betesa') formData.append('is_betesa', true)
  //   await postReportSaveReport(apiToken, formData)
  // }

  const handleGeneratePdf = async (reportTemplate: React.RefObject<HTMLDivElement>) => {
    if (userId && testId) {
      setTimeout(async () => {
        await generatePDF(reportTemplate)
        // await sendPDFFileToAPI(token, pdfFile, userId, testId)
        setLoading(false)
      }, 1000)
    }
  }

  useEffect(() => {
    if (isReady) {
      setTimeout(async () => {
        await DimensionScore(token, userId, testId).then((res) => {
          setDimensionScore(res.data)
        })
        handleGeneratePdf(reportTemplateRef)
      }, 1000)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isReady])

  useEffect(() => {
    if (isReady && role !== 'user') {
      setTimeout(async () => {
        await IdealProfile(token, Number(userTestId)).then((res) => {
          setIdealProfile(res.data)
        })
      }, 100)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isReady])

  return (
    <PreviewContainer>
      {type === 'skills' && isReady && resume && (
        <div ref={reportTemplateRef}>
          <ReportSchema
            resume={resume}
            groups={groups}
            generalTextByDimensions={generalTextByDimensions}
            dimensionScore={dimensionScore}
            isAdmin={role !== 'user'}
            idealProfile={role !== 'user' ? idealProfile : null}
            percentil={percentilData}
          />
        </div>
      )}
      {type === 'betesa' && isReady && betesa && (
        <div ref={reportTemplateRef}>
          <ReportBetesaSchema data={betesa} isAdmin={role !== 'user'} lang={lang}/>
        </div>
      )}
      {loading && (
        <ModalLoader
          isLoading={loading}
          text={t('betesaReport.downloadReportLoadingText')}
          style={{ position: 'absolute', top: '50%' }}
        />
      )}
    </PreviewContainer>
  )
}

export default PreviewGenerateReport
