import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { globalTranslations } from 'translations/global';
import { testTranslations } from 'translations/test/test';
import sidebarTranslations from 'views/components/Sidebar/translations/sidebar.translations';
import evaluatedUserTranslations from 'views/screens/Evaluated-User/translations';
import { betesaReportTranslations } from 'views/components/report/components/betesa/translations/report-betesa.translate';
import { emailsTemplatesTranslations } from 'views/screens/Platform-administrator/Process/translations/emails.templates.translations';
import { developmentPlanTranslations } from 'views/components/report/components/development-plan/translations/development-plan.translations';

const resources = {
  en: {
    translation: {
      global: globalTranslations.en,
      test: testTranslations.en,
      sidebar: sidebarTranslations.en,
      evaluatedUser: evaluatedUserTranslations.en,
      betesaReport : betesaReportTranslations.en,
      emailTemplates: emailsTemplatesTranslations.en,
      developmentPlan: developmentPlanTranslations.en
    }
  },
  es: {
    translation: {
      global: globalTranslations.es,
      test: testTranslations.es,
      sidebar: sidebarTranslations.es,
      evaluatedUser: evaluatedUserTranslations.es,
      betesaReport : betesaReportTranslations.es,
      emailTemplates: emailsTemplatesTranslations.es,
      developmentPlan: developmentPlanTranslations.es
    }
  }
};
i18n
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  // want your translations to be loaded from a professional CDN? => https://github.com/locize/react-tutorial#step-2---use-the-locize-cdn
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    resources,
    fallbackLng: 'es',
    debug: true,

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    }
  });


export default i18n;