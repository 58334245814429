import { useMemo } from 'react';
import {NO_INTERPRETABLE_TEXT} from 'constants/global.constants';


const nameQuadrant = (name:string) => {
    switch(name) {
        case "FI":
            return "global.common.betesa.quadrants.FI";
        case "FD":
            return "global.common.betesa.quadrants.FD";
        case "BI":
            return "global.common.betesa.quadrants.BI";
        case "BD":
            return "global.common.betesa.quadrants.BD";
        default:
            return NO_INTERPRETABLE_TEXT;
    }

  return name;
};

export const useNameQuadrant = (name:string) => {
  const fullName = useMemo(() => nameQuadrant(name), [name]);
  return fullName;
};