export const headers = [
  {
    key: 'document',
    name: 'Documento',
  },
  {
    key: 'email',
    name: 'Email',
  },
  {
    key: 'first_name',
    name: 'Nombre',
  },
  {
    key: 'last_name',
    name: 'Apellido',
  },
  {
    key: 'company_name',
    name: 'Compañía',
  },
]
export const HEADERS_ACTION = [
  {
    key: 'document',
    name: 'Documento',
  },
  {
    key: 'email',
    name: 'Email',
  },
  {
    key: 'firstName',
    name: 'Nombre',
  },
  {
    key: 'lastName',
    name: 'Apellido',
  },
  {
    key: 'reason',
    name: 'Motivo',
  },
  {
    key: 'action',
    name: 'Importar desde excel',
    renderer: 'StrokedButton',
  },
]

export const HEADERS_ADDED_USERS = [
  {
    key: 'document',
    name: 'Documento',
  },
  {
    key: 'email',
    name: 'Email',
  },
  {
    key: 'first_name',
    name: 'Nombre',
  },
  {
    key: 'last_name',
    name: 'Apellido',
  },
  {
    key: 'test_types',
    name: 'Prueba',
  },
]

export const HEADERS_ADDED_USERS_EDITABLE = [
  {
    key: 'document',
    name: 'Documento',
  },
  {
    key: 'email',
    name: 'Email',
    editable: true,
    validator: true,
  },
  {
    key: 'first_name',
    name: 'Nombre',
    editable: true,
  },
  {
    key: 'last_name',
    name: 'Apellido',
    editable: true,
  },
  {
    key: 'test_types',
    name: 'Prueba',
  },
  {
    key: 'language',
    name: 'Idioma',
  },
]

export interface User {
  id?: number
  document: string
  email: string
  first_name: string
  last_name?: string
  userId?: number
  company_name?: string
  test_types?: string
  test_sent?: boolean
  active?: boolean
}

export interface UserAdded {
  active: boolean
  company_id: number
  document: string
  email: string
  first_name: string
  last_name: string
  personId: number
  process_ids: number[]
  role: string
  userId: number
  id: number
  test_types?: string
  test_sent?: boolean
  emailEdit?: boolean
  language?: string
}

export const searchData = [
  {
    id: 1,
    document: '12334567',
    email: 'coincidencia@email.com',
    name: 'coincidencia de busqueda',
    company: 'compañía',
  },
]
export const usersAdded = [
  {
    id: 1,
    document: '12334567',
    email: 'coincidencia@email.com',
    name: 'coincidencia de busqueda',
    company: 'compañía',
  },
]

export const steps = ['Información básica', 'Familia de rol', 'Selección de pruebas', 'Perfil ideal', 'Usuarios']
