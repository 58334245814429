import React, { FC } from 'react'
import { CardTitleWithColor, CardInfoWithBorderTitle, StressorsList, StressorText } from './styled'
import { H5 } from 'views/components/UI/Text'

type Answer = {
  text: string
  score: number
}
interface EmotionalStateProps {
  emotions: Answer[]
}

const EmotionalState: FC<EmotionalStateProps> = ({ emotions }) => {
  const emotionalScore = emotions.reduce((acc, emotion) => acc + emotion.score, 0)
  const filteredEmotions = emotions.filter((emotion) => emotion.score !== 0)

  const showEmotionState = () => {
    if (emotionalScore > 0) {
      return (
        <H5 margin="0 0 0 5px" sx={{ display: 'flex', alignItems: 'center' }}>
          - Positivo
        </H5>
      )
    }
    if (emotionalScore === 0) {
      return (
        <H5 margin="0 0 0 5px" sx={{ display: 'flex', alignItems: 'center' }}>
          - Neutro
        </H5>
      )
    }
    if (emotionalScore < 0) {
      return (
        <H5 margin="0 0 0 5px" sx={{ display: 'flex', alignItems: 'center' }}>
          - Negativo
        </H5>
      )
    }
  }
  return (
    <CardInfoWithBorderTitle>
      <CardTitleWithColor>
        <H5>Estado emocional</H5>
        {showEmotionState()}
      </CardTitleWithColor>
      <StressorsList>
        {filteredEmotions.map((emotion, index) => (
          <StressorText key={`emotions-detail-${index}`}>
            {emotion.text}
            {index !== filteredEmotions.length - 1 && ','}
          </StressorText>
        ))}
      </StressorsList>
    </CardInfoWithBorderTitle>
  )
}

export default EmotionalState
