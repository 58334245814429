import { FC } from 'react'
import { Wrapper } from './styled'

import rangeScoreImgSrc from 'assets/images/new-range-score-img.png'
import { ScaleContainer, Scale, ItemScale, ItemScaleText } from 'views/containers/BrainMetrics/styled'
import { useTranslation } from 'react-i18next'

interface RangeScoreImgProps {
  width?: number
  height?: number
  align?: string
}

export const RangeScoreImg: FC<RangeScoreImgProps> = ({ width = 393, height = 43, align = 'center' }) => {
  return (
    <Wrapper align={align}>
      <img
        width={`${width}px`}
        height={`${height}px`}
        src={rangeScoreImgSrc}
        alt="Imagen de rango de promedio alto bajo y medio"
      />
    </Wrapper>
  )
}

export const RangeScoreBraing: FC<RangeScoreImgProps> = ({ align = 'center' }) => {
  const { t } = useTranslation()
  return (
    <Wrapper align={align}>
        <ScaleContainer>
          <Scale>
            <p>0</p>
            <p>40</p>
            <p style={{ marginLeft: '6px' }}>60</p>
            <p style={{ marginRight: '-6px' }}>80</p>
            <p style={{ textAlign: 'end' }}>100</p>
          </Scale>
          <Scale>
            <ItemScale color="#F02401" style={{ borderTopLeftRadius: '16px', borderBottomLeftRadius: '16px' }}>
              <ItemScaleText color="#F02401">{t('global.common.colorGraphText.lowLevel')}</ItemScaleText>
            </ItemScale>
            <ItemScale color="#FF8402">
              <ItemScaleText color="#FF8402">{t('global.common.colorGraphText.lowAvgLevel')}</ItemScaleText>
            </ItemScale>
            <ItemScale color="#674531">
              <ItemScaleText color="#674531">{t('global.common.colorGraphText.highAvgLevel')}</ItemScaleText>
            </ItemScale>
            <ItemScale color="#2C9430">
              <ItemScaleText color="#2C9430">{t('global.common.colorGraphText.highLevel')}</ItemScaleText>
            </ItemScale>
            <ItemScale color="#9E9E9E" style={{ borderTopRightRadius: '16px', borderBottomRightRadius: '16px' }}>
              <ItemScaleText color="#9E9E9E">{t('global.common.colorGraphText.veryHighLevel')}</ItemScaleText>
            </ItemScale>
          </Scale>
        </ScaleContainer>
    </Wrapper>
  )
}
