import React, { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { MainLayout } from 'views/layouts/'
import BluesiteTable from 'views/components/UI/BluesiteTable'
import Modal from 'views/components/UI/Modal'
import {
  Container,
  ButtonContainer,
  ModalCloseButtonContainer,
  ModalBottomNavigationContainer,
  DescriptionText,
  SubtitleText,
  Row,
  NavigationText,
} from './styled'
import { H1, H2, P, H3 } from 'views/components/UI/Text'
import { Button } from 'views/components/UI/Buttons'
import { ActionAddTalent, ActionDeleteTalentToDevelop } from 'views/components/UI/BluesiteTable/Actions/GridActions'
import { useNotification } from 'lib/context/notification.context'
import CloseIcon from 'assets/icons/CloseIcon'
import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined'
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined'
import { platformAdminRoutes } from 'router/routes'
import { MOCK_ENDPOINT } from './mock'
import { useTranslation } from 'react-i18next'

interface Talent {
  id: number
  name: string
}

const ModalTalents: React.FC<{
  data: any
  actions: Talent[]
  actionDescription: string
  onClose: () => void
  visible: boolean
}> = ({ actions, onClose, visible, data, actionDescription }) => {
  const { t } = useTranslation()
  const [currentIndex, setCurrentIndex] = useState(0)

  const ITEMS_PER_PAGE = 10

  const displayedActions = actions.slice(currentIndex, currentIndex + ITEMS_PER_PAGE)

  const handlePrev = () => {
    if (currentIndex >= ITEMS_PER_PAGE) {
      setCurrentIndex(currentIndex - ITEMS_PER_PAGE)
    }
  }

  const handleNext = () => {
    if (currentIndex + ITEMS_PER_PAGE < actions.length) {
      setCurrentIndex(currentIndex + ITEMS_PER_PAGE)
    }
  }

  return (
    <Modal
      styleContent={{ width: '400px', height: '80vh', padding: '30px' }}
      show={visible}
      setShow={onClose}
      hideCloseButton
    >
      <ModalCloseButtonContainer>
        <CloseIcon onClick={onClose} />
      </ModalCloseButtonContainer>
      <H3 marginTop="-10px">{data.name}</H3>
      <DescriptionText>{actionDescription}</DescriptionText>
      <SubtitleText>Acciones de desarrollo</SubtitleText>
      {displayedActions.map((action: Talent) => (
        <P margin="5px 0px">{action.name}</P>
      ))}
      <ModalBottomNavigationContainer>
        <Row>
          {currentIndex !== 0 && (
            <>
              <ArrowCircleLeftOutlinedIcon onClick={handlePrev} style={{ color: '#2D749C' }} />
              <NavigationText onClick={handlePrev}>Anterior</NavigationText>
            </>
          )}
        </Row>
        <Row>
          {currentIndex + ITEMS_PER_PAGE < actions.length && (
            <>
              <NavigationText onClick={handleNext}>{t('global.common.nextButtonText')}</NavigationText>
              <ArrowCircleRightOutlinedIcon onClick={handleNext} style={{ color: '#2D749C' }} />
            </>
          )}
        </Row>
      </ModalBottomNavigationContainer>
    </Modal>
  )
}

//  main component
const SelectTalentsToDevelop: React.FC = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const userName = location.state?.userName ? location.state.userName : ''

  const { getWarning, getSuccess } = useNotification()

  const [talents, setTalents] = useState<Talent[]>([]) // All talents
  const [talentsSelected, setTalentsSelected] = useState<Talent[]>([]) // Selected talents
  const [currentTalent, setCurrentTalent] = useState<Talent | null | any>(null) // Selected talent
  const [idTalentsSelected, setIdTalentsSelected] = useState<number[]>([]) // Selected talents ids

  //  eslint-disable-next-line
  const [showModal, setShowModal] = useState(false)
  const [showResume, setShowResume] = useState(false)

  useEffect(() => {
    // Fetch talents data from API
    setTalents(MOCK_ENDPOINT.data.talents)
  }, [])

  // HANDLERS
  const handleAddTalent = (talent: Talent) => {
    if (talentsSelected.length >= 3) {
      return
    }
    setTalentsSelected([...talentsSelected, talent])
    setIdTalentsSelected([...idTalentsSelected, talent.id])
  }

  const handleDeleteTalent = (talent: Talent) => {
    if (talentsSelected.length === 1) {
      getWarning('No hay talentos seleccionados, debes volver a seleccionarlos')
      setShowResume(false)
    }

    const newTalentsSelected = talentsSelected.filter((t) => t.id !== talent.id)
    setTalentsSelected(newTalentsSelected)
    setIdTalentsSelected(newTalentsSelected.map((t) => t.id))
  }

  const handleSendDevelopmentPlan = () => {
    getSuccess('Plan de desarrollo enviado correctamente')
    navigate(platformAdminRoutes.DetailEvaluatedUser)
  }

  return (
    <MainLayout>
      <Container>
        {userName && <H1 margin="20px 0px">Plan de desarrollo - {userName}</H1>}
        {!showResume ? (
          <>
            <H2>Selecciona los talentos a desarrollar</H2>
            <P margin="30px 0px">Selecciona máximo tres competencias a mejorar en el plan de desarrollo</P>
            <BluesiteTable
              data={talents}
              ActionComponent={ActionAddTalent}
              headers={[{ key: 'name', name: 'Nombre' }]}
              searchBar={false}
              searchLabel=""
              page={1}
              totalPages={0}
              extraDataAction={{
                onAddTalent: handleAddTalent,
                onViewTalent: (e: any) => {
                  setCurrentTalent(e)
                  setShowModal(true)
                },
                idTalentsSelected,
              }}
            />
            {talentsSelected.length > 0 && (
              <>
                <BluesiteTable
                  data={talentsSelected}
                  ActionComponent={ActionDeleteTalentToDevelop}
                  headers={[{ key: 'name', name: 'Nombre' }]}
                  searchBar={false}
                  searchLabel=""
                  page={1}
                  totalPages={0}
                  extraDataAction={{
                    onDeleteTalent: handleDeleteTalent,
                  }}
                />
                <ButtonContainer>
                  <Button type="button" onClick={() => setShowResume(true)}>
                    Continuar
                  </Button>
                </ButtonContainer>
              </>
            )}
          </>
        ) : (
          <>
            <H2 margin="20px 0px 40px 0px">Resumen de talentos agregados</H2>
            <BluesiteTable
              data={talentsSelected}
              ActionComponent={ActionDeleteTalentToDevelop}
              headers={[{ key: 'name', name: 'Nombre' }]}
              searchBar={false}
              searchLabel=""
              page={1}
              totalPages={0}
              extraDataAction={{
                onDeleteTalent: handleDeleteTalent,
              }}
            />
            <ButtonContainer>
              <Button type="button" onClick={handleSendDevelopmentPlan}>
                Enviar
              </Button>
            </ButtonContainer>
          </>
        )}

        {showModal && (
          <ModalTalents
            data={currentTalent}
            actions={currentTalent.actions}
            actionDescription={currentTalent.description}
            visible={showModal}
            onClose={() => {
              setShowModal(false)
              setCurrentTalent(null)
            }}
          />
        )}
      </Container>
    </MainLayout>
  )
}

export default SelectTalentsToDevelop
