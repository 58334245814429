export const evaluatedUserTranslations = {
  en: {
    title: 'Talents to develop',
    noTestEmptyState: 'You do not have any pending tests.',
    downloadResults: {
      title: 'Download results',
      createPlanButtonText: 'Create development plan',
    },
    analytics: {
      title: 'Select the process to plot:',
      subtitle: 'Select the test you want to analyze',
      results: 'Results:',
      gap: 'Gap:',
      youth: 'Youth:',
    },
    developmentPlan: {
      title: 'Development plan',
      subtitle: 'Select the test to which you want to add the development plan',
      toDevelopTitle: 'Talents to be developed',
      addedSkillsTitle: 'Added Skills',
      toDevelopBetesaSubtitle: 'Select up to three skills to improve in your development plan',
      toDevelopSkillsSubtitle: 'Select up to three competencies to improve in your development plan',
      nameItem: 'Name',
      createdDevelopmentPlanTitle: 'My development plan for',
      downloadDevelopmentPlanButton: 'Download my development plan',
    },
    notifications: {
      noInterpretationTest: 'You cannot create a development plan because the test has not been interpreted',
      noSkills: 'There are no competencies to develop in this quadrant',
      noSwitchDevicePC: 'Hello! We know that you have started the test to retake it, you have to do it from your computer.',
      noSwitchDeviceMobile: 'Hello! We know that you have started the test to retake it, you have to do it from your Mobile.',
      noInterpretationBetesa: 'Test not interpreted.',
    },
    questionnaire: {
      title: 'Bluesite Questionnaire',
      buttons: {
        startTestButtonText: 'Start',
      },
      modal: {
        title: 'Send test',
        text: 'If you send the test, you cannot return to it. Are you sure you want to send it?',
      }
    },
    betesaQuestionnaire: {
      statementTest: 'Statement',
      answersOption: 'Answer option',
      dragAndDropText: 'If you want to change your answer, drop it here.',
      instructionsLegendText: 'Please read the instructions carefully before starting to answer the test.',
      instructionsLegend2Text: 'Note that all elements must be placed in a box.',
      chapterTitle: 'Questions of ',
      chapterText: 'Chapter ',
      mobileInstructions: `On the left, you will find the statements of the question. On the right, you will find the options of
                the answer. You must associate each statement with an answer. To do this, select
                the answer first and then select the statement with the one you want to
                associate. The same color will appear in each pair.`,
      of: 'of',
      cap1: {
        chapterName: 'Childhood and Youth',
        chapterDescription:
          "In this section we want to better understand how you remember your childhood and youth. There may be questions where it's not easy for you to prioritize the options. Don't worry, answer as best you can and as authentically as possible.",
        instructions:
          'In this section, you will find a series of statements, each with 4 associated elements. You should arrange them by dragging from right to left the option that most resembles you to the one that least resembles you.',
      },
      cap2: {
        chapterName: 'Free Time',
        chapterDescription:
          "In this section we want to get to know you when you're not working. There may be questions where it's not easy for you to prioritize the options. Don't worry, answer as best you can and as authentically as possible.",
        instructions:
          'In this section, you will find a series of statements, each with 4 associated elements. You should arrange them by dragging from right to left the option that most resembles you to the one that least resembles you.',
      },
      cap3: {
        chapterName: 'Work Life',
        chapterDescription:
          "In this section, we want to get to know you when you're working. There may be questions where it's not easy for you to prioritize the options. Don't worry, answer as best you can and as authentically as possible.",
        instructions:
          'In this section, you will find a series of statements, each with 4 associated elements. You should arrange them by dragging from right to left the option that most resembles you to the one that least resembles you.',
      },
      cap4: {
        chapterName: 'Self-perception',
        chapterDescription:
          "In this section, we want to understand better how you perceive yourself. There may be questions where it's not easy for you to prioritize the options. Don't worry, answer as best you can and as authentically as possible.",
        instructions:
          'In this section, you will find a series of statements, each with 4 associated elements. You should arrange them by dragging from right to left the option that most resembles you to the one that least resembles you.',
      },
      cap5: {
        chapterName: 'Productivity Environments',
        chapterDescription:
          'In this section we want to understand those scenarios where you are most productive because you achieve better focus to develop your work. Answer as best you can and in the most authentic way possible.',
        instructions:
          'In this section, you will find a statement. You should choose the answer that is closest to your way of thinking.',
      },
      cap6: {
        chapterName: 'Emotional State',
        chapterDescription:
          'In this section, we want to know how you have felt over the past year. Answer as best you can and as authentically as possible.',
        instructions:
          'In this section, you will find a statement. You should choose the answer that is closest to your way of thinking.',
      },
      cap7: {
        chapterName: 'Stressors',
        chapterDescription:
          'In this section, we want to understand your experience of situations that can be stressful for you, positively or negatively. Answer as best you can and as authentically as possible.',
        instructions:
          'Indicate which of the following events has happened to you in the last year (choose one or several options)',
      },
      betesaDragAndDropOptions: {
        opt1: 'The most similar to me',
        opt2: 'Similar to me',
        opt3: 'Somewhat similar to me',
        opt4: 'Not similar to me',
      },
      betesaNotifications: {
        successChapter: 'Chapter saved successfully',
        endTest: 'Test saved successfully',
        mobileAlertSelection: 'First, you must select an item from the right column',
        mobileAlertNoSelection: 'You have selected an item from the right column, now you must select an item from the left column',
      }
    },
  },
  es: {
    title: 'Competencias a desarrollar',
    noTestEmptyState: 'No tienes pruebas pendientes por contestar.',
    downloadResults: {
      title: 'Descargar resultados',
      createPlanButtonText: 'Crear plan de desarrollo',
    },
    analytics: {
      title: 'Selecciona el proceso a graficar:',
      subtitle: 'Selecciona la prueba que deseas analizar',
      results: 'Resultados:',
      gap: 'Brecha:',
      youth: 'Juvenil:',
    },
    developmentPlan: {
      title: 'Plan de desarrollo',
      subtitle: 'Selecciona la prueba a la que deseas agregar el plan de desarrollo',
      toDevelopTitle: 'Talentos a desarrollar',
      addedSkillsTitle: 'Competencias agregadas',
      toDevelopBetesaSubtitle: 'Selecciona máximo tres talentos a mejorar en tu plan de desarrollo',
      toDevelopSkillsSubtitle: 'Selecciona máximo tres competencias a mejorar en tu plan de desarrollo',
      nameItem: 'Nombre',
      createdDevelopmentPlanTitle: 'Mi plan de desarrollo de',
      downloadDevelopmentPlanButton: 'Descargar mi plan de desarrollo',
    },
    notifications: {
      noInterpretationTest: 'No puedes crear un plan de desarrollo porque la prueba no ha sido interpretada',
      noSkills: 'No hay competencias a desarrollar en este cuadrante',
      noSwitchDevicePC: '¡Hola! sabemos que ya iniciaste tu prueba para retomarla debes hacerlo desde tu equipo.',
      noSwitchDeviceMobile: '¡Hola! sabemos que ya iniciaste tu prueba para retomarla debes hacerlo desde tu móvil.',
      noInterpretationBetesa: 'Uninterpreted test results.'
    },
    questionnaire: {
      title: 'Bluesite Questionnaire',
      buttons: {
        startTestButtonText: 'Comenzar',
      },
      modal: {
        title: 'Enviar prueba',
        text: 'Si envías la prueba, no podrás volver a realizarla. ¿Estás seguro de que quieres enviarla?',
      }
    },
    betesaQuestionnaire: {
      statementTest: 'Enunciado',
      answersOption: 'Opción de respuesta',
      dragAndDropText: 'Si deseas cambiar tu respuesta arrastra aquí.',
      instructionsLegendText: 'Por favor, lee detenidamente las instrucciones antes de empezar a contestar tu prueba.',
      instructionsLegend2Text: 'Ten en cuenta que todos los elementos deben quedar ubicados en alguna casilla.',
      chapterTitle: 'Preguntas de ',
      chapterText: 'Capítulo ',
      mobileInstructions: `A tu izquierda, encontrarás los enunciados de la pregunta. A tu derecha, encontrarás las opciones de
                respuesta. Debes asociar cada uno de los enunciados con una opción de respuesta. Para hacerlo,
                selecciona primero la opción de respuesta y luego selecciona el enunciado con la que quieres
                emparejarlo. Aparecerá el mismo color en cada pareja.`,
      of: 'de',
      cap1: {
        chapterName: 'Niñez y juventud',
        chapterDescription:
          'En esta sección queremos entender mejor cómo recuerdas tu época de niñez y juventud. Puede haber preguntas en las que no te sea fácil priorizar las opciones. No te preocupes, contesta como mejor puedas hacerlo y de la forma más auténtica posible.',
        instructions:
          'En esta sección encontrarás una serie de enunciados, cada uno con 4 elementos asociados. Deberás ordenarlos arrastrando de derecha a izquierda la opción que más se parece a ti hasta la que menos se parece.',
      },
      cap2: {
        chapterName: 'Tiempo libre',
        chapterDescription:
          'En esta sección queremos conocerte cuando no estás trabajando. Puede haber preguntas en las que no te sea fácil priorizar las opciones. No te preocupes, contesta como mejor puedas hacerlo y de la forma más auténtica posible.',
        instructions:
          'En esta sección encontrarás una serie de enunciados, cada uno con 4 elementos asociados. Deberás ordenarlos arrastrando de derecha a izquierda la opción que más se parece a ti hasta la que menos se parece.',
      },
      cap3: {
        chapterName: 'Vida laboral',
        chapterDescription:
          'En esta sección queremos conocerte cuando estás trabajando. Puede haber preguntas en las que no te sea fácil priorizar las opciones. No te preocupes, contesta como mejor puedas hacerlo y de la forma más auténtica posible',
        instructions:
          'En esta sección encontrarás una serie de enunciados, cada uno con 4 elementos asociados. Deberás ordenarlos arrastrando de derecha a izquierda la opción que más se parece a ti hasta la que menos se parece.',
      },
      cap4: {
        chapterName: 'Autopercepción',
        chapterDescription:
          'En esta sección queremos entender mejor cómo te percibes a ti mismo. Puede haber preguntas en las que no te sea fácil priorizar las opciones. No te preocupes, contesta como mejor puedas hacerlo y de la forma más auténtica posible.',
        instructions:
          'En esta sección encontrarás una serie de enunciados, cada uno con 4 elementos asociados. Deberás ordenarlos arrastrando de derecha a izquierda la opción que más se parece a ti hasta la que menos se parece.',
      },
      cap5: {
        chapterName: 'Entornos de productividad',
        chapterDescription:
          'En esta sección queremos conocer aquellos escenarios donde eres más productivo porque alcanzas mejor foco para desarrollar tu trabajo. Contesta como mejor puedas hacerlo y de la forma más auténtica posible.',
        instructions:
          'En esta sección encontrarás una afirmación. Debes elegir la respuesta que más se acerque a tu forma de pensar',
      },
      cap6: {
        chapterName: 'Estado emocional',
        chapterDescription:
          'En esta sección queremos conocer cómo te has sentido el último año. Contesta como mejor puedas hacerlo y de la forma más auténtica posible.',
        instructions:
          'En esta sección encontrarás una afirmación. Debes elegir la respuesta que más se acerque a tu forma de pensar',
      },
      cap7: {
        chapterName: 'Estresores',
        chapterDescription:
          'En esta sección queremos entender tu vivencia de aquellas situaciones que pueden ser estresantes para ti, positivas o negativas. Contesta como mejor puedas hacerlo y de la forma más auténtica posible.',
        instructions:
          'Indica cuál de los siguientes eventos te ha sucedido en el último año (elije una o varias opciones)',
      },
      betesaDragAndDropOptions: {
        opt1: 'Lo más parecido a mí',
        opt2: 'Parecido a mí',
        opt3: 'Poco parecido a mí',
        opt4: 'Nada parecido a mí',
      },
      betesaNotifications: {
        successChapter: 'Capítulo guardado correctamente',
        endTest: 'Prueba finalizada y guardada correctamente',
        mobileAlertSelection: 'Primero debes seleccionar un ítem de la columna derecha',
        mobileAlertNoSelection: 'Ya seleccionaste un ítem de la columna derecha, ahora debes seleccionar uno de la columna izquierda',
      }
    },
  },
}

export default evaluatedUserTranslations
