import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import FormControl from '@mui/material/FormControl'
import { FormControlLabel, Radio, RadioGroup } from '@mui/material'
import { Container } from './styled'
import { ComponentProps } from 'views/screens/Evaluated-User/store'
import { Questions } from '../../styled'
import QuestionNavigation from '../components/questionNavigation'
import { setQuestion, setCurrentSelectedOption } from '../../../../store'

const QuestionUniqueOptions = ({ questions = [], previousAnswers }: ComponentProps) => {
  const { currentQuestion } = useSelector((state: any) => state.questions)
  const current = questions[currentQuestion]
  const [optionQuestion, setOptionQuestion] = useState(previousAnswers[current.id] || '')
  const dispatch = useDispatch()

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const objValue = JSON.parse(event.target.value)

    setOptionQuestion(objValue)
    dispatch(setCurrentSelectedOption(objValue))
  }

  useEffect(() => {
    dispatch(setQuestion(current))
    dispatch(setCurrentSelectedOption(previousAnswers[current.id] || ''))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [current, dispatch])

  console.log('current.options', current.options)

  return (
    <Container>
      <Questions>{current.text}</Questions>
      <FormControl>
        <RadioGroup
          aria-labelledby="demo-controlled-radio-buttons-group"
          name="controlled-radio-buttons-group"
          value={JSON.stringify(optionQuestion)}
          onChange={handleChange}
        >
          {current.options.map((answer) => (
            <FormControlLabel
              key={answer.id}
              value={JSON.stringify(answer)}
              control={<Radio />}
              label={answer.text}
              checked={answer.id === optionQuestion.id}
            />
          ))}
        </RadioGroup>
      </FormControl>
      <QuestionNavigation onContinue={() => !!optionQuestion} />
    </Container>
  )
}

export default QuestionUniqueOptions
