import styled from 'styled-components'

export const OptionAnswersDiv = styled.div`
  display: flex;
  flex-direction: row;
  gap: 30px;
  padding: 20px;
  @media (max-width: 992px) {
    display: block;
    height: max-content;
  }
`
export const MatchingAnswersDiv = styled.div`
  display: flex;
  flex-direction: row;
  gap: 30px;
  @media (min-width: 992px) {
    display: none;
  }
  @media (max-width: 992px) {
    padding: 0px;
    height: max-content;
    justify-content: space-around;
  }
`
export const OptionItemDiv = styled.div<{ isDraggingOver?: boolean }>`
  width: 100%;
  min-height: 182px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 10px;
  position: relative;
  gap: 20px;
  align-self: flex-start;
  justify-content: center;
  justify-items: center;
  border: 2px dashed #2d749c;
  border-radius: 8px;
  text-align: center;
  @media (max-width: 992px) {
    display: none;
  }
  @media (max-width: 574px) {
    font-size: 3vw;
  }
`;
export const OptionColumnOne = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  @media (min-width: 992px) {
    display: none;
  }
  @media (max-width: 992px) {
    width: 100px;
  }
`
export const OptionColumnTwo = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  @media (min-width: 992px) {
    display: none;
  }
  @media (max-width: 992px) {
    display: block;
    width: 137px;
  }
`
export const OptionColumnOneItemA = styled.div`
  background: white;
  padding: 15px;
  text-align: left;
  border-radius: 8px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  @media (min-width: 992px) {
    display: none;
  }
`
export const OptionColumnOneItemB = styled.div`
  background: white;
  padding: 15px;
  text-align: left;
  border-radius: 8px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  @media (min-width: 992px) {
    display: none;
  }
`
export const OptionColumnOneItemC = styled.div`
  background: white;
  padding: 15px;
  text-align: left;
  border-radius: 8px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  @media (min-width: 992px) {
    display: none;
  }
`
export const OptionColumnOneItemD = styled.div`
  background: white;
  padding: 15px;
  text-align: left;
  border-radius: 8px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  @media (min-width: 992px) {
    display: none;
  }
`
export const OptionColumnOneItem = styled.div<{color?: string}>`
  background: ${props => props.color || 'white'};
  padding: 35px;
  text-align: left;
  border-radius: 8px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  @media (min-width: 992px) {
    display: none;
  }
  &:active{
    transform: scale(.9);
  }
  @media (max-width: 992px) {
    width: 100px;
    font-weight: 400;
    font-size: 12px;
    line-height: 22px;
    color: #1f2124;
    padding: 10px;
  }
`
export const OptionColumnTwoItem =styled.div<{color?: string}>`
  background: ${props => props.color || 'white'};
  padding: 15px;
  text-align: left;
  border-radius: 8px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  @media (min-width: 992px) {
    display: none;
  }
  &:active{
    transform: scale(.9);
  }
  @media (max-width: 992px) {
    width: 100px;
    font-weight: 400;
    font-size: 12px;
    line-height: 22px;
    color: #1f2124;
    margin-bottom: 10px;
  }
`

export const OptionItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 266px;
  height: 68px;
  background: #ffffff;
  grid-gap: 10px;
  padding: 4px 12px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
`
export const OptionDropDiv = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  @media (max-width: 992px) {
    display: none;
  }
`
export const OptionDropRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 30px;
`
export const OptionDropArea = styled.div`
  display: flex;
  justify-content: center;
  border: 1px solid #2d749c;
  border-radius: 5px;
  width: 296px;
  height: 82px;
`

export const ParagraphChapter = styled.p`
  font-size: 18px;
  @media (max-width: 574px) {
    font-size: 3vw;
    margin-top: 0;
  }
`
export const ParagraphChapterConditional = styled.p`
  font-size: 18px;
  @media (max-width: 992px) {
    display:none;
  }
`
export const OptionType = styled.p`
  align-self:center; 
  color:#2D749C; 
  text-align:center;
`

export const MessageEmptyOptionText = styled.p`
  width: 100%;
  height: 20px;
  position: absolute;
  top: 45%;
  margin: 0;
  font-size: 16px;
  color: #2d749c;
  font-weight: bold;
`
