import { FC, useState } from 'react'
import { MainLayout } from 'views/layouts'
import { StrokedButton } from 'views/components/UI/Buttons'
import InterpretationDetail from '../interpretation-detail'
import { BetesaTitle, WrapperTitle } from '../styled'

const ReinterpretationDetail: FC = () => {
  const [isEditing, setIsEditing] = useState<boolean>(false)

  return (
    <MainLayout>
      <WrapperTitle>
        <BetesaTitle>Reinterpretación de betesa</BetesaTitle>
        <StrokedButton onClick={() => setIsEditing(!isEditing)}>
          {isEditing ? 'Cancelar reinterpretación' : 'Reinterpretar'}
        </StrokedButton>
      </WrapperTitle>
      <InterpretationDetail disable={!isEditing} isReinterpretation={true} />
    </MainLayout>
  )
}

export default ReinterpretationDetail
