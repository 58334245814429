import { useNameLevelAlert } from 'hooks/useNameLevelAlert'
import { useNameQuadrant } from 'hooks/useNameQuadrant'
import {
  ContainerTable,
  Subtitle,
  HeadingTable,
  TableCell,
  InnerTable,
  InnerTableCell,
  TopInnerTableRow,
  BottomInnerTableRow,
  CustomTableCell,
} from '../../styled'
import { ContainerT, PreviewTable } from './first-page/styled'
import { useTranslation } from 'react-i18next'
const TableResult = ({
  domination,
  alertlevel,
  QuadrantGlobal,
  Stressors,
  emotionalText /*  positiveValue, negativeValue */,
}: any) => {
  // const isBothValuesZero = negativeValue === 0 && positiveValue === 0;
  // const greaterValue = positiveValue > negativeValue ? positiveValue : negativeValue;
  const { t } = useTranslation()
  const parsedLevel = useNameLevelAlert(alertlevel)
  const parsedQuadrant = useNameQuadrant(domination)

  return (
    <ContainerT>
      <ContainerTable>
        <Subtitle>{t('betesaReport.summaryResultsTitle')}</Subtitle>

        <PreviewTable style={{ textAlign: 'center' }}>
          <tbody>
            <tr>
              <HeadingTable>{t('betesaReport.tableSummaryResults.thinkingStructure')}</HeadingTable>
              <TableCell>{t(parsedQuadrant)}</TableCell>
            </tr>

            <tr>
              <HeadingTable>{t('betesaReport.tableSummaryResults.developmentScore')}</HeadingTable>
              <CustomTableCell>
                <InnerTable
                  style={{
                    borderLeftStyle: 'hidden',
                    borderRightStyle: 'hidden',
                  }}
                >
                  <TopInnerTableRow>
                    <InnerTableCell>{t('global.common.betesa.quadrantsAbbr.FI')}</InnerTableCell>
                    <InnerTableCell>{t('global.common.betesa.quadrantsAbbr.BI')}</InnerTableCell>
                    <InnerTableCell>{t('global.common.betesa.quadrantsAbbr.BD')}</InnerTableCell>
                    <InnerTableCell>{t('global.common.betesa.quadrantsAbbr.FD')}</InnerTableCell>
                  </TopInnerTableRow>
                  <BottomInnerTableRow>
                    <InnerTableCell>{QuadrantGlobal?.FI}</InnerTableCell>
                    <InnerTableCell>{QuadrantGlobal?.BI}</InnerTableCell>
                    <InnerTableCell>{QuadrantGlobal?.BD}</InnerTableCell>
                    <InnerTableCell>{QuadrantGlobal?.FD}</InnerTableCell>
                  </BottomInnerTableRow>
                </InnerTable>
              </CustomTableCell>
            </tr>

            <tr>
              <HeadingTable>{t('betesaReport.tableSummaryResults.alertLevel')}</HeadingTable>
              <TableCell>{t(parsedLevel)}</TableCell>
            </tr>

            <tr>
              <HeadingTable>{t('betesaReport.tableSummaryResults.emotionalState')}</HeadingTable>
              <TableCell style={{ textTransform: 'capitalize' }}>{emotionalText}</TableCell>
            </tr>

            <tr>
              <HeadingTable>{t('betesaReport.tableSummaryResults.stressors')}</HeadingTable>
              <TableCell>{`${Stressors}/100`}</TableCell>
            </tr>
          </tbody>
        </PreviewTable>
      </ContainerTable>
    </ContainerT>
  )
}

export default TableResult
