import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import dayjs from 'dayjs'
import { useLocation, useNavigate } from 'react-router-dom'
import { MainLayout } from 'views/layouts/'
import { Container, RowContainer, InfoUserContainer, InfoColumn, CardInfo, ReportContainer } from './styled'
import { H2, H5, H4, H3, P, SubtitleBold } from 'views/components/UI/Text'
import CardTypeTest from 'views/components/Cards/TestType'
import { BackButton } from 'views/components/UI/Buttons'
import { useNotification } from 'lib/context/notification.context'
import ModalLoader from 'views/components/UI/ModalLoader/modal-loader'
import skillIcon from 'assets/images/box-icon.png'
import betesaIcon from 'assets/images/brain-icon.png'
import { orderTestsByProcess } from 'utils/common'
import getProcessDetailByUser from 'services/common/process/getProcessDetailByUser'
import { superAdminRoutes } from 'router/routes'
import { useTranslation } from 'react-i18next'

const DetailUserByCompany = () => {
  const { t } = useTranslation()
  const { getError } = useNotification()
  const navigate = useNavigate()

  const { token } = useSelector((state: any) => state.user)

  const [userDetail, setUserDetail] = useState<any>(null)
  const [tests, setTests] = useState<any>(null)
  const [companiesHistory, setCompaniesHistory] = useState<any>(null)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const location = useLocation()
  const { userData } = location.state
  const { process_id: processId } = userData
  const companyId = userData.companyId

  useEffect(() => {
    if (isLoading || !processId) return
    setIsLoading(true)
    getProcessDetailByUser(token, userData.id).then((res) => {
      if (res.status === 'OK') {
        setUserDetail(res.data.user_detail)
        setTests(orderTestsByProcess(res.data.tests as any))
        setCompaniesHistory(res.data.companies_history)
        setIsLoading(false)
      } else {
        getError(t('global.errorMessages.userDataError'))
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, userData.id, userData.process_id])

  return (
    <MainLayout>
      <>
        {processId ? (
          <Container>
            <div>
              <H2 margin="1.5% 2.3%">Espacio de evaluado {`${userData?.user_name}`}</H2>
            </div>
            <InfoUserContainer>
              <InfoColumn>
                <CardInfo>
                  <H5 margin="8px 0px">Datos personales</H5>
                  <RowContainer margin="8px 0px">
                    <P margin="0px 6px 0px 0px" weight={700}>
                      Nombre completo:{' '}
                    </P>
                    <P>{`${userData?.user_name}`}</P>
                  </RowContainer>
                  <RowContainer margin="2px 0px">
                    <P weight={700} margin="0px 6px 0px 0px">
                      {' '}
                      Fecha de nacimiento:{' '}
                    </P>
                    <P>
                      {userDetail?.user_birth_date}
                      {` (${dayjs().diff(dayjs(userDetail?.user_birth_date, 'DD/MM/YYYY'), 'year')}) años`}
                    </P>
                  </RowContainer>
                  <RowContainer margin="2px 0px">
                    <P weight={700} margin="0px 6px 0px 0px">
                      {' '}
                      Documento de identidad:{' '}
                    </P>
                    <P>{userDetail?.user_document}</P>
                  </RowContainer>
                </CardInfo>
              </InfoColumn>
              <InfoColumn>
                <CardInfo>
                  <H4 style={{ marginBottom: 20 }}>Procesos a los que pertenece</H4>
                  {companiesHistory && companiesHistory.length > 0 ? (
                    companiesHistory.map((company: any, index: number) => (
                      <RowContainer margin="2px 0px" key={index}>
                        <P>{company.process_name} - </P>
                        <P fontWeight={700}>{` ${company.company_name}`}</P>
                      </RowContainer>
                    ))
                  ) : (
                    <P>No hay procesos asociados a este usuario</P>
                  )}
                </CardInfo>
              </InfoColumn>
            </InfoUserContainer>
            <ReportContainer>
              <H3 margin="10px 0px">Pruebas realizadas</H3>

              {tests && tests.length > 0 ? (
                tests.map((process: any, index: number) => {
                  return (
                    <div key={index}>
                      <SubtitleBold fontSize="1.5rem">{process.process_name}</SubtitleBold>
                      <RowContainer margin="2px 0px">
                        {process.tests.map((test: any, indexItem: number) => {
                          const CardToRender =
                            test.category_name === 'Betesa Premium' ? (
                              <CardTypeTest
                                key={`process-test-index-${indexItem}`}
                                link={superAdminRoutes.ResultsBetesa}
                                params={{
                                  testId: test.test_id,
                                  userName: userData.user_name,
                                  userId: userData.id,
                                  companyId,
                                  betesaResultId: test.betesa_test_id,
                                  processId,
                                  language: test.language,
                                }}
                                title="Betesa - Talentos naturales"
                                image={betesaIcon}
                                disabled={test.domination === null}
                              />
                            ) : (
                              <CardTypeTest
                                key={`process-test-competence-${indexItem}`}
                                link={superAdminRoutes.ResultsSkills}
                                params={{
                                  testId: test.test_id,
                                  userName: userData.user_name,
                                  userId: userData.id,
                                  companyId,
                                  processId,
                                  language: test.language,
                                }}
                                title={t('global.common.skillText')}
                                image={skillIcon}
                                disabled={!test.completed_test}
                              />
                            )
                          return CardToRender
                        })}
                      </RowContainer>
                    </div>
                  )
                })
              ) : (
                <P>Este usuario no ha presentado ninguna prueba</P>
              )}
            </ReportContainer>
            <BackButton onClick={() => navigate(-1)} />
          </Container>
        ) : (
          <>
            <h1>Este usuario no tiene procesos asociados</h1>
          </>
        )}
      </>
      {processId && <ModalLoader isLoading={isLoading} />}
    </MainLayout>
  )
}

export default DetailUserByCompany
