import { FC } from 'react'
// import { TestLayout } from 'views/layouts'
import { BtnGoBack, SaveButton, Row, GridModal, ModalContainer, ModalHeader, ModalBody } from './styled'

import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import Modal from 'views/components/UI/Modal'
import { SendTestModal } from 'views/screens/Evaluated-User/store'
import { useTranslation } from 'react-i18next'

export const SendModal: FC<SendTestModal> = ({
  openModal,
  setOpenModal,
  onSubmit,
  isLoading = false,
}: SendTestModal) => {
  const { t } = useTranslation()

  return (
    <Modal
      hideCloseButton={true}
      show={openModal}
      setShow={setOpenModal}
      styleContent={{
        width: '560px',
        height: '300px',
        borderRadius: '10px',
        padding: '20px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        backgroundColor: '#FFFFFF',
      }}
    >
      <ModalContainer>
        <ModalHeader>
          <div
            style={{
              cursor: 'pointer',
            }}
            onClick={() => {
              setOpenModal(false)
            }}
          >
            <CloseOutlinedIcon />
          </div>
        </ModalHeader>

        <ModalBody>
          <h3 style={{ textAlign: 'center' }}>{t('evaluatedUser.questionnaire.modal.title')}</h3>
          <GridModal>
            <p>{t('evaluatedUser.questionnaire.modal.text')}</p>
            <Row>
              <BtnGoBack
                onClick={() => {
                  setOpenModal(false)
                }}
              >
                {t('global.common.cancelButtonText')}
              </BtnGoBack>
              <SaveButton
                onClick={() => {
                  onSubmit()
                }}
              >
                {isLoading ? t('global.common.loadingText') : t('global.common.sendButtonText')}
              </SaveButton>
            </Row>
          </GridModal>
        </ModalBody>
      </ModalContainer>
    </Modal>
  )
}
