const sidebarTranslations = {
  en: {
    header: {
      titleText: 'Hi, {{ name }}!',
    },
    home: {
      titleText: 'Home',
    },
    downloadResults: {
      titleText: 'Download results',
    },
    analytics: {
      titleText: 'Analytics',
    },
    developmentPlan: {
      titleText: 'Development plan',
    },
    logout: {
      titleText: 'Logout',
    },
  },
  es: {
    header: {
      titleText: '¡Hola, {{ name }}!',
    },
    home: {
      titleText: 'Inicio',
    },
    downloadResults: {
      titleText: 'Descarga de resultados',
    },
    analytics: {
      titleText: 'Analíticas',
    },
    developmentPlan: {
      titleText: 'Plan de desarrollo',
    },
    logout: {
      titleText: 'Cerrar sesión',
    },
  },
}

export default sidebarTranslations
