import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { MainLayout } from 'views/layouts'
import Box from '@mui/material/Box'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import { generalDataSelector } from 'ducks/auth'
import { useSelector } from 'react-redux'
import BasicInfo from './basicInfo'
import DiagnosticInfo from './diagnosticInfo'
import SkillInfo from './skillInfo'
import ProfileInfo from './profileInfo'
import { Formik, Form } from 'formik'
import MinimumRecord from 'views/components/MinumRecord'
import Chargest from './charges'
import { postFamilyRol } from 'services/rolFamily'
import { useNotification } from 'lib/context/notification.context'
import { superAdminRoutes } from 'router/routes'
import { BACK_PAGE, DEFAULT_LEVEL } from 'constants/global.constants'
import { TypeCompetence, TypeTest } from '../../../store'
import BackButton from 'views/components/UI/BackButton/back-button'
import { validationCreateRoleFamilySchema } from '../../../schemas'
import { Button } from 'views/components/UI/Buttons'
import { BackButton as Back } from 'views/components/UI/Buttons'
import { BackContainer } from './styled'
import { t } from 'i18next'
const stepsNames: any = [
  'Información básica',
  'Habilidades asociadas',
  'Configuración de competencias',
  'Perfil ideal',
  'Cargos típicos',
]

function RenderStepContent(props: any) {
  const { typeTestValue, activeStep }: { typeTestValue: any; activeStep: any } = props
  switch (props.activeStep.step) {
    case 0:
      return <BasicInfo props={props} />
    case 1:
      return <SkillInfo props={props} />
    case 2:
      return <DiagnosticInfo props={props} />
    case 3:
      return typeTestValue[activeStep.subStep - 1]?.name === 'Betesa Premium' ? (
        <ProfileInfo props={props} />
      ) : (
        <MinimumRecord props={props} />
      )
    case 4:
      return <Chargest typicalCharges="typicalCharges" props={props} />
    default:
      return <div>Not Found</div>
  }
}

const FormFamilyRol = () => {
  const [competencesAdded, setCompetencesAdded] = useState<TypeCompetence[] | []>([])
  const navigate = useNavigate()
  const { getWarning, getSuccess, getError } = useNotification()

  const handleAdd = (skill: any, skill_id: any, weight?: any, description?: string) => {
    const competencesToAdd = competencesAdded.filter((object: any) => object.skill === skill)
    if (competencesToAdd.length > 0) {
      getWarning(`La competencia ${skill} ya fue seleccionado`)
    } else {
      setCompetencesAdded([...competencesAdded, { skill, skill_id, weight, description }])
      getSuccess(`La competencia ${skill} fue agregada`)
    }
  }

  const [typeTestValue, setTypeTestValue] = useState<TypeTest[]>([])
  const [activeStep, setActiveStep] = useState<any>({ step: 0, subStep: 1 })

  const numberValidation =
    typeTestValue[activeStep.subStep - 1]?.name === 'Betesa Premium' && activeStep.step === 3 ? 1 : 0
  const numberValidation2 =
    typeTestValue[activeStep.subStep - 1]?.name === 'Competencias' && activeStep.step === 4 ? 1 : 0
  const currentValidationSchema =
    validationCreateRoleFamilySchema[activeStep.step + numberValidation + numberValidation2]

  const { data } = useSelector(generalDataSelector)
  const { category, level, alertLevelText } = data
  const isLastStep = activeStep.step === stepsNames.length - 1

  const [datos] = useState({
    name: '',
    level: '',
    level_id: '',
    description: '',
    categories: [],
    'Betesa Premium': false,
    Competencias: false,
    typicalChargesCompetencia: [''],
    typicalCharges: [''],
    competence_skills: [
      {
        skill_id: 0,
        weight: '',
      },
    ],
    family_skills_data: [''],
    ideal_profile_data: {
      alert_level_id: '',
      profile_values: [
        {
          quadrant: '',
          priority: 0,
        },
      ],
    },
  })

  const handleCheckChange = (event: any) => {
    if (!event.target.checked) {
      const typeTest = typeTestValue.filter((object: any) => object.name !== event.target.name)
      setTypeTestValue(typeTest)
    } else {
      const type = {
        name: event.target.name,
        id: category?.filter((object: any) => object.name === event.target.name)[0]?.id,
      }
      setTypeTestValue((current) => [...current, type])
    }
  }

  const submitForm = async (values: any) => {
    values.categories = typeTestValue
    values.level = level.find((element: any) => element.id === Number(values?.level_id))?.name || DEFAULT_LEVEL

    const familyData = Object.assign(
      {},
      {
        ...values,
        ideal_profile_data: {
          alert_level_range: values.ideal_profile_data.alert_level_id.split(',').map(Number),
          profile_values: values.ideal_profile_data.profile_values.map((e: any) => {
            return {
              quadrant: e.quadrant,
              priority: e.priority,
              min: e.min,
              max: e.max,
            }
          }),
        },
      }
    )

    if (!familyData['Betesa Premium']) {
      delete familyData.ideal_profile_data
    }

    if (!familyData.Competencias) {
      delete familyData.competence_skills
    }
    delete familyData.typicalChargesCompetencia
    delete familyData.level_id
    delete familyData['Betesa Premium']
    delete familyData.Competencias

    const res: any = await postFamilyRol({
      ...familyData,
    })

    if (!res.OK && res.message) getError('No se pudo crear la familia de rol')
    if (res?.status === 'OK') {
      navigate(BACK_PAGE)
      getSuccess('Familia de rol creada correctamente')
    }
  }

  const handleSubmit = (values: any) => {
    if (isLastStep) {
      submitForm(values)
    } else if (
      activeStep.step === 1 &&
      typeTestValue.length < 2 &&
      typeTestValue.filter((e: any) => e.name === 'Betesa Premium').length
    ) {
      setActiveStep({ subStep: 1, step: activeStep.step + 2 })
    } else if (competencesAdded.length === 0 && activeStep.step === 2) {
      getWarning('Selección 1 Competencia como mínimo')
    } else if (activeStep.subStep < typeTestValue.length && activeStep.step === 3) {
      setActiveStep({ ...activeStep, subStep: activeStep.subStep + 1 })
    } else if (typeTestValue.length >= 1) {
      setActiveStep({ subStep: 1, step: activeStep.step + 1 })
    }
  }

  const handleBack = () => {
    if (activeStep.step === 0) {
      navigate(superAdminRoutes.RoleFamily)
    }
    if (
      activeStep.step === 3 &&
      typeTestValue.length < 2 &&
      typeTestValue.filter((e: any) => e.name === 'Betesa Premium')
    ) {
      setActiveStep({ ...activeStep, step: activeStep.step - 2 })
    } else {
      setActiveStep({ ...activeStep, step: activeStep.step - 1 })
    }
  }

  const handleCancel = () => {
    navigate(superAdminRoutes.RoleFamily)
  }

  /*   const isDisabled = (allErrors: any) => {
    return Object.keys(allErrors).length > 0
  } */

  return (
    <MainLayout>
      <BackContainer>
        <Back onClick={() => handleBack()} />
      </BackContainer>
      <Box sx={{ width: '100%', marginTop: 5 }}>
        <Stepper activeStep={activeStep.step} alternativeLabel>
          {stepsNames?.map((label: any, index: any) => (
            <Step key={`${label}${index}`}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>
      <Formik
        initialValues={datos}
        validationSchema={currentValidationSchema}
        validateOnChange
        validateOnMount
        onSubmit={handleSubmit}
        isSubmitting
      >
        {({ isValid, handleChange, setFieldValue, values, errors, touched }) => (
          <Form id="form-family-rol">
            {RenderStepContent({
              ProfileInfo,
              activeStep,
              handleChange,
              setFieldValue,
              setCompetencesAdded,
              handleBack,
              competencesAdded,
              handleAdd,
              values,
              errors,
              touched,
              handleCheckChange,
              typeTestValue,
              category,
              level,
              alertLevelText,
            })}

            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                marginTop: 30,
                alignItems: 'center',
                paddingBottom: 20,
              }}
            >
              <BackButton type="reset" onClick={() => handleCancel()} text="Cancelar" />

              <Button
                type="submit"
                disabled={!isValid || activeStep.step === 2 ? (competencesAdded.length ? false : true) : false}
              >
                {`${isLastStep ? 'Guardar' : t('global.common.nextButtonText')}`}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </MainLayout>
  )
}

export default FormFamilyRol
