import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Checkbox, FormControlLabel, FormGroup } from '@mui/material'
import { Container } from './styled'
import { ComponentProps } from 'views/screens/Evaluated-User/store'
import { Questions } from '../../styled'
import QuestionNavigation from '../components/questionNavigation'
import { setQuestion, setMultipleOptionsQuestion } from '../../../../store'
const QuestionMultiOptions = ({ questions = [], previousAnswers }: ComponentProps) => {
  const { currentQuestion } = useSelector((state: any) => state.questions)
  const dispatch = useDispatch()
  const current = questions[currentQuestion]
  const currentQuestionData = questions[currentQuestion]
  const [selectedOptions, setSelectedOptions] = useState<string[]>(
    previousAnswers[currentQuestionData.id]?.selectedOptions || []
  )

  const noneOfTheAboveId = currentQuestionData.options[currentQuestionData.options.length - 1].id.toString()

  const noneOfTheAboveIsSelected = previousAnswers[currentQuestionData.id]?.selectedOptions?.includes(noneOfTheAboveId)

  const [noneOfTheAboveValue, setNoneOfTheAboveValue] = useState<string | null>(
    noneOfTheAboveIsSelected ? noneOfTheAboveId : null
  )

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedOptionValue = event.target.value
    const isSelected = event.target.checked
    const isNoneOfTheAbove = event.target.name === 'Ninguno de los anteriores' || event.target.name === 'None of the above'

    if (isSelected) {
      if (isNoneOfTheAbove) {
        setSelectedOptions([selectedOptionValue])
        dispatch(
          setMultipleOptionsQuestion({
            question_id: current.id,
            selectedOptions: [selectedOptionValue],
          })
        )
        setNoneOfTheAboveValue(selectedOptionValue)
      } else {
        if (noneOfTheAboveValue) {
          const updatedOptions = selectedOptions.filter((option) => option !== noneOfTheAboveValue)
          setSelectedOptions([...updatedOptions, selectedOptionValue])
          dispatch(
            setMultipleOptionsQuestion({
              question_id: current.id,
              selectedOptions: [...updatedOptions, selectedOptionValue],
            })
          )
        } else {
          setSelectedOptions([...selectedOptions, selectedOptionValue])
          dispatch(
            setMultipleOptionsQuestion({
              question_id: current.id,
              selectedOptions: [...selectedOptions, selectedOptionValue],
            })
          )
        }
      }
    } else {
      const updatedOptions = selectedOptions.filter((option) => option !== selectedOptionValue)
      setSelectedOptions(updatedOptions)
      dispatch(
        setMultipleOptionsQuestion({
          question_id: current.id,
          selectedOptions: updatedOptions,
        })
      )
      if (isNoneOfTheAbove) {
        setNoneOfTheAboveValue(null)
      }
    }
  }
  useEffect(() => {
    dispatch(setQuestion(current))
    dispatch(
      setMultipleOptionsQuestion({
        question_id: current.id,
        selectedOptions: [...selectedOptions],
      })
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [current, dispatch])

  return (
    <Container>
      <Questions>{current.text}</Questions>
      {currentQuestionData.options.map((answer) => (
        <FormGroup key={answer.id}>
          <FormControlLabel
            control={
              <Checkbox
                value={answer.id.toString()}
                checked={selectedOptions.includes(answer.id.toString())}
                onChange={handleCheckboxChange}
                name={answer.text}
              />
            }
            label={answer.text}
          />
        </FormGroup>
      ))}
      <QuestionNavigation onContinue={() => selectedOptions.length > 0} />
    </Container>
  )
}

export default QuestionMultiOptions
