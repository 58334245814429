import {Container, ImageContainer, TextContainer, Image, TitleContainer} from './styled';
import { H4} from 'views/components/UI/Text';
import noImg from 'assets/images/no-image.png';


// images
import brainIcon from 'assets/images/brain-icon.png'; 
import boxIcon from 'assets/images/box-icon.png';
import { useNotification } from 'lib/context/notification.context';
import { useTranslation } from 'react-i18next';


interface IProps {
    title: string;
    type: 'betesa' | 'skills' | string;
    style?: React.CSSProperties;
    onSelected?: () => void;
    selected?: boolean;
    disabled?: boolean;
}

function Index(props: IProps) {
    const { t } = useTranslation();
    const { title, type, style, onSelected, selected, disabled} = props;
    const { getWarning } = useNotification()

    const handleClick = () => {
        if (disabled) {
            getWarning(t('evaluatedUser.notifications.noInterpretationTest'));
        } else if (onSelected) {
            onSelected();
        }
    };

    return (
        <Container style={{
            ...style,
            backgroundColor: selected ? '#F4F6F8' : 'white',
            border: selected ? '2px solid #2D749C' : '',
            cursor: disabled ? 'not-allowed' : 'pointer',
            opacity: disabled ? 0.5 : 1
        }} onClick={handleClick}>
            <ImageContainer>
                {
                    type === 'betesa' ? <Image src={brainIcon} /> : type === 'skills'? <Image src={boxIcon} /> :  <Image src={noImg} />
                }
            </ImageContainer>
            <TextContainer>
                <TitleContainer> 
                    <H4>{title || "Inserte titulo"}</H4>
                </TitleContainer>       
            </TextContainer>       
        </Container>
    );
}

export default Index;