import { setUserTestId } from 'ducks/evaluatedUser'
import { interpretationSelector } from 'ducks/ui'
import { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import getOneResultBetesa from 'services/common/betesa/getResultByIdTest'
import { BetesaScore } from 'types/common'
import Loader from 'views/components/UI/Loader'
import { H5 } from 'views/components/UI/Text'
import { MainLayout } from 'views/layouts'
import { setEvaluatedUserId, setTestId } from 'views/screens/super-admin/percentiles/store'

import InterpretationForm from '../components/interpretation-form/interpretation-form'
import ScoresTable from '../components/scores-table/scores-table'
import StressorsDetail from '../components/stressors-detail/stressors-detail'
import UserInfo from '../components/user-info/user-info'
import { Answer, IBetesaUserDetail, IItemDetailResult } from '../entities'
import {
  BetesaScores,
  CardInfoWithTable,
  CardRow,
  CardRowTableSticky,
  CardTitleWithColor,
  Container,
  InfoWrapper,
  LoaderContainer,
  WrapperContent,
} from './styled'
import { BetesaTitle, WrapperTitle } from '../styled'
import SelfPerceptions from '../components/self-perceptions'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import EmotionalState from '../components/emotional-state'

interface InterpretationDetailProps {
  title?: string
  disable?: boolean
  isReinterpretation?: boolean
}

const InterpretationDetail: FC<InterpretationDetailProps> = ({
  title = 'Betesas por interpretar',
  disable = false,
  isReinterpretation = false,
}) => {
  const { search } = useLocation()
  const queryParams = new URLSearchParams(search)
  const company = queryParams.get('company')
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const isInterpreted = useSelector(interpretationSelector)
  const { token } = useSelector((state: any) => state.user)
  const [userDetails, setUserDetails] = useState<IBetesaUserDetail | null>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const [previousInterpretationInfo, setPreviousInterpretationInfo] = useState<any>({})
  const [isProcessDevelop, setIsProcessDevelop] = useState<boolean>(false)
  const [interpretationUserTestId, setInterpretationUserTestId] = useState<number | null>(null)
  const scores = {
    FI: 0,
    BI: 0,
    BD: 0,
    FD: 0,
  }
  const [chapters, setChapters] = useState<IItemDetailResult[]>([
    {
      chapter_name: 'Estresores',
      scores,
    },
  ])

  const [currentTotalsSummary, setCurrentTotalsSummary] = useState<BetesaScore>(scores)

  const [stressors, setStressors] = useState<Answer[]>([
    {
      text: 'Estresores',
      score: 0,
    },
  ])
  const [emotionalState, setEmotionalState] = useState<Answer[]>([])

  const [selfPerceptionScores, setSelfPerceptionScores] = useState<BetesaScore[]>([scores])
  const [selfPerceptionsTextItems, setSelfPerceptionsTextItems] = useState<string[]>([])

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search)
    const id = searchParams.get('id')

    if (id !== null) {
      getOneResultBetesa(token, parseInt(id)).then((res: any) => {
        if (Object.keys(res.data).length > 0) {
          setInterpretationUserTestId(res.data.betesa_user_detail.user_test_id)
          setLoading(false)
          setUserDetails(res.data.betesa_user_detail)
          setChapters(res.data.betesa_results_detail.chapters)
          setEmotionalState(res.data.betesa_results_detail.emotional_state_answers)
          setStressors(res.data.betesa_results_detail.stressors_answers)
          setSelfPerceptionScores(res.data.betesa_results_detail.self_perception_scores)
          setCurrentTotalsSummary(res.data.betesa_results_detail.current_total_summary)
          setPreviousInterpretationInfo({
            hasPreviousInterpretation: res.data.has_previous_interpretation,
            result: res.data.betesa_previous_interpretation,
          })
          setIsProcessDevelop(res.data.betesa_user_detail.type_process_id === 2)
          setSelfPerceptionsTextItems(res.data.self_perception_items)
        }
      })
    }
  }, [token])

  useEffect(() => {
    dispatch(setEvaluatedUserId(Number(userDetails?.user_id)))
    dispatch(setTestId(Number(userDetails?.test_id)))
    dispatch(setUserTestId(Number(userDetails?.user_test_id)))
  }, [dispatch, isInterpreted, userDetails])

  if (!userDetails) {
    return <div> {t('global.common.loadingText')} </div>
  }

  const content = (
    <>
      {loading ? (
        <LoaderContainer>
          <Loader />
        </LoaderContainer>
      ) : (
        <Container>
          {!isReinterpretation && (
            <WrapperTitle>
              <BetesaTitle>
                {title} - {company}
              </BetesaTitle>
            </WrapperTitle>
          )}

          <WrapperContent>
            <BetesaScores>
              <CardRowTableSticky>
                <CardInfoWithTable>
                  <CardTitleWithColor>
                    <H5 style={{ marginBottom: 0 }}>Puntajes</H5>
                  </CardTitleWithColor>
                  <ScoresTable
                    chapters={chapters}
                    selfPerceptionScores={selfPerceptionScores}
                    totalSummary={currentTotalsSummary}
                  />
                </CardInfoWithTable>
              </CardRowTableSticky>
            </BetesaScores>

            <InfoWrapper>
              <InterpretationForm
                interpretation={userDetails?.domination}
                userIdParam={userDetails?.user_id}
                testIdParam={userDetails?.test_id}
                loadingState={setLoading}
                disabled={disable}
                userTestId={interpretationUserTestId ?? 0}
              />
              <SelfPerceptions selfPerceptionsItems={selfPerceptionsTextItems} />
              <CardRow>
                {userDetails && (
                  <UserInfo
                    userName={userDetails.user_name}
                    age={userDetails?.age}
                    documentTypeName={userDetails.document_type_name}
                    document={userDetails.document}
                    previousInterpretationInfo={previousInterpretationInfo}
                    isProcessDevelop={isProcessDevelop}
                  />
                )}
              </CardRow>
              {emotionalState && <EmotionalState emotions={emotionalState} />}
              <StressorsDetail stressors={stressors} />
            </InfoWrapper>
          </WrapperContent>
        </Container>
      )}
    </>
  )

  return isReinterpretation ? content : <MainLayout>{content}</MainLayout>
}

export default InterpretationDetail
