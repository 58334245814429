/* eslint-disable prefer-const */
import { useEffect, useState, useRef } from 'react'
import { questionsOptions, colorMap, getColor } from '../../constants'
import { useSelector, useDispatch } from 'react-redux'
import {
  MatchingAnswersDiv,
  OptionAnswersDiv,
  OptionColumnOne,
  OptionColumnOneItem,
  OptionColumnTwo,
  OptionColumnTwoItem,
  OptionDropArea,
  OptionDropDiv,
  OptionDropRow,
  OptionItem,
  OptionItemDiv,
  ParagraphChapterConditional,
  OptionType,
  MessageEmptyOptionText,
} from './styles'
import { useDrop } from 'hooks/useDrop'
import { ParagraphOption, ParagraphOptionCard, Questions } from '../../styled'
import {
  AnswerData,
  ComponentProps,
  DroppedItem,
  Option,
  cleanColorOptions,
  Item,
  SelectedItemsState,
  answeredQuestionsSelector,
  AnsweredQuestion,
} from 'views/screens/Evaluated-User/store'
import QuestionNavigation from '../components/questionNavigation'
import { setDropped, setQuestion, setColorOptions } from '../../../../store'
import { mapNumberToCard, extractColumnTwo } from '../../handlers'
import { useNotification } from 'lib/context/notification.context'
import { useTranslation } from 'react-i18next'

const QuestionDragDrop = ({ questions = [], previousAnswers }: ComponentProps) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { currentQuestion, colorOptions } = useSelector((state: any) => state.questions)
  const current = questions[currentQuestion]
  const { getWarning } = useNotification()
  const [selectedAnswers, setSelectedAnswers] = useState<AnswerData[]>([])
  const [isDraggingOver, setIsDraggingOver] = useState(false)
  const [history, setHistory] = useState<{ [optionId: number]: DroppedItem[] }>({})
  const { droppedItems, handleDrop, newHistoryState } = useDrop(current.options, history)
  const [, setDraggedItem] = useState<Option | null>(null)
  const [, setSelectedItems] = useState({ columnOne: null, columnTwo: null })
  const [options, setOptions] = useState(questionsOptions)
  const [isShowMessage, setIsShowMessage] = useState(false)
  const firstColumnOneRef = useRef(null)
  const [lastUpdatedId, setLastUpdatedId] = useState<number | null>(null)
  const answeredQuestions = useSelector(answeredQuestionsSelector)

  useEffect(() => {
    if (previousAnswers && previousAnswers[current?.id]) {
      setSelectedAnswers(previousAnswers[current?.id])
    }
  }, [current, previousAnswers])

  useEffect(() => {
    if (newHistoryState) {
      setHistory(newHistoryState)
    }
  }, [newHistoryState])

  useEffect(() => {
    if (selectedAnswers?.length > 0) {
      const convertedHistory: { [optionId: number]: DroppedItem[] } = {}

      const orderedAnswers = [...selectedAnswers]

      orderedAnswers.forEach((answerData, index) => {
        const matchingOption = current.options.find((option) => option.quadrant === answerData.answer.quadrant)

        if (matchingOption) {
          convertedHistory[index + 1] = [
            {
              optionIndex: index + 1,
              item: {
                active: matchingOption.active,
                created_at: matchingOption.created_at,
                id: matchingOption.id,
                quadrant: matchingOption.quadrant,
                question_id: matchingOption.question_id,
                text: matchingOption.text,
                updated_at: matchingOption.updated_at,
                weight: matchingOption.weight,
                selectedOptions: undefined,
                answerId: 0,
                quadrantWeight: 0,
                options: [],
                rightful: false,
              },
            },
          ]
        }
      })

      setHistory(convertedHistory)
    } else {
      if (Object.keys(droppedItems).length > 0) setHistory(droppedItems)
    }
  }, [selectedAnswers, current.options, droppedItems])

  const handleSelection = (item: any, columnName: any) => {
    setSelectedItems((prevState) => {
      const newItemsState = { ...prevState, [columnName]: item }
      if (columnName === 'columnOne' && prevState.columnOne /* && colorOptions[current.id].columnOne */) {
        getWarning(t('evaluatedUser.betesaQuestionnaire.betesaNotifications.mobileAlertNoSelection'))
        firstColumnOneRef.current = newItemsState.columnOne
        if (firstColumnOneRef.current !== prevState.columnOne) {
          const newOptions = [...options]
          const index = newOptions.findIndex((option) => option.id === firstColumnOneRef.current)
          if (index !== -1) {
            newOptions[index].active = false
            setOptions(newOptions)
          }
        }

        return prevState
      }

      if (!newItemsState.columnOne) {
        getWarning(t('evaluatedUser.betesaQuestionnaire.betesaNotifications.mobileAlertSelection'))
        return { columnOne: null, columnTwo: null }
      } else if (newItemsState.columnOne && newItemsState.columnTwo) {
        setSelectedItems({ columnOne: null, columnTwo: null })
        const newOption = { columnOne: newItemsState.columnOne, columnTwo: newItemsState.columnTwo }
        const currentOptions = colorOptions[current?.id] ?? []
        const doesColumnOneExist = currentOptions.some((option: any) => option.columnOne === newOption.columnOne)

        if (currentOptions.length < 4 && !doesColumnOneExist) {
          dispatch(
            setColorOptions({
              ...colorOptions,
              [current?.id]: [...currentOptions, newOption],
            })
          )
        }
        return { columnOne: null, columnTwo: null }
      }

      return newItemsState
    })
  }

  const handleDeselection = (columnName: keyof SelectedItemsState, item: any) => {
    setSelectedItems((prevState: SelectedItemsState) => {
      const newItemsStateTest = { ...prevState, [columnName]: item }
      if (current?.id && colorOptions[current.id]) {
        let match: any = null
        if (columnName === 'columnOne' && prevState.columnOne) {
          firstColumnOneRef.current = newItemsStateTest.columnOne
          if (firstColumnOneRef.current === prevState.columnOne) {
            const newOptions = [...options]
            match = firstColumnOneRef.current
            const index = newOptions.findIndex((option) => option.id === firstColumnOneRef.current)
            if (index !== -1) {
              newOptions[index].active = false
              setOptions(newOptions)
            }
            if (newItemsStateTest.columnOne === match) {
              dispatch(cleanColorOptions({ id: current.id, item: match }))
            }
            const newItemsState = { ...prevState, [columnName]: null }
            return newItemsState
          }
        } else if (colorOptions[current.id].length === 1) {
          match = colorOptions[current.id].find(
            (option: Item) =>
              option.columnOne === newItemsStateTest.columnOne ||
              (option.columnTwo && option.columnTwo.id === newItemsStateTest.columnTwo?.id)
          )
        } else {
          colorOptions[current.id].forEach((option: Item) => {
            if (
              option.columnOne === newItemsStateTest.columnOne ||
              (option.columnTwo && option.columnTwo.id === newItemsStateTest.columnTwo?.id)
            ) {
              match = option
            }
          })
        }
        if (match?.columnOne) {
          const newOptions = [...options]
          const index = newOptions.findIndex((option) => option.id === match.columnOne)
          if (index !== -1) {
            newOptions[index].active = false
            setOptions(newOptions)
          }
        }
        if (match) {
          dispatch(cleanColorOptions({ id: current.id, item: match }))
        }
      }
      const pairsDeselected = Object.values(droppedItems).flat().length <= 2
      if (pairsDeselected) {
        setHistory({})
      }
      const newItemsState = { ...prevState, [columnName]: null }
      return newItemsState
    })
  }

  const remainingOptions = current.options.filter(
    (item) =>
      !Object.values(history)
        .flatMap((droppedItemsArray) => droppedItemsArray)
        .some((droppedItem) => droppedItem && droppedItem.item.id === item.id)
  )

  const handleDragStart = (event: any, item: any) => {
    event.dataTransfer.setData('item', JSON.stringify(item))
  }

  const handleDragOver = (event: any) => {
    event.preventDefault()
    setIsDraggingOver(false)
  }

  const handleDragLeave = (event: any) => {
    event.preventDefault()
    setIsDraggingOver(false)
  }

  const handleDroppedItemDragStart = (event: any, item: any) => {
    event.dataTransfer.setData('item', JSON.stringify(item))
  }

  const handleItemDivDragOver = (event: any) => {
    event.preventDefault()
    setIsDraggingOver(true)
  }

  useEffect(() => {
    const updatedItems = Object.values(history).flatMap((items) => items.map((item: any) => item.item))
    dispatch(setDropped(updatedItems))
    dispatch(setQuestion(current))
  }, [current, dispatch, history])

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (colorOptions[current?.id]?.length === 4) {
        const newArray = extractColumnTwo(colorOptions[current?.id])
        dispatch(setDropped(newArray))
      }
    }, 0)
    return () => clearTimeout(timeoutId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [colorOptions, dispatch])

  useEffect(() => {
    setHistory(droppedItems)
    setIsDraggingOver(false)
  }, [current?.id, droppedItems])

  useEffect(() => {
    if (Object.keys(history).length === 4) {
      const currentOptions = colorOptions[current?.id] || []

      const updatedOptions = options.map((option) => {
        const isActive = currentOptions.find((o: { columnOne: number }) => o.columnOne === option.id)
        return { ...option, active: Boolean(isActive) }
      })

      const isOptionChangeRequired = !options.every(
        (option, index) => option.active === updatedOptions[index].active && option.id === updatedOptions[index].id
      )

      if (isOptionChangeRequired) {
        setOptions(updatedOptions)
      }
    }
  }, [current?.id, colorOptions, options, history])

  useEffect(() => {
    if (lastUpdatedId !== current.id) {
      const isActiveOption = options.some((option) => option.active === true)
      if (!isActiveOption) {
        const updatedOptions = options.map((option) => ({ ...option, active: false }))
        setOptions(updatedOptions)
        setLastUpdatedId(current.id)
      }
    }
  }, [current.id, options, lastUpdatedId, colorOptions, history, current])

  useEffect(() => {
    if (window.screen.width > 992) {
      let areAllHistoryItemsFilled = true
      for (let option of options) {
        if (!history[option.id] || history[option.id].length === 0) {
          areAllHistoryItemsFilled = false
          break
        }
      }

      if (areAllHistoryItemsFilled) {
        setIsShowMessage(true)
      } else {
        setIsShowMessage(false)
      }
    }
  }, [history, options])

  useEffect(() => {
    const answersByQuestionId = answeredQuestions
      .map((answeredQuestion: AnsweredQuestion, index: number) => {
        if (questions[currentQuestion].id === answeredQuestion.questionId) {
          return {
            optionIndex: index,
            item: {
              active: true,
              answerId: answeredQuestion.answerId,
              created_at: '',
              id: answeredQuestion.answerId,
              options: [],
              quadrant: answeredQuestion.quadrant,
              quadrantWeight: 0,
              question_id: answeredQuestion.questionId,
              rightful: false,
              selectedOptions: undefined,
              text: answeredQuestion.answerText,
              updated_at: '',
              weight: answeredQuestion.weight,
            },
          }
        }

        return null
      })
      .filter((item) => item !== null)
      .map((item, newIndex) => {
        return { [newIndex + 1]: [{ ...item, optionIndex: newIndex + 1 }] }
      })
    if (answersByQuestionId.length > 0) {
      setHistory(Object.assign({}, ...answersByQuestionId))
    }
  }, [answeredQuestions, currentQuestion, questions, history])

  return (
    <>
      <ParagraphChapterConditional>
        {t('evaluatedUser.betesaQuestionnaire.instructionsLegend2Text')}
      </ParagraphChapterConditional>
      <Questions>{current.text}</Questions>
      <OptionAnswersDiv>
        <OptionDropDiv>
          {options.map((option, index) => {
            const customHandleDrop = (event: any) => {
              if (history[option.id]?.length > 0) return
              handleDrop(event)
            }

            return (
              <OptionDropRow key={`${option.id}-options-${index}`} style={{ display: 'flex', flexDirection: 'row' }}>
                <ParagraphOption>{t(option.name)}</ParagraphOption>

                <OptionDropArea
                  data-area="dropArea"
                  data-id={option.id}
                  onDragOver={(event) => handleDragOver(event)}
                  onDragLeave={handleDragLeave}
                  onDrop={customHandleDrop}
                >
                  {history[option.id] && history[option.id][0] && (
                    <OptionItem
                      key={history[option.id][0].item.id}
                      draggable="true"
                      onDragStart={(event) => handleDroppedItemDragStart(event, history[option.id][0])}
                      style={{ alignSelf: 'center' }}
                    >
                      <ParagraphOptionCard>{history[option.id][0].item.text}</ParagraphOptionCard>
                    </OptionItem>
                  )}
                </OptionDropArea>
              </OptionDropRow>
            )
          })}
        </OptionDropDiv>
        <OptionItemDiv
          data-area="initialArea"
          onDragEnd={() => setDraggedItem(null)}
          onDragOver={handleItemDivDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
          isDraggingOver={isDraggingOver}
        >
          {isShowMessage && (
            <MessageEmptyOptionText>{t('evaluatedUser.betesaQuestionnaire.dragAndDropText')}</MessageEmptyOptionText>
          )}
          {current.options.map((item, index) => {
            if (
              Object.values(history)
                .flatMap((droppedItemsArray) => droppedItemsArray)
                .some((droppedItem) => droppedItem && droppedItem.item.id === item.id)
            ) {
              return null
            }
            return (
              <OptionItem
                key={`draggable-${index}`}
                draggable
                onDragStart={(event) => handleDragStart(event, item)}
                data-id={item.id}
                id={`draggable-${index}`}
              >
                <ParagraphOptionCard>{item.text}</ParagraphOptionCard>
              </OptionItem>
            )
          })}
        </OptionItemDiv>
        <MatchingAnswersDiv>
          <OptionColumnTwo>
            <OptionType>{t('evaluatedUser.betesaQuestionnaire.statementTest')}</OptionType>
            {current.options.map((item, index) => {
              const matchingSelection = colorOptions[current?.id]?.find(
                (selection: any) => selection.columnTwo.id === item.id
              )
              const selectedColor = mapNumberToCard(matchingSelection?.columnOne || '')

              return (
                <OptionColumnTwoItem
                  key={`${item.id}-columnTwo-${index}`}
                  onClick={() => {
                    return !selectedColor ? handleSelection(item, 'columnTwo') : handleDeselection('columnTwo', item)
                  }}
                  color={selectedColor ? colorMap[selectedColor] : ''}
                >
                  {item.text}
                </OptionColumnTwoItem>
              )
            })}
          </OptionColumnTwo>

          <OptionColumnOne>
            <OptionType>{t('evaluatedUser.betesaQuestionnaire.answersOption')}</OptionType>
            {options.map((item, index) => {
              const handleClick = () => {
                handleSelection(index + 1, 'columnOne')
                const newOptions = [...options]
                if (newOptions[index].active === false) {
                  newOptions[index].active = true
                  setOptions(newOptions)
                } else {
                  newOptions[index].active = false
                  setOptions(newOptions)
                }
              }

              const matchingSelection = colorOptions[current?.id]?.find(
                (selection: { columnOne: number }) => selection.columnOne === index + 1
              )
              return (
                <OptionColumnOneItem
                  key={`${item.name}-columnOne-${index}`}
                  onClick={() => {
                    return !item.active ? handleClick() : handleDeselection('columnOne', item.id)
                  }}
                  color={item.active ? getColor(item.id) : getColor(matchingSelection?.columnOne)}
                >
                  {t(item.name)}
                </OptionColumnOneItem>
              )
            })}
          </OptionColumnOne>
        </MatchingAnswersDiv>
      </OptionAnswersDiv>
      <QuestionNavigation onContinue={() => remainingOptions.length === 0 || colorOptions[current?.id]?.length === 4} />
    </>
  )
}
export default QuestionDragDrop
