import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { Block } from '../../../../components/UI/Contents'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import styled from 'styled-components'
import LoopIcon from '@mui/icons-material/Loop'

import { TextError, ErrorContainer, LastButtonsContainer, TextUploadSizeRecomended, ChangeLogo } from './styled'
import { Button } from '../../../../components/UI/Buttons'
import Input from 'views/components/UI/TextInput'
import Select from 'views/components/UI/Select'
import { P } from '../../../../components/UI/Text'
import RadioCheck from 'views/components/UI/InputRadio'
import ImagePicker from 'views/components/ImagePicker'

import { generalDataSelector } from 'ducks/auth'
import { useNotification } from 'lib/context/notification.context'

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`

interface IRow {
  margin?: string
  width?: string
  justifyContent?: string
  alignItems?: string
  flexDirection?: string
  gap?: string
}

const Row = styled.div<IRow>`
  display: flex;
  flex-direction: ${(props) => props.flexDirection || 'row'};
  justify-content: ${(props) => props.justifyContent || 'space-between'};
  align-items: ${(props) => props.alignItems || 'flex-start'};
  width: ${(props) => props.width || '100%'};
  margin-top: 10px;
  margin: ${(props) => props.margin || 'none'};
  gap: ${(props) => props.gap || 'none'};
`

const SignUpSchema = Yup.object().shape({
  name: Yup.string().required('El nombre de la empresa es obligatorio'),
  operativeId: Yup.string().required('El nombre del operativo es obligatorio'),
  contactName: Yup.string().required('El nombre del contacto es obligatorio'),
  contactEmail: Yup.string().email('El email no es válido').required('El email es obligatorio'),
  phone: Yup.string()
    .required('El teléfono es obligatorio')
    .min(7, 'El teléfono debe tener al menos 7 caracteres')
    .max(16, 'El teléfono debe tener máximo 16 caracteres'),
  typeCompanyId: Yup.string().required('El carácter es obligatorio'),
  hasIntegration: Yup.boolean(),
})

type ISubmit = {
  name: string
  typeCompanyId: number
  operativeId: number
  contactName: string
  contactEmail: string
  phone: string
  companyCharacter: string
  logo?: Blob
  hasIntegration: string
}

interface IProps {
  onSubmit: (values: ISubmit) => void
}

// MAIN
/**
 * @description Componente formulario de registro
 */
const RegisterForm = (props: IProps) => {
  const navigate = useNavigate()
  const { getWarning } = useNotification()
  const { onSubmit } = props
  const { data } = useSelector(generalDataSelector)
  const [imageCompany, setImageCompany] = useState<string | null>(null)
  const [imageBlob, setImageBlob] = useState<Blob | null>(null)
  const [companyCharacter, setCompanyCharacter] = useState<string | undefined>()
  const [hasIntegration, setHasIntegration] = useState<string | undefined>()

  const associatedOperating = data?.list_admin.map((admin: any) => {
    return {
      value: admin.id,
      label: `${admin.first_name} ${admin.last_name}`,
    }
  })

  const handleImage = (e: any) => {
    const file = e.target.files[0]
    setImageBlob(file)

    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onloadend = () => {
      setImageCompany(reader.result as string)
    }
  }

  const isDisabled = (allErrors: any) => {
    return Object.keys(allErrors).length > 0
  }

  return (
    <Container>
      <Formik
        validateOnChange={true}
        validateOnMount={true}
        validationSchema={SignUpSchema}
        onSubmit={(values) => {
          if (
            !companyCharacter ||
            !values.name ||
            !values.typeCompanyId ||
            !values.operativeId ||
            !values.contactName ||
            !values.contactEmail ||
            !values.phone ||
            !values.hasIntegration
          ) {
            return getWarning('Algunos campos están incompletos, asegurate de llenarlos')
          }
          onSubmit({
            name: values.name,
            typeCompanyId: parseInt(values.typeCompanyId),
            operativeId: parseInt(values.operativeId),
            contactName: values.contactName,
            contactEmail: values.contactEmail,
            phone: values.phone,
            companyCharacter: values.companyCharacter,
            logo: imageBlob ? imageBlob : undefined,
            hasIntegration: values.hasIntegration,
          })
        }}
        initialValues={{
          name: '',
          typeCompanyId: '',
          operativeId: '',
          contactName: '',
          contactEmail: '',
          phone: '',
          companyCharacter: '',
          hasIntegration: '',
        }}
      >
        {({ errors, touched, values, handleChange, handleBlur, handleSubmit }) => (
          <Form style={{ width: '100%' }}>
            <Block display="flex" flexDirection="column">
              <Row width="100%">
                <Row width="48%" flexDirection="column">
                  <Input
                    name="name"
                    type="text"
                    label="Nombre de la empresa"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.name}
                  />
                  {errors.name && touched.name ? (
                    <ErrorContainer>
                      <TextError>{errors.name}</TextError>
                    </ErrorContainer>
                  ) : null}
                </Row>
                <Row width="48%" flexDirection="column">
                  <Select
                    hideNeverChoice={true}
                    label="Operativo asociado"
                    options={associatedOperating}
                    onChange={(e) => {
                      handleChange('operativeId')(e.toString())
                    }}
                    value={values.operativeId}
                  />
                </Row>
              </Row>

              <Row width="100%">
                <Row width="48%" flexDirection="column">
                  <Input
                    name="contactName"
                    type="text"
                    label="Nombre de contacto"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.contactName}
                  />
                  {errors.contactName && touched.contactName ? (
                    <ErrorContainer>
                      <TextError>{errors.contactName}</TextError>
                    </ErrorContainer>
                  ) : null}
                </Row>
                <Row width="48%" flexDirection="column">
                  <Input
                    name="contactEmail"
                    type="text"
                    label="Email de contacto"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.contactEmail}
                  />
                  {errors.contactEmail && touched.contactEmail ? (
                    <ErrorContainer>
                      <TextError>{errors.contactEmail}</TextError>
                    </ErrorContainer>
                  ) : null}
                </Row>
              </Row>

              <Row width="100%">
                <Row width="48%" flexDirection="column">
                  <Input
                    name="phone"
                    type="number"
                    label="Teléfono de contacto"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.phone}
                  />
                  {errors.phone && touched.phone ? (
                    <ErrorContainer>
                      <TextError>{errors.phone}</TextError>
                    </ErrorContainer>
                  ) : null}
                </Row>
                <Row width="48%" flexDirection="column">
                  <P weight={600} color="#1F2124">
                    Logo de la empresa
                  </P>
                  {imageCompany === null ? (
                    <Row width="100%" alignItems="center" justifyContent="flex-start">
                      <ImagePicker onChange={handleImage} value={imageCompany ? imageCompany : ''} />
                      <TextUploadSizeRecomended>Tamaño recomendado: 200x200px</TextUploadSizeRecomended>
                    </Row>
                  ) : (
                    <>
                      <img
                        src={imageCompany}
                        alt="logo"
                        style={{ width: '200px', height: '200px', objectFit: 'contain' }}
                      />
                      <ChangeLogo onClick={() => setImageCompany(null)}>
                        <p style={{ color: '#00A6FF' }}>Cambiar imagen</p>
                        <LoopIcon style={{ color: '#00A6FF' }} />
                      </ChangeLogo>
                    </>
                  )}
                </Row>
              </Row>

              <Row width="100%" justifyContent="flex-start" alignItems="center">
                <P color="#1F2124" margin="0px 10px 0px 0px">
                  ¿Cuál es el carácter de esta empresa?
                </P>
                <Row gap="10px" justify-content=" flex-start" width="auto" margin="0px">
                  <RadioCheck
                    label="Corporativo"
                    onChange={() => {
                      setCompanyCharacter('corporate')
                      handleChange('typeCompanyId')('1')
                    }}
                    value={companyCharacter}
                    checked={companyCharacter === 'corporate'}
                  />

                  <Row justify-content=" flex-start" width="auto" margin="0px">
                    <RadioCheck
                      label="Vocacional"
                      onChange={() => {
                        setCompanyCharacter('vocational')
                        handleChange('typeCompanyId')('2')
                      }}
                      value={companyCharacter}
                      checked={companyCharacter === 'vocational'}
                    />
                  </Row>
                </Row>
              </Row>
              <Row width="100%" justifyContent="flex-start" alignItems="center">
                <P color="#1F2124" margin="0px 10px 0px 0px">
                  ¿Tiene integración?
                </P>
                <Row gap="10px" justify-content=" flex-start" width="auto" margin="0px">
                  <RadioCheck
                    label="Si"
                    onChange={() => {
                      setHasIntegration('true')
                      handleChange('hasIntegration')('true')
                    }}
                    value={hasIntegration}
                    checked={hasIntegration === 'true'}
                  />

                  <Row justify-content=" flex-start" width="auto" margin="0px">
                    <RadioCheck
                      label="No"
                      onChange={() => {
                        setHasIntegration('false')
                        handleChange('hasIntegration')('false')
                      }}
                      value={hasIntegration}
                      checked={hasIntegration === 'false'}
                    />
                  </Row>
                </Row>
              </Row>
            </Block>
            <LastButtonsContainer>
              <P
                onClick={() => {
                  navigate(-1)
                }}
                style={{ cursor: 'pointer' }}
                weight={700}
                color="#2D749C"
              >
                Cancelar
              </P>
              <Button disabled={isDisabled(errors)} type="submit" onClick={handleSubmit}>
                Guardar
              </Button>
            </LastButtonsContainer>
          </Form>
        )}
      </Formik>
    </Container>
  )
}

export default RegisterForm
