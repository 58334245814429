import { useCallback } from 'react'
import { useNotification } from 'lib/context/notification.context'
import { useTranslation } from 'react-i18next'

const useReports = () => {
  const { t } = useTranslation()
  const { getError, getSuccess } = useNotification()

  const handleReportDownload = useCallback(
    (user_id: number, test_id: number, process_Id: number, user_test_id: number, type: string, language?: string) => {
      try {
        const queryString =
          `?user_id=${encodeURIComponent(user_id)}` +
          `&test_id=${encodeURIComponent(test_id)}` +
          `&process_id=${encodeURIComponent(process_Id)}` +
          `&user_test_id=${encodeURIComponent(user_test_id)}` +
          `&type=${encodeURIComponent(type)}` +
          `&lang=${encodeURIComponent(language ?? 'es')}`
        const reportDownloadUrl = `${window.location.origin}/reports-download${queryString}`
        window.open(reportDownloadUrl, '_blank')

        getSuccess(t('global.notifications.generateReportSuccess'))
      } catch (error) {
        console.error('Error fetching data:', error)
        getError(t('global.notifications.generateReportError'))
      }
    },
    [getSuccess, getError, t]
  )

  return {
    handleReportDownload,
  }
}

export default useReports
