import { useNotification } from 'lib/context/notification.context';
import { useCallback, useState } from 'react';
import { downloadSummaryReportUser } from 'services/common/reports/getProcessReport/get-process-reports-service';

interface GetReportsProcessFilters {
  processId: number;
  useBetesa: boolean;
  useCompetence: boolean;
  processName: string;
  isForAllProcess?: boolean
  initDate?: string
  endDate?: string
  processIds?: number[]
}

const useReportDownloaderByExcel = () => {
  const [loading, setLoading] = useState(false);
  const { getWarning } = useNotification();

  const fetchGetReportsByExcel = useCallback(async (data: GetReportsProcessFilters) => {
    const { processId, useBetesa, useCompetence, processName, isForAllProcess, initDate, endDate, processIds } = data;
    setLoading(true);
    try {
      const downloadReport = await downloadSummaryReportUser({
        processId,
        useBetesa,
        useCompetences: useCompetence,
        isForAllProcess,
        initDate,
        endDate,
        processIds
      });

      const getFileName = () => {
        if (isForAllProcess && processIds?.length === 0 && !initDate) {
          return 'Resumen_General.xlsx';
        } else if (isForAllProcess && processIds && processIds.length > 0) {
          return `Resumen_de_${processIds.length}_procesos.xlsx`;
        } else if (isForAllProcess && initDate) {
          return `Resumen_desde-${initDate}-hasta-${endDate}.xlsx`;
        } else {
          return `Resumen_${processName}.xlsx`;
        }
      }

      const fileName = getFileName()
      if (downloadReport && downloadReport.status !== 'error') {
        const blob = new Blob([downloadReport], { type: 'xlsx' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        link.remove();
        URL.revokeObjectURL(url);
      } else {
        getWarning('No hay registros para descargar');
      }
    } catch (error) {
      console.error('Error al obtener los informes: ', error);
    } finally {
      setLoading(false);
    }
  }, [getWarning]);

  return { fetchGetReportsByExcel, loading };
};

export default useReportDownloaderByExcel;
