/* eslint-disable @typescript-eslint/no-unused-vars */
import { GraphicsContainer, SubtitleGraphic, ContainerColumnGraphic, EficientLabel, StructureImg } from './styled'
import * as math from 'mathjs'
import { useNameLevelAlert } from 'hooks/useNameLevelAlert'
import { Chart as ChartJS, RadialLinearScale, PointElement, LineElement, Filler, Tooltip, Legend } from 'chart.js'
import { PolarArea } from 'react-chartjs-2'
import Estructrure from 'assets/images/EstructuraDePensamiento.png'
import { useTranslation } from 'react-i18next'
const plugin = {
  id: 'customCanvasBackgroundColor_3',
  beforeDraw: (chart: { width?: any; height?: any; ctx?: any }, args: any, options: { color: string }) => {
    const { ctx } = chart
    ctx.save()
    ctx.globalCompositeOperation = 'destination-over'
    ctx.fillStyle = options.color || 'transparent'
    ctx.fillRect(0, 0, chart.width, chart.height)
    ctx.restore()
  },
}

ChartJS.register(RadialLinearScale, PointElement, LineElement, Filler, Tooltip, Legend, plugin)

export const EfficientGraphic = ({
  name,
  width,
  nameUser = 'Andrea',
  title = '',
  Dominance,
  IdealDominance,
  dominationData = '',
  AlertLevel,
  IdealAlertLevel,
  userName,
  isDevelopmentPlan,

  Profile = {
    FI: 60,
    ColorFi: '#FF9A02',
    FD: 100,
    ColorFd: '#25AF32',
    BI: 40,
    ColorBi: '#F00101',
    BD: 60,
    ColorBd: '#674531',
  },
  // eslint-disable-next-line @typescript-eslint/no-shadow
  IdealProfile = {},
}: any) => {
  const { t } = useTranslation()
  const FI = Profile.FI > 100 ? math.round(Profile.FI / 3) : Profile.FI
  const BI = Profile.BI > 100 ? math.round(Profile.BI / 3) : Profile.BI
  const BD = Profile.BD > 100 ? math.round(Profile.BD / 3) : Profile.BD
  const FD = Profile.FD > 100 ? math.round(Profile.FD / 3) : Profile.FD
  const ColorFi = '#FF9A02'
  const ColorFd = '#25AF32'
  const ColorBi = '#F00101'
  const ColorBd = '#674531'

  const array = [Profile.FD, Profile.BD, Profile.BI, Profile.FI]

  const data = array.map(function (num) {
    if (num > 100) return 100
    else return num
  })
  const maxIndex = data.findIndex((item) => item === Math.max(...data))
  const quadrantMapping = ['FD', 'BD', 'BI', 'FI']
  const maxQuadrant = quadrantMapping[maxIndex]
  const backgroundColorArray = ['#D0CFCF', '#D0CFCF', '#D0CFCF', '#E8E8E8']

  // backgroundColorArray[maxIndex] = '#DD7AAF';  // Comentado o eliminado

  const domIndex = quadrantMapping.indexOf(dominationData)
  if (domIndex !== -1) {
    backgroundColorArray[domIndex] = '#DD7AAF'
  }
  const data2 = {
    labels: ['Frontal Derecho', 'Basal Derecho', 'Basal Izquierdo', 'Frontal Izquierdo'],
    datasets: [
      {
        data: data,
        backgroundColor: backgroundColorArray,
        borderWidth: 1,
      },
    ],
  }

  const getLabelStyle = (x: number, y: number, angle: number) => {
    const adjustedAngle = angle + Math.PI / 4
    const radius = 0.5
    const centerX = 50 + radius * 100 * Math.cos(adjustedAngle)
    const centerY = 50 + radius * 100 * Math.sin(adjustedAngle)

    return {
      position: 'absolute',
      left: `${centerX}%`,
      top: `${centerY}%`,
      transform: `translate(-50%, -50%)`,
    } as React.CSSProperties
  }

  function getLessEfficient(index: any, length: any) {
    return (index + Math.floor((length % 2 === 0 ? length : length + 1) / 2)) % length
  }

  const labelArray = data.map((value, index) => {
    const angle = ((2 * Math.PI) / data.length) * index - Math.PI / 2
    const radius = value / 100
    const x = 50 + radius * Math.cos(angle) * 50
    const y = 50 + radius * Math.sin(angle) * 50

    const positionStyle = getLabelStyle(x, y, angle)

    const dominanceIndex = quadrantMapping.indexOf(dominationData)
    const lessEfficientIndex = getLessEfficient(dominanceIndex, data.length)

    if (index === dominanceIndex) {
      return {
        label: t('betesaReport.graph.dominance'),
        style: positionStyle,
      }
    }
    if (index === lessEfficientIndex) {
      return {
        label: t('betesaReport.graph.minor'),
        style: positionStyle,
      }
    }
    return { label: t('betesaReport.graph.aux'), style: positionStyle }
  })

  const options: any = {
    responsive: true,
    maintainAspectRatio: false,
    aspectRatio: 1,
    color: '#fff',
    scales: {
      r: {
        max: 100,
        min: 0,
        grid: {
          display: true,
        },
        ticks: {
          display: false,
        },
      },
    },
    elements: {
      arc: {
        borderWidth: 0.5,
      },
    },
    tooltip: {
      enabled: false,
    },
    plugins: {
      customCanvasBackgroundColor_3: {
        color: '#fff',
      },
      tooltip: {
        enabled: false,
      },
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
    },
  }

  const parsedArlertLevel = useNameLevelAlert(AlertLevel)
  return (
    <ContainerColumnGraphic>
      <SubtitleGraphic>{title}</SubtitleGraphic>
      <GraphicsContainer width={width} style={{ position: 'relative' }}>
        {labelArray.map((item, index) => (
          <EficientLabel key={`${item.label}_${index}`} style={item?.style}>
            {item.label}
          </EficientLabel>
        ))}
        <StructureImg src={Estructrure} alt="Estructura de pensamiento" />
        <PolarArea
          height={isDevelopmentPlan ? '450px' : '250px'}
          width={isDevelopmentPlan ? '450px' : '250px'}
          data={data2}
          options={options}
        />
      </GraphicsContainer>
    </ContainerColumnGraphic>
  )
}
