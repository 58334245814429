import React, { useRef } from 'react'
import { Block } from '../../../components/UI/Contents'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'

// Components
import { Button } from '../../../components/UI/Buttons'
import Input from '../../UI/TextInput'

//Styles
import { Container, InputContainer, Row, TextError } from './styled'
import { useTranslation } from 'react-i18next'

const ForgotSchema = Yup.object().shape({
  email: Yup.string().email('Formato de correo invalido').required('El correo electrónico es requerido'),
})

interface IProps {
  onSubmit: (values: any) => void
}

// MAIN
/**
 * @description Componente formulario de login
 */
const ForgotForm = (props: IProps) => {
  const { t } = useTranslation()
  const { onSubmit } = props

  const initialValues = useRef({
    email: '',
  })

  const handleSubmit = (values: any): void => {
    onSubmit(values)
  }

  return (
    <Container>
      <Formik h validationSchema={ForgotSchema} onSubmit={handleSubmit} initialValues={initialValues.current}>
        {({ errors, touched, values, handleChange }) => {
          return (
            <Form
              style={{
                width: '100%',
              }}
            >
              <Block display="flex" flexDirection="column" justifyContent="center" alignItems="center" width="100%">
                <InputContainer>
                  {/* <Field name="email" /> */}
                  <Input
                    value={values.email}
                    onChange={handleChange('email')}
                    type="text"
                    name="email"
                    label={t('global.common.forgotPassword.inputs.email')}
                    className="input"
                    style={{
                      width: '100%',
                      maxWidth: '400px',
                      minWidth: '200px',
                    }}
                  />
                  {errors.email && touched.email ? (
                    <div>
                      <TextError>{errors.email}</TextError>
                    </div>
                  ) : null}
                </InputContainer>
                <Row>
                  {/* <button type="submit">Submit</button> */}
                  <Button
                    style={{
                      height: '52px',
                      padding: '10px 20px',
                      marginTop: '30px',
                    }}
                    disabled={errors.email ? true : false}
                    type="submit"
                  >
                    {t('global.common.forgotPassword.buttons.send')}
                  </Button>
                </Row>
              </Block>
            </Form>
          )
        }}
      </Formik>
    </Container>
  )
}

export default ForgotForm
