import axios from 'axios'


type IData  = {
    name: string;
    typeCompanyId: number;
    contactName: string;
    contactEmail: string;
    phone: string;
    operativeId: number;
    logo?: Blob | null;
    hasIntegration: string;
}

/**
 * @description service to create a new company
 */
const createNewCompany = async ( data: IData ) => {

    const body = new FormData();
      body.append("name", data.name);
      body.append("type_company_id", data.typeCompanyId.toString());
      body.append("contact_name", data.contactName);
      body.append("contact_email", data.contactEmail);
      body.append("phone", data.phone);
      body.append("operative_id", data.operativeId.toString());
      body.append("has_integration", data.hasIntegration);

    if (data.logo) {
      body.append("logo", data.logo , "logo.png");
    }


    const response = await axios.post(`/company`, body).then((res) => {
      return res.data
    }).catch((error) => {
      return { ok: false, message: error.message };
    })

    return response

}

export default createNewCompany
