export const betesaReportTranslations = {
  es: {
    adjustmentLevelPage: {
      title: 'Nivel de Ajuste al Perfil Ideal',
      subtitle: 'Nivel de Ajuste al Rol de ',
      evaluatedProfileLegend: 'Perfil del Evaluado',
      idealProfileLegend: 'Perfil Ideal',
      levelAlertLegend: 'Nivel de Alerta:',
      thinkingStructureLegend: 'Estructura de Pensamiento Deseada',
      thinkingStructureStyleLegend: 'Estructura de Pensamiento de',
      priority: 'Prioridad',
      range: 'Rango',
      dominanceQuadrant: 'Cuadrante dominante de ',
      onePriorityQuadrant: 'Cuadrante prioridad uno: ',
      idealAlertLevel: 'Nivel de Alerta deseado: ',
      idealAlertLevelOf: 'Nivel de Alerta de ',
    },
    downloadReportLoadingText: 'Descargando reporte',
    of: 'de',
    summaryResultsTitle: 'Resumen de Resultados',
    developmentScoresTitle: 'Tus puntajes de desarrollo en:',
    tableSummaryResults: {
      thinkingStructure: 'Estructura de pensamiento',
      developmentScore: 'Puntaje de Desarrollo',
      alertLevel: 'Nivel de Alerta',
      emotionalState: 'Estado Emocional',
      stressors: 'Exposición a Estresores',
    },
    alertLevels: {
      E: 'Extravertido',
      EI: 'Extravertido Introvertido',
      EEII: 'EE:II',
      IE: 'Introvertido Extravertido',
      I: 'Introvertido',
    },
    graph: {
      dominance: 'Dominancia',
      aux: 'Auxiliar',
      minor: 'Menos eficiente',
      frontal: 'Frontal',
      basal: 'Basal',
      right: 'Derecho',
      left: 'Izquierdo',
    },
    chapters: {
      chapter1Name: 'Niñez y Juventud',
      chapter2Name: 'Tiempo Libre',
      chapter3Name: 'Vida Laboral',
      chapter4Name: 'Autopercepción',
    },
    betesaSchemeTitle: 'Esquema Betesa',
    yourAlertLevel: 'Tu nivel de alerta es:',
    moodCondition: 'Condición Anímica Reciente',
    lastYearMoodCondition: 'Estado emocional del último año:',
    stressorsExposure: 'Exposición a Estresores:',
    stressors: 'Estresores',
    noneOfTheAbove: 'Ninguno de los anteriores',
    positiveAdjectives: 'Adjetivos Positivos',
    negativeAdjectives: 'Adjetivos Negativos',
    interpretativeReportTitle: 'Informe Interpretativo',
    someAptitudeFor: 'Algunas aptitudes asociadas con tu dominancia:',
    someAptitudeFor80: 'Algunas aptitudes asociadas con tus cuadrantes de alto nivel de desarrollo (puntaje superior a 80):',
    moreSpendEnergy: 'Actividades donde consumes más energía:',
    notifications: {
      preview: 'Esta es una previsualización de tu reporte, tú reporte se ha descargado en tu equipo.',
      success: 'Tu reporte se ha descargado en tu equipo.',
    }
  },
  en: {
    adjustmentLevelPage: {
      title: 'Ideal Profile Adjustment Level',
      subtitle: 'Adjustment Level for ',
      evaluatedProfileLegend: 'Ideal Profile',
      idealProfileLegend: 'User Profile',
      levelAlertLegend: 'Alert Level:',
      thinkingStructureLegend: 'Ideal Thinking Style',
      thinkingStructureStyleLegend: 'Thinking Style of',
      priority: 'Priority',
      range: 'Range',
      dominanceQuadrant: 'Dominance Quadrant of ',
      onePriorityQuadrant: 'Priority 1 Quadrant: ',
      idealAlertLevel: 'Ideal Alert Level: ',
      idealAlertLevelOf: 'Alert Level of ',
    },
    downloadReportLoadingText: 'Downloading report',
    of: 'of',
    summaryResultsTitle: 'Summary Results',
    developmentScoresTitle: 'Your scores in:',
    tableSummaryResults: {
      thinkingStructure: 'Thinking Structure',
      developmentScore: 'Development Score',
      alertLevel: 'Alert Level',
      emotionalState: 'Mood Condition',
      stressors: 'Exposure to Stressors',
    },
    alertLevels: {
      E: 'Extraverted',
      EI: 'Extraverted Introverted',
      EEII: 'EE:II',
      IE: 'Introverted Extraverted',
      I: 'Introverted',
    },
    graph: {
      dominance: 'Dominance',
      aux: 'Auxiliary',
      minor: 'Least efficient',
      frontal: 'Frontal',
      basal: 'Basal',
      right: 'Right',
      left: 'Left',
    },
    chapters: {
      chapter1Name: 'Childhood and Youth',
      chapter2Name: 'Free Time',
      chapter3Name: 'Work Life',
      chapter4Name: 'Self-Perception',
    },
    betesaSchemeTitle: 'Betesa Blueprint',
    yourAlertLevel: 'Your alert level is:',
    moodCondition: 'Recent Mood Condition',
    lastYearMoodCondition: 'Mood Condition Last Year:',
    stressorsExposure: 'Exposure to Stressors:',
    stressors: 'Stressors',
    noneOfTheAbove: 'None of the above',
    positiveAdjectives: 'Positive Adjectives',
    negativeAdjectives: 'Negative Adjectives',
    interpretativeReportTitle: 'Interpretative Report',
    someAptitudeFor: 'Some skills associated with your dominance:',
    someAptitudeFor80: 'Some skills associated with your high-level development quadrants (score greater than 80):',
    moreSpendEnergy: 'Activities where you consume more energy:',
    notifications: {
      preview: 'This is a preview of your report, the report has been downloaded to your device.',
      success: 'The report has been downloaded to your device.',
    }
  },
}
