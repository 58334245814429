import styled from 'styled-components'

export const Container = styled.div`
  min-height: 90vh;

  @media (max-width: 992px) {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`

interface IRowProps {
  width?: string
  height?: string
  flexDirection?: string
  justifyContent?: string
  alignItems?: string
  margin?: string
}

export const Row = styled.div`
  display: flex;
  flex-direction: ${(props: IRowProps) => props.flexDirection || 'row'};
  width: ${(props: IRowProps) => props.width || '100%'};
  justify-content: ${(props: IRowProps) => props.justifyContent || 'flex-start'};
  align-items: ${(props: IRowProps) => props.alignItems || 'flex-start'};
  height: ${(props: IRowProps) => props.height || 'auto'};
  margin: ${(props: IRowProps) => props.margin || '0'};

  @media (max-width: 992px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`

export const TitlePrincipal = styled.h1`
  font-family: 'Public Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #303030;
  margin-right: 10px;
`

export const TextDelete = styled.div`
  font-family: 'Public Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #e44646;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
    opacity: 0.8;
  }

  @media (max-width: 992px) {
    font-size: 14px;
  }

  @media (max-width: 768px) {
    font-size: 12px;
  }
`

export const ContainerTitleSelect = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: flex-start;

  @media (max-width: 992px) {
    flex-direction: column;
    align-items: center;
  }
  @media (max-width: 574px) {
  }
`

// GRID

export const GridContainer = styled.div`
  width: 100%;
  margin-top: 40px;
`

// grid with 3 columns with proportion 8 - 1 - 2 fr
export const Grid = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 9fr 1fr 2fr;
  grid-gap: 20px;

  @media (max-width: 992px) {
    grid-gap: 10px;
    grid-template-columns: 6fr 2fr 4fr;
  }
`
interface IGridItemProps {
  width?: string
  flexDirection?: string
  justifyContent?: string
  alignItems?: string
  marginBottom?: string
}

export const GridItem = styled.div<IGridItemProps>`
  width: ${(props: IGridItemProps) => props.width || '100%'};
  display: flex;
  flex-direction: ${(props: IGridItemProps) => props.flexDirection || 'row'};
  justify-content: ${(props: IGridItemProps) => props.justifyContent || 'space-between'};
  align-items: ${(props: IGridItemProps) => props.alignItems || 'center'};
  margin-bottom: 10px;
`

// DIVIDER

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: #1f2124;
  margin: 20px 0px;
`

// MODAL

export const ModalContainer = styled.div`
  position: absolute;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.2);
`

export const Modal = styled.div`
  width: 50%;
  height: 50%;
  background: #ffffff;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
`

export const ModalContent = styled.div`
  width: 100%;
  height: 100%;
`

export const ActionsContainer = styled.div`
  width: 100%;
  margin: 0px 0px 10px 0px;
  height: auto;
  overflow-y: scroll;
  // hide scrollbar
  &::-webkit-scrollbar {
    display: none;
  }
  /* 

    @media (max-width: 992px) {
       height: 50%;
    } */
`

export const TextView = styled.p`
  // inter regular
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #c6016b;
  margin: 10px 0px;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
    opacity: 0.8;
  }

  @media (max-width: 992px) {
    font-size: 14px;
    margin: 0px 18px;
  }
`

export const NavigationModal = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
  margin-top: 10px;
`

interface IItemNavigationProps {
  flexDirection?: string
  justifyContent?: string
  alignItems?: string
  reverse?: boolean
  width?: string
}

export const ItemNavigation = styled.div<IItemNavigationProps>`
  width: ${(props: IItemNavigationProps) => props.width || '50%'};
  display: flex;
  flex-direction: ${(props: IItemNavigationProps) => props.flexDirection || 'row'};
  justify-content: ${(props: IItemNavigationProps) => props.justifyContent || 'flex-start'};
  align-items: ${(props: IItemNavigationProps) => props.alignItems || 'auto'};
  cursor: pointer;

  @media (max-width: 992px) {
    flex-direction: ${(props: IItemNavigationProps) => (props.reverse ? 'row-reverse' : 'row')};
  }
`

export const ContainerPlan = styled.div`
  display: flex;
  flex-direction: row;
  width: 65%;
  margin: 40px 0px 0px 0px;
  @media (max-width: 992px) {
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0;
    max-width: 500px;
  }
`

export const ContainerPlanText = styled.div`
  display: flex;
  flex-direction: row;
  width: 50%;
  justify-content: center;
  align-items: center;
  margin: 10px 0px;

  @media (max-width: 992px) {
    width: 100%;
    flex-direction: column;
  }
`

export const ContainerCardsPlan = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
  > div {
    margin-bottom: 20px;
  }

  @media (max-width: 992px) {
    flex-direction: column;
    justify-content: space-around;
    margin-top: 60px;

    > div {
      max-width: 100%;
      flex-basis: 100%;
    }
  }
`

export const ContainerCardsPlanText = styled.div`
  display: flex;
  margin: 30px 0px;
  width: 100%;

  @media (max-width: 992px) {
    display: none;
  }
`

export const TextMyPlan = styled.h2`
  // inter regular
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 48px;
  color: #1f2124;
  margin: 0px 0px 0px 10px;

  @media (max-width: 992px) {
    align-self: center;
    font-size: 24px;
    margin: 0px;
  }

  @media (max-width: 574px) {
    align-self: start;
  }
`

export const ContainerDownloadResults = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 60px;
  align-items: flex-end;
  padding-bottom: 30px;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }

  @media (max-width: 992px) {
    justify-content: center;
  }
`

export const SelectContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 50px 0px 0px 0px;
`
