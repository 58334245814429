import React, { ChangeEvent, FormEvent, useRef, useState } from 'react'

import {
  Container,
  ScaleContainer,
  Scale,
  ItemScale,
  ItemScaleText,
  LegendContainer,
  ItemLegend,
  TextLegend,
  Grid,
  Label,
  Button,
  GridTitles,
  GraphicContainer,
  ButtonEdit,
  PencilIcon,
  ButtonEditContainer,
  LineReferenceContainer,
  LineReferenceLeftContainer,
  CircleLeft,
  LineReferenceLeft,
  LineReferenceRightContainer,
  CircleRight,
  LineReferenceRight,
} from './styled'
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip'
import { TextField } from '@mui/material'
import { styled } from '@mui/material/styles'
import BrainMetrics from 'views/components/Graphics/BrainMetrics'
import PolarAreaIcon from 'assets/icons/PolarAreaIcon'
import RectangleSkew from 'assets/icons/RectangleSkew'
import pencil from 'assets/icons/pencilIcon.png'
import { useTranslation } from 'react-i18next'

const Legend = () => {
  const { t } = useTranslation()
  return (
    <div>
      <ItemLegend>
        <PolarAreaIcon />
        <TextLegend>:{t('global.common.userResultsLegend')}</TextLegend>
      </ItemLegend>
      <ItemLegend>
        <RectangleSkew />
        <TextLegend>:{t('global.common.idealProfileText')}</TextLegend>
      </ItemLegend>
    </div>
  )
}

interface IProps {
  FI?: any
  BI?: any
  BD?: any
  FD?: any
  hideLegend?: boolean
  idealProfile?: Ideal
}

export interface Ideal {
  FI: number
  BI: number
  BD: number
  FD: number
}

type Inputs = {
  FI: string
  BI: string
  FD: string
  BD: string
}

const AlertTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'red',
    color: 'white',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))

const BrainGraphicContainer = (props: IProps) => {
  const { hideLegend, idealProfile, FI, FD, BI, BD } = props

  const [inputs, setInputs] = useState<Inputs>({ FI: '', BI: '', FD: '', BD: '' })
  const [userData] = useState<Inputs>({ FI: FI || '', BI: BI || '', FD: FD || '', BD: BD || '' })
  const [idealData, setIdealData] = useState<Ideal>(idealProfile || { FI: 0, BI: 0, FD: 0, BD: 0 })
  const [showGraphic, setShowGraphic] = useState(false)
  const [tooltipVisible, setTooltipVisible] = useState(false)

  const refs = {
    FI: useRef<HTMLInputElement | null>(null),
    BI: useRef<HTMLInputElement | null>(null),
    FD: useRef<HTMLInputElement | null>(null),
    BD: useRef<HTMLInputElement | null>(null),
  }

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target
    setInputs((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault()
    if (Object.values(inputs).some((input) => input === '')) {
      setTooltipVisible(true)
      setTimeout(() => {
        setTooltipVisible(false)
      }, 4000)
    } else if (Object.values(inputs).some((input) => Number(input) < 10 || Number(input) > 120)) {
      return
    } else {
      setIdealData({
        FI: Number(inputs.FI),
        BI: Number(inputs.BI),
        FD: Number(inputs.FD),
        BD: Number(inputs.BD),
      })

      setShowGraphic(true)
      setTooltipVisible(false)
    }
  }

  const handleEditButton = () => {
    setShowGraphic(false)
    setInputs({ FI: '', BI: '', FD: '', BD: '' })
    setIdealData({ FI: 0, BI: 0, FD: 0, BD: 0 })
    setTimeout(() => {
      if (refs.FI.current) refs.FI.current?.focus()
    }, 100)
  }
  
  const { t } = useTranslation()

  return (
    <Container>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <form onSubmit={handleSubmit}>
          <Grid>
            <GridTitles border>{t('global.common.pluralQuadrantText')}</GridTitles>
            <GridTitles>Perfil ideal del equipo</GridTitles>
            {['FI', 'BI', 'BD', 'FD'].map((quad, index) => (
              <>
                <Label key={`grid-key-${index}`} borderTop={index === 0 ? 'none' : undefined}>
                  {quad.toUpperCase()}
                </Label>
                <div style={{ padding: '6px' }}>
                  <TextField
                    inputRef={refs[quad as keyof Inputs]}
                    disabled={showGraphic}
                    type="number"
                    size="small"
                    inputProps={{ min: '10', max: '120' }}
                    name={quad}
                    value={inputs[quad as keyof Inputs]}
                    onChange={handleInputChange}
                    sx={{ borderRadius: '8px', boxShadow: '0px 3px 6px #00000029' }}
                  />
                </div>
              </>
            ))}
          </Grid>
          <AlertTooltip open={tooltipVisible} title="Debes completar los 4 campos" placement="top">
            <Button type="submit">Generar gráfica</Button>
          </AlertTooltip>
          <ButtonEditContainer>
            <PencilIcon src={pencil} />
            <ButtonEdit onClick={handleEditButton}>Editar perfil del equipo</ButtonEdit>
          </ButtonEditContainer>
        </form>
        <GraphicContainer>
          <ScaleContainer>
            <Scale>
              <p>0</p>
              <p>40</p>
              <p style={{ marginLeft: '6px' }}>60</p>
              <p style={{ marginRight: '-6px' }}>80</p>
              <p>100</p>
              <p>+100</p>
            </Scale>
            <Scale>
              <ItemScale color="#F02401" style={{ borderTopLeftRadius: '16px', borderBottomLeftRadius: '16px' }}>
                <ItemScaleText color="#F02401">{t('global.common.colorGraphText.lowLevel')}</ItemScaleText>
              </ItemScale>
              <ItemScale color="#FF8402">
                <ItemScaleText color="#FF8402">{t('global.common.colorGraphText.lowAvgLevel')}</ItemScaleText>
              </ItemScale>
              <ItemScale color="#674531">
                <ItemScaleText color="#674531">{t('global.common.colorGraphText.highAvgLevel')}</ItemScaleText>
              </ItemScale>
              <ItemScale color="#2C9430">
                <ItemScaleText color="#2C9430">{t('global.common.colorGraphText.highLevel')}</ItemScaleText>
              </ItemScale>
              <ItemScale color="#9E9E9E" style={{ borderTopRightRadius: '16px', borderBottomRightRadius: '16px' }}>
                <ItemScaleText color="#9E9E9E">{t('global.common.colorGraphText.veryHighLevel')}</ItemScaleText>
              </ItemScale>
            </Scale>
            <LineReferenceContainer>
              <LineReferenceLeftContainer>
                <CircleLeft />
                <LineReferenceLeft>Perfil ideal de Equipo</LineReferenceLeft>
              </LineReferenceLeftContainer>
              <LineReferenceRightContainer>
                <CircleRight />
                <LineReferenceRight>Promedio de Equipo</LineReferenceRight>
              </LineReferenceRightContainer>
            </LineReferenceContainer>
          </ScaleContainer>
          <BrainMetrics
            FI={Number(userData.FI)}
            BI={Number(userData.BI)}
            BD={Number(userData.BD)}
            FD={Number(userData.FD)}
            idealProfile={idealData}
            showPolarArea={false}
          />
        </GraphicContainer>
        <LegendContainer>{!hideLegend && <Legend />}</LegendContainer>
      </div>
    </Container>
  )
}

export default BrainGraphicContainer
