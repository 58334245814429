import { FC, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { MainLayout } from 'views/layouts'
import TitleDiv from 'views/components/UI/TitleDiv'
import Input from 'views/components/UI/TextInput'
import Select from 'views/components/UI/Select'
import { H2 } from 'views/components/UI/Text'
import { Button } from 'views/components/UI/Buttons'
import {
  InputGridDiv,
  ContainerRow,
  BtnGoBack,
  TextArea,
  Label,
  OptionContainerItem,
  OptionContainerLabel,
  TypeQuestionContainer,
  TextError,
  Row,
  TextQuadrantSelect,
} from './styled'
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'
import { dataNewChapter } from 'ducks/modules/selectors'
import { cleanDataNewChapter, cleanDataNewQuestionnaire } from 'ducks/modules'
// routes
import { superAdminRoutes } from 'router/routes'
// services
import createNewQuestion from 'services/common/questions/new'
import createNewChapter from 'services/common/chapters/new'
import { QUADRANT_OPTIONS, TYPE_QUESTION } from 'constants/global.constants'

/*
 * @description Screen to create a new question
 */
const CreateBetesaQuestion: FC = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const location = useLocation()

  // redux state
  const { token } = useSelector((state: any) => state.user)
  const dataChapter = useSelector(dataNewChapter)

  // router state
  const { questionnaireId, chapterId } = location.state

  // local state
  const [loading, setLoading] = useState(false)

  // Select options
  const typeOptions = TYPE_QUESTION
  const quadrantOptions = QUADRANT_OPTIONS

  // Formik initial values
  const initialValues = {
    type: '',
    questionText: '',
    quadrantOption1: '',
    quadrantOption2: '',
    quadrantOption3: '',
    quadrantOption4: '',
    quadrantOption5: '',
    option1: '',
    option2: '',
    option3: '',
    option4: '',
    option5: '',
    option6: '',
    option7: '',
    option8: '',
    option9: '',
    option10: '',
    option11: '',
  }

  const validationSchema = Yup.object({
    type: Yup.string().trim().required('El tipo de pregunta es requerido'),
    questionText: Yup.string().trim().required('El texto de la pregunta es requerido'),
    option1: Yup.string().trim().required('La opción 1 es requerida'),
    option2: Yup.string().trim().required('La opción 2 es requerida'),
    option3: Yup.string().trim().required('La opción 3 es requerida'),
    option4: Yup.string().trim().required('La opción 4 es requerida'),
    option5: Yup.string()
      .trim()
      .when('type', {
        is: (type: string) => type === TYPE_QUESTION[2].value,
        then: Yup.string().trim().required('La opción 5 es requerida'),
      }),
    option6: Yup.string().when('type', {
      is: (type: string) => type === TYPE_QUESTION[0].value,
      then: Yup.string().trim().required('La opción 6 es requerida'),
    }),
    option7: Yup.string().when('type', {
      is: (type: string) => type === TYPE_QUESTION[0].value,
      then: Yup.string().trim().required('La opción 7 es requerida'),
    }),
    option8: Yup.string()
      .trim()
      .when('type', {
        is: (type: string) => type === TYPE_QUESTION[0].value,
        then: Yup.string().required('La opción 8 es requerida'),
      }),
    option9: Yup.string()
      .trim()
      .when('type', {
        is: (type: string) => type === TYPE_QUESTION[0].value,
        then: Yup.string().required('La opción 9 es requerida'),
      }),
    option10: Yup.string()
      .trim()
      .when('type', {
        is: (type: string) => type === TYPE_QUESTION[0].value,
        then: Yup.string().required('La opción 10 es requerida'),
      }),
    option11: Yup.string().when('type', {
      is: (type: string) => type === TYPE_QUESTION[0].value,
      then: Yup.string().trim().required('La opción 11 es requerida'),
    }),
  })

  const onSubmit = (values: any) => {
    // format options if the question is drag and drop so only view 4, if unica respuesta so only view 5, and if multiple so view 11
    const optionsData =
      values.type === TYPE_QUESTION[1].value
        ? [
            { text: values.option1, quadrant: values.quadrantOption1 !== '' ? values.quadrantOption1 : 'N/A' },
            { text: values.option2, quadrant: values.quadrantOption2 !== '' ? values.quadrantOption2 : 'N/A' },
            { text: values.option3, quadrant: values.quadrantOption3 !== '' ? values.quadrantOption3 : 'N/A' },
            { text: values.option4, quadrant: values.quadrantOption4 !== '' ? values.quadrantOption4 : 'N/A' },
          ]
        : values.type === TYPE_QUESTION[2].value
        ? [
            { text: values.option1 },
            { text: values.option2 },
            { text: values.option3 },
            { text: values.option4 },
            { text: values.option5 },
          ]
        : [
            { text: values.option1 },
            { text: values.option2 },
            { text: values.option3 },
            { text: values.option4 },
            { text: values.option5 },
            { text: values.option6 },
            { text: values.option7 },
            { text: values.option8 },
            { text: values.option9 },
            { text: values.option10 },
            { text: values.option11 },
          ]

    if (!loading) {
      setLoading(true)
      if (Object.keys(dataChapter).length > 0) {
        // when create a new chapter and his first question
        const dataCreateChapter = {
          name: dataChapter.chapterName,
          number: dataChapter.chapterNumber.toString(),
          description: dataChapter.chapterDescription,
          questionData: {
            text: values.questionText,
            type_question: values.type,
            active: true,
            type_category_test_id: dataChapter.idQuestionnaire,
            dimension_id: 1, // by default for betesa
          },
          optionsData: [...optionsData], //optionsData
        }

        createNewChapter(token, dataCreateChapter)
          .then((res) => {
            if (res.status === 'OK') {
              dispatch(cleanDataNewChapter())
              dispatch(cleanDataNewQuestionnaire())
              setLoading(false)
              navigate(
                `${superAdminRoutes.QuestionsTalentQuestionaire}?chapter=${res.chapter.id}&questionnaireId=${dataChapter.idQuestionnaire}`,
                { state: { chapterId: res.chapter.id, questionnaireId: dataChapter.idQuestionnaire } }
              )
            } else {
              alert('Error al crear el capitulo')
              setLoading(false)
            }
          })
          .catch(() => {
            setLoading(false)
          })
      } else {
        // for create a new question in a chapter

        if (questionnaireId && chapterId) {
          const dataCreateQuestion = {
            text: values.questionText,
            typeQuestion: values.type,
            active: true,
            typeCategoryTestId: parseInt(questionnaireId),
            chapterId: parseInt(chapterId),
            dimensionId: 1, // 1 by default for betesa
            optionsData: optionsData,
          }

          createNewQuestion(token, dataCreateQuestion)
            .then(() => {
              setLoading(false)
              navigate(superAdminRoutes.QuestionsTalentQuestionaire, {
                state: { chapterId: chapterId, questionnaireId: questionnaireId },
              })
            })
            .catch(() => {
              setLoading(false)
            })
        }
      }
    }
  }

  return (
    <MainLayout>
      <TitleDiv>
        <H2 marginBottom="10px">Nueva pregunta</H2>
      </TitleDiv>
      <Formik validateOnMount initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
        {({ values, isSubmitting, errors, setFieldValue, touched }) => (
          <Form>
            <InputGridDiv>
              <TypeQuestionContainer>
                <Select
                  hideNeverChoice={true}
                  label="Tipo de pregunta"
                  options={typeOptions}
                  onChange={(e) => setFieldValue('type', e)}
                  value={values.type}
                />
                {touched.type && errors.type ? <div>{errors.type}</div> : null}
              </TypeQuestionContainer>

              <div style={{ marginTop: 10 }}>
                <Label>Texto de la pregunta</Label>
                <Field as={TextArea} style={{ marginTop: 5 }} placeholder="Texto de la pregunta" name="questionText" />
                {touched.questionText && errors.questionText ? <TextError>{errors.questionText}</TextError> : null}
              </div>
              <Label>Opciones de respuesta</Label>

              <OptionContainerItem>
                <OptionContainerLabel>Opción 1</OptionContainerLabel>
                <Row>
                  <Field as={Input} type="text" name="option1" label="Opción 1" style={{ width: '50%' }} />

                  {values.type === TYPE_QUESTION[1].value ? (
                    <Row style={{ width: '50%', justifyContent: 'flex-end' }}>
                      <TextQuadrantSelect>Cuadrante asociado</TextQuadrantSelect>
                      <Select
                        hideNeverChoice
                        label="Cuadrante"
                        options={quadrantOptions}
                        onChange={(e) => setFieldValue('quadrantOption1', e)}
                        value={values.quadrantOption1}
                        width="40%"
                      />
                    </Row>
                  ) : null}
                </Row>
                {touched.option1 && errors.option1 ? <TextError>{errors.option1}</TextError> : null}
              </OptionContainerItem>

              <OptionContainerItem>
                <OptionContainerLabel>Opción 2</OptionContainerLabel>
                <Row>
                  <Field as={Input} type="text" name="option2" label="Opción 2" style={{ width: '50%' }} />
                  {values.type === TYPE_QUESTION[1].value ? (
                    <Row style={{ width: '50%', justifyContent: 'flex-end' }}>
                      <TextQuadrantSelect>Cuadrante asociado</TextQuadrantSelect>
                      <Select
                        hideNeverChoice
                        label="Cuadrante"
                        options={quadrantOptions.filter(
                          (quadrantOption) => quadrantOption.value !== values.quadrantOption1
                        )}
                        onChange={(e) => setFieldValue('quadrantOption2', e)}
                        value={values.quadrantOption2}
                        width="40%"
                      />
                    </Row>
                  ) : null}
                </Row>
                {touched.option2 && errors.option2 ? <TextError>{errors.option2}</TextError> : null}
              </OptionContainerItem>

              <OptionContainerItem>
                <OptionContainerLabel>Opción 3</OptionContainerLabel>
                <Row>
                  <Field as={Input} type="text" name="option3" label="Opción 3" style={{ width: '50%' }} />
                  {values.type === TYPE_QUESTION[1].value ? (
                    <Row style={{ width: '50%', justifyContent: 'flex-end' }}>
                      <TextQuadrantSelect>Cuadrante asociado</TextQuadrantSelect>
                      <Select
                        hideNeverChoice
                        label="Cuadrante"
                        options={quadrantOptions.filter(
                          (quadrantOption) =>
                            quadrantOption.value !== values.quadrantOption1 &&
                            quadrantOption.value !== values.quadrantOption2
                        )}
                        onChange={(e) => setFieldValue('quadrantOption3', e)}
                        value={values.quadrantOption3}
                        width="40%"
                      />
                    </Row>
                  ) : null}
                </Row>
                {touched.option3 && errors.option3 ? <TextError>{errors.option3}</TextError> : null}
              </OptionContainerItem>

              <OptionContainerItem>
                <OptionContainerLabel>Opción 4</OptionContainerLabel>
                <Row>
                  <Field as={Input} type="text" name="option4" label="Opción 4" style={{ width: '50%' }} />
                  {values.type === TYPE_QUESTION[1].value ? (
                    <Row style={{ width: '50%', justifyContent: 'flex-end' }}>
                      <TextQuadrantSelect>Cuadrante asociado</TextQuadrantSelect>
                      <Select
                        hideNeverChoice
                        label="Cuadrante"
                        options={quadrantOptions.filter(
                          (quadrantOption) =>
                            quadrantOption.value !== values.quadrantOption1 &&
                            quadrantOption.value !== values.quadrantOption2 &&
                            quadrantOption.value !== values.quadrantOption3
                        )}
                        onChange={(e) => setFieldValue('quadrantOption4', e)}
                        value={values.quadrantOption4}
                        width="40%"
                      />
                    </Row>
                  ) : null}
                </Row>
                {touched.option4 && errors.option4 ? <TextError>{errors.option4}</TextError> : null}
              </OptionContainerItem>
              {values.type !== TYPE_QUESTION[1].value ? (
                <OptionContainerItem>
                  <OptionContainerLabel>Opción 5</OptionContainerLabel>
                  <Row>
                    <Field as={Input} type="text" name="option5" label="Opción 5" style={{ width: '50%' }} />
                  </Row>
                  {touched.option5 && errors.option5 ? <TextError>{errors.option5}</TextError> : null}
                </OptionContainerItem>
              ) : null}

              {values.type === TYPE_QUESTION[0].value ? (
                <>
                  <OptionContainerItem>
                    <OptionContainerLabel>Opción 6</OptionContainerLabel>
                    <Row>
                      <Field as={Input} type="text" name="option6" label="Opción 6" style={{ width: '50%' }} />
                    </Row>
                    {touched.option6 && errors.option6 ? <TextError>{errors.option6}</TextError> : null}
                  </OptionContainerItem>
                  <OptionContainerItem>
                    <OptionContainerLabel>Opción 7</OptionContainerLabel>
                    <Row>
                      <Field as={Input} type="text" name="option7" label="Opción 7" style={{ width: '50%' }} />
                    </Row>
                    {touched.option7 && errors.option7 ? <TextError>{errors.option7}</TextError> : null}
                  </OptionContainerItem>
                  <OptionContainerItem>
                    <OptionContainerLabel>Opción 8</OptionContainerLabel>
                    <Row>
                      <Field as={Input} type="text" name="option8" label="Opción 8" style={{ width: '50%' }} />
                    </Row>
                    {touched.option8 && errors.option8 ? <TextError>{errors.option8}</TextError> : null}
                  </OptionContainerItem>
                  <OptionContainerItem>
                    <OptionContainerLabel>Opción 9</OptionContainerLabel>
                    <Row>
                      <Field as={Input} type="text" name="option9" label="Opción 9" style={{ width: '50%' }} />
                    </Row>
                    {touched.option9 && errors.option9 ? <TextError>{errors.option9}</TextError> : null}
                  </OptionContainerItem>
                  <OptionContainerItem>
                    <OptionContainerLabel>Opción 10</OptionContainerLabel>
                    <Row>
                      <Field as={Input} type="text" name="option10" label="Opción 10" style={{ width: '50%' }} />
                    </Row>
                    {touched.option10 && errors.option10 ? <TextError>{errors.option10}</TextError> : null}
                  </OptionContainerItem>
                  <OptionContainerItem>
                    <OptionContainerLabel>Opción 11</OptionContainerLabel>
                    <Row>
                      <Field as={Input} type="text" name="option11" label="Opción 11" style={{ width: '50%' }} />
                    </Row>
                    {touched.option11 && errors.option11 ? <TextError>{errors.option11}</TextError> : null}
                  </OptionContainerItem>
                </>
              ) : null}
              <ContainerRow>
                <BtnGoBack onClick={() => navigate(-1)}>Volver</BtnGoBack>
                <Button disabled={isSubmitting || Object.keys(errors).length > 0} type="submit">
                  Guardar pregunta
                </Button>
              </ContainerRow>
            </InputGridDiv>
          </Form>
        )}
      </Formik>
    </MainLayout>
  )
}

export default CreateBetesaQuestion
