import styled from 'styled-components'

export const TableContainer = styled.div`
  align-items: center;
  padding-bottom: 40px;
`
export const PaginationDiv = styled.div`
  display: flex; 
  flex-direction: row; 
  justify-content: center;
  margin: 2rem 0;
`
export const Table = styled.table`
  width: 100%;
  text-align: left;
   border-collapse: collapse;
`
export const TableRow = styled.tr`
  margin-top:30px;
`
export const TableHeaders = styled.thead`
  margin-bottom: 30px;
  border-bottom: 1px solid #1F2124;
  padding: 0.3em;
  justify-content: space-between;
  align-items: center;
  cursor:pointer;
`
export const TableHeader = styled.th``
export const ActionsCell = styled.td`
  font-weight: bold;
  align-items: center;
  justify-content: flex-end;
`
export const UpdateLink = styled.a`
  align-items: center;
  justify-content: center;
  color:#2D749C;
  font-weight: bold;
  cursor:pointer;
`

export const TableBody = styled.tbody`
  border-top: 1px solid ;
  padding-top: 30px;
  justify-content: space-between;
  min-height: 400px;
`
export const TextView = styled.span`
    //  font family public sans serif
    font-family: 'Public Sans';
    color: #C6016B;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    margin: 0;
    cursor: pointer;
`
export const TextUpdate = styled.span`
    //  font family public sans serif
    font-family: 'Public Sans';
    justify-Content: flex-end; 
    display: flex; 
    align-items:center;font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    margin: 0;
    cursor: pointer;
    color:#2D749C;
    
`

export const LoaderContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
`
