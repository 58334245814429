import axios from 'axios'
import { DevelopmentPlanActions } from '../store'

export const fetchDevelopmentPlan = async (id: string) => {
  const url = `${process.env.REACT_APP_MAIN_API}/process/users/results/${id}`
    const response = (await axios.get(url)).data.data
    response.id = id
    return response
}

export const fetchDevelopmentPlanCompetences = async (id: number, userTestId?: number ): Promise<DevelopmentPlanActions> => {
  const url = `${process.env.REACT_APP_MAIN_API}/development_plan/${id}${userTestId ? `?userTestId=${userTestId}` : ''}`
  try {
    const response = await axios.get(url)
    return response.data.development_plan
  } catch (error) {
    throw new Error('Error fetching get assignment tests')
  }
}
