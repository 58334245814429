import { ChangeEvent, FC, useEffect, useRef, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  BtnGoBack,
  Container,
  ContainerRow,
  Question,
  SaveButton,
  Row,
  GridModal,
  ModalContainer,
  ModalHeader,
  ModalBody,
  Title,
  Paragraph,
} from './styled'
import { TestLayout } from 'views/layouts'
import { Button } from 'views/components/UI/Buttons'
import { competencesQuestionsSelector, totalQuestionsSelector } from './store'
import { FormControlLabel, Radio, RadioGroup } from '@mui/material'
import Modal from 'views/components/UI/Modal'
import FormControl from '@mui/material/FormControl'
import useQuestions from '../../hooks/useQuestions'
import ProgressBar from './components/progress-bar/progress-bar'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import ModalLoader from 'views/components/UI/ModalLoader/modal-loader'
import { useNavigate } from 'react-router-dom'
import { userTestIdSelector } from 'ducks/evaluatedUser'
import { fetchGetCompetenceResults } from '../../services/fetch-questions-betesa'
import CobrandingLogo from 'views/components/UI/Logo/logo-cobranding'
import { cobrandingLogoSelector, loadingSelector, setLoading } from 'ducks/ui'
import { useNotification } from 'lib/context/notification.context'
import { Routes } from 'constants/global.constants'
import { t } from 'i18next'

const QuestionsSkills: FC = () => {
  const dispatch = useDispatch()
  const questions = useSelector(competencesQuestionsSelector)
  const userTestId = useSelector(userTestIdSelector)
  const navigate = useNavigate()
  const [currentQuestion, setCurrentQuestion] = useState(1)
  const [optionScore, setOptionScore] = useState(0)
  const [openModal, setOpenModal] = useState(false)
  const [isFinalQuestion, setIsFinalQuestion] = useState(false)
  const [questionId, setQuestionId] = useState(0)
  const [fetchedResults, setFetchedResults] = useState<any>({})
  const [updateResults, setUpdateResults] = useState(false)
  const isLoadingGlobal = useSelector(loadingSelector)
  const [isLoading, setIsLoading] = useState(false)
  const [isConnectionError, setIsConnectionError] = useState(false)
  const { getError } = useNotification()
  const { getCompetencesQuestions, saveCompetencesQuestions, finishedCompetencesTest, getTotalQuestions } =
    useQuestions()
  const totalQuestions = useSelector(totalQuestionsSelector)

  const companyLogo = useSelector(cobrandingLogoSelector)
  const answeredQuestionCount = useRef(0)

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      try {
        const res = await fetchGetCompetenceResults(userTestId)
        setIsConnectionError(false)
        setFetchedResults(res.results)
        answeredQuestionCount.current = Object.keys(res.results).length
        const nextUnanswered = questions.findIndex((question) => !(question.id in res.results))
        setCurrentQuestion(nextUnanswered !== -1 ? nextUnanswered + 1 : 1)

        if (updateResults) {
          setUpdateResults(false)
        }
      } catch (error) {
        setIsConnectionError(true)
        getError('Ha ocurrido un error al obtener las respuestas previas.')
      } finally {
        setLoading(false)
      }
    }

    if (questions.length > 0 && currentQuestion === 1) {
      fetchData()
    }
  }, [userTestId, updateResults, questions, getError, currentQuestion])

  useEffect(() => {
    if (questions[currentQuestion - 1]?.id in fetchedResults) {
      setOptionScore(fetchedResults[questions[currentQuestion - 1].id].score)
      setQuestionId(fetchedResults[questions[currentQuestion - 1].id].question_id)
    }
  }, [currentQuestion, fetchedResults, questions])

  useEffect(() => {
    if (currentQuestion === totalQuestions) setIsFinalQuestion(true)
  }, [currentQuestion, totalQuestions])

  const progress = currentQuestion === totalQuestions ? 100 : (currentQuestion / totalQuestions) * 100

  const handleNext = async () => {
    setIsLoading(true)
    try {
      const response = await saveCompetencesQuestions(questionId, optionScore)

      setFetchedResults((prevResults: any) => ({
        ...prevResults,
        [questionId]: { score: optionScore, question_id: questionId },
      }))

      const nextQuestionIndex = currentQuestion
      const tempFetchedResults = {
        ...fetchedResults,
        [questionId]: { score: optionScore, question_id: questionId },
      }
      const isLastQuestion = nextQuestionIndex === questions.length
      const nextUnansweredQuestionIndex = questions.findIndex(
        (question, index) => index > currentQuestion - 1 && !(question.id in tempFetchedResults)
      )

      if (!isLastQuestion && response.status === 'OK') {
        setCurrentQuestion(nextQuestionIndex + 1)
      }
      if (nextUnansweredQuestionIndex === -1 && response.status === 'OK') {
        setIsFinalQuestion(true)
      }

      setUpdateResults(true)

      setOptionScore(0)
    } catch (error) {
      setIsConnectionError(true)
      getError('Tus respuestas no se están guardando, por favor contacta soporte.')
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    getTotalQuestions()
  }, [getTotalQuestions])

  const handleGoBack = () => {
    if (currentQuestion === 1) {
      navigate(-2)
    } else {
      setCurrentQuestion(currentQuestion - 1)
      setIsFinalQuestion(false)
    }
  }
  const handleChange = (event: ChangeEvent<HTMLInputElement>, inputQuestionId: number) => {
    setQuestionId(inputQuestionId)
    setOptionScore(+event.target.value)
    event.preventDefault()
  }

  const handleSendTest = async () => {
    setLoading(true)
    try {
      dispatch(setLoading(true))
      setOpenModal(false)
      await Promise.all([saveCompetencesQuestions(questionId, optionScore), finishedCompetencesTest()])
      navigate(Routes.home)
    } catch {
      setIsConnectionError(true)
    } finally {
      setLoading(false)
      dispatch(setLoading(false))
    }
  }

  const handleCloseModal = () => {
    setOpenModal(false)
  }

  useEffect(() => {
    getCompetencesQuestions()
  }, [getCompetencesQuestions])

  if (!questions.length) return <ModalLoader isLoading />

  return (
    <TestLayout cobrandingLogoUrl={companyLogo}>
      <Container>
        <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
          {companyLogo && <CobrandingLogo logoUrl={companyLogo} hideMobile />}
        </div>
        <Title>{t('evaluatedUser.questionnaire.title')}</Title>
        <p>
          {currentQuestion} de <span>{totalQuestions}</span>
        </p>
        <ProgressBar variant="determinate" value={progress} />
        <Paragraph>
          En esta sección encontrarás una afirmación. Debes elegir la respuesta que más se acerque a tu forma de pensar
        </Paragraph>
        <Question>{questions[currentQuestion - 1].text}</Question>
        <FormControl style={{ width: '100%' }}>
          {questions[currentQuestion - 1].options.map((answer) => (
            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              key={answer.text}
              value={optionScore}
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                handleChange(event, questions[currentQuestion - 1].id)
              }
            >
              <FormControlLabel
                value={questions[currentQuestion - 1].reverse_order ? answer.reverse_weight : answer.weight}
                control={<Radio />}
                label={answer.text}
              />
            </RadioGroup>
          ))}
        </FormControl>
        <ContainerRow>
          {currentQuestion !== 0 && <BtnGoBack onClick={() => handleGoBack()}>{t('global.common.previousButtonText')}</BtnGoBack>}

          {!isFinalQuestion ? (
            <Button
              onClick={() => {
                if (!isLoading) handleNext()
              }}
              disabled={optionScore === 0 || isLoading || isConnectionError}
            >
              {isLoading ? t('global.common.loadingText') : t('global.common.nextButtonText')}
            </Button>
          ) : (
            <Button
              onClick={() => {
                setOpenModal(true)
              }}
              disabled={optionScore === 0 || isLoading || isConnectionError}
            >
              Enviar
            </Button>
          )}
        </ContainerRow>
      </Container>
      <Modal
        hideCloseButton={true}
        show={openModal}
        setShow={setOpenModal}
        styleContent={{
          width: '560px',
          height: '280px',
          borderRadius: '10px',
          padding: '20px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          backgroundColor: '#FFFFFF',
        }}
      >
        <ModalContainer>
          <ModalHeader>
            <div style={{ cursor: 'pointer' }}>
              <CloseOutlinedIcon onClick={handleCloseModal} />
            </div>
          </ModalHeader>

          <ModalBody>
            <h3 style={{ textAlign: 'center' }}>Enviar prueba</h3>
            <GridModal>
              <p style={{ width: '100%', marginTop: 0, color: '#303030' }}>
                Si envías la prueba, no podrás volver a realizarla. ¿Estás seguro de que quieres enviarla?
              </p>

              <Row>
                <BtnGoBack
                  onClick={() => {
                    setOpenModal(false)
                  }}
                >
                  Cancelar
                </BtnGoBack>
                <SaveButton onClick={handleSendTest}>{t('global.common.sendButtonText')}</SaveButton>
              </Row>
            </GridModal>
          </ModalBody>
        </ModalContainer>
      </Modal>
      {isLoadingGlobal ? (
        <ModalLoader
          style={{ backgroundColor: 'white', padding: '30px', borderRadius: '30px' }}
          text="Espera unos segundos mientras procesamos tus respuestas."
          isLoading={isLoadingGlobal}
        />
      ) : null}
    </TestLayout>
  )
}

export default QuestionsSkills
