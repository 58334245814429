import brain from 'assets/images/brain.svg'

import { Container } from './styled'
import PolarAreaWithAngle from 'views/components/Graphics/PolarAreaWithAngle'
import { Ideal } from 'views/containers/TeamMap/BrainGraphicContainer'

interface ChildhoodAndYouth {
  FI: number
  FD: number
  BI: number
  BD: number
}
interface IProps {
  idealProfile?: Ideal
  showPolarArea: boolean
  dominantQuadrant?: string
  childhood?: ChildhoodAndYouth
  FI: number
  BI: number
  BD: number
  FD: number
}

function isReadyIdealProfile(idealProfile: Ideal) {
  const { FI, BI, BD, FD } = idealProfile
  return FI > 0 && BI > 0 && BD > 0 && FD > 0
}

const BrainMetrics = ({ FI, BI, BD, FD, idealProfile, showPolarArea, dominantQuadrant, childhood }: IProps) => {
  return (
    <Container
      style={{
        position: 'relative',
        backgroundImage: `url(${brain})`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        marginTop: '20px',
      }}
    >
      <PolarAreaWithAngle
        showPolarArea={showPolarArea}
        width="360px"
        profileIdeal={idealProfile && isReadyIdealProfile(idealProfile) ? idealProfile : {}}
        FI={FI}
        BI={BI}
        BD={BD}
        FD={FD}
        dominantQuadrant={dominantQuadrant}
        childhood={childhood}
      />
    </Container>
  )
}

export default BrainMetrics
