import axios from 'axios'

export const fetchDevelopmentPlanSkills = async (id: string, userTestId?: number) => {
  const url = `${process.env.REACT_APP_MAIN_API}/development_plan/actions/results/${id}${userTestId ? `?userTestId=${userTestId}` : ''}`
  try {
    const response = (await axios.get(url)).data.data
    response.id = id
    return response
  } catch (error) {
    console.error(error)
  }
}
