import { useMemo } from 'react';

const nameLevelAlert = (name:string) => {
  if (name === "E") return "betesaReport.alertLevels.E";
  if (name === "EI") return "betesaReport.alertLevels.EI";
  if (name === "EE:II") return "betesaReport.alertLevels.EEII";
  if (name === "IE") return "betesaReport.alertLevels.IE";
  if (name === "I") return "betesaReport.alertLevels.I";

  return name;
};

export const useNameLevelAlert = (name:string) => {
  const fullName = useMemo(() => nameLevelAlert(name), [name]);
  return fullName;
};
