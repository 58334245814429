import axios from 'axios'

const apiUrl = `${process.env.REACT_APP_MAIN_API}/subscription_company`

interface Iprops {
  subscriptionId: number
  companyId: number
  testUsed: number
  startDate: string
  expirationDate: string
  name: string
  credits: number
  comments: string
}

interface BodyProps {
  subscription_id: number
  company_id: number
  tests_used: number
  start_date: string
  expiration_date: string
  state: boolean
  name: string
  credits: number
  comments?: string
}

export default async function index(token: string, data: Iprops | any) {
  const { subscriptionId, companyId, testUsed, startDate, expirationDate, name, credits, comments } = data
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  }

  const body: BodyProps = {
    subscription_id: subscriptionId,
    company_id: companyId,
    tests_used: testUsed,
    start_date: startDate,
    expiration_date: expirationDate,
    state: true,
    name: name,
    credits: credits,
  }

  if (comments.trim() !== '') {
    body.comments = comments
  }

  const response = await axios
    .post(apiUrl, body, { headers })
    .then((res) => {
      return res.data
    })
    .catch((error) => {
      return error
    })
  return response
}
