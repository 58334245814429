import Modal from 'views/components/UI/Modal'
import { Button, ButtonBack, ButtonContainer, Container, DatePickerWrapper, Title } from './style'
import { FC } from 'react'
import DatePicker from 'views/components/UI/Datepicker'
import { useFormik } from 'formik'
import dayjs from 'dayjs'

interface ModalDateFilterProps {
  showConfirmModal: boolean
  setShowModal: (e: boolean) => void
  downloadFunction: (e: any) => void
}

const ModalDateFilter: FC<ModalDateFilterProps> = ({ showConfirmModal, setShowModal, downloadFunction }) => {
  const { setFieldValue, values, handleSubmit } = useFormik({
    initialValues: {
      initDate: '',
      endDate: '',
    },
    onSubmit: () => {
      downloadFunction({
        initDate: values.initDate,
        endDate: values.endDate,
      })
    },
  })

  const handleBackButtonClick = () => {
    setShowModal(false)
  }

  const handleSaveButtonClick = () => {
    setShowModal(false)
    handleSubmit()
  }

  return (
    <Modal styleContent={{ width: '350px' }} show={showConfirmModal} setShow={setShowModal}>
      <Container>
        <Title>Descargar resumen por fecha</Title>
        <DatePickerWrapper>
          <DatePicker
            defaultValue={String(new Date())}
            label="Fecha inicial"
            format='MM/DD/YYYY'
            value={values.initDate}
            onChange={(date) => setFieldValue('initDate', dayjs(date).format('MM/DD/YYYY'))}
          />
          <DatePicker
            defaultValue={String(new Date())}
            label="Fecha final"
            format='MM/DD/YYYY'
            value={values.endDate}
            onChange={(date) => setFieldValue('endDate', dayjs(date).format('MM/DD/YYYY'))}
          />
        </DatePickerWrapper>
        <ButtonContainer>
          <ButtonBack onClick={handleBackButtonClick}>Cerrar</ButtonBack>
          <Button onClick={handleSaveButtonClick} type="submit">Descargar</Button>
        </ButtonContainer>
      </Container>
    </Modal>
  )
}

export default ModalDateFilter
