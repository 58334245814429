import axios from 'axios'
import { AnswerQuestion, CompetenceQuestion } from '../pages/QuestionsSkills/store/entities'
import {
  COMPETENCE_CURRENT_RESULTS,
  COMPETENCE_FINISH_PATH,
  COMPETENCE_QUESTIONS_PATH,
  COMPETENCE_QUESTION_SAVE_PATH,
} from '../pages/QuestionsSkills/constants'

export const fetchGetBetesaQuestions = async (userTestId: number) => {
  const url = `${process.env.REACT_APP_MAIN_API}/question/betesa?user_test_id=${userTestId}`
  try {
    const response = (await axios.get(url)).data.questions
    return response
  } catch (error) {
    throw new Error('Error fetching get questions betesa')
  }
}

export const fetchGetCompetenceQuestions = async (testId: number): Promise<CompetenceQuestion[]> => {
  const response = await axios.get(`${COMPETENCE_QUESTIONS_PATH}/${testId}`)
  return response.data.questions
}

export const fetchSaveCompetenceQuestions = async (answerQuestion: AnswerQuestion) => {
  try {
    const response = await axios.post(COMPETENCE_QUESTION_SAVE_PATH, answerQuestion)
    return response.data
  } catch (error) {}
}

export const fetchGetCompetenceResults = async (userTestId: number) => {
  try {
    const response = await axios.get(`${COMPETENCE_CURRENT_RESULTS}/${userTestId}`)
    return response.data
  } catch (error) {}
}

export const fetchUpdateCompetenceFinished = async (userTestId: number) => {
  const response = await axios.put(`${COMPETENCE_FINISH_PATH}/${userTestId}`)
  return response.data
}
