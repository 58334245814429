import axios from 'lib/axios'
export const fetchHomeUserEvaluated = async () => {
  try {
    const response = (await axios.get('exam/assigned_tests')).data.data
    return response
  } catch (error) {
    return []
  }
}

export const fetchInformedConsentUser = async (id: string, acceptedConsent: boolean, answer_device: "DESKTOP" | "MOBILE") => {
  try {
    const response = await axios.put(`user_test/${id}`, { informed_consent: acceptedConsent, answer_device })
    return response.data
  } catch (error) {
    throw new Error('Error fetching get assignment tests')
  }
}

export const fetchUserConsented = async (id: string, isBetesaTest?: boolean) => {
  try {
    const response = await axios.get(`user_test/${id}${isBetesaTest ? `?is_betesa_test=${isBetesaTest}` : ''}`)
    return response.data
  } catch (error) {
    throw new Error('Error fetching get assignment tests')
  }
}
