import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import dayjs from 'dayjs'
import 'dayjs/locale/es'
import { MainLayout } from 'views/layouts'
import { useNavigate } from 'react-router-dom'
import { Container, TextAreaDiv, EditIconContainer } from './styled'
import ModalEdit from './modal-edit'

import { H2, P } from 'views/components/UI/Text'
import { Button, BackButton } from 'views/components/UI/Buttons'
import DatePicker from 'views/components/UI/Datepicker'
import { useNotification } from 'lib/context/notification.context'
import { stepOneSelector, setStepFiveEmailTemplate, getEmailTemplateStepFive } from 'ducks/createProcess'
import useGetCompanyDetail from 'hooks/company/useGetCompanyDetail/useGetCompanyDetail'
import { platformAdminRoutes } from 'router/routes'
import { Row } from 'views/components/UI/Row'
import EditIcon from 'assets/icons/EditCoin'

type TextByStep = {
  [key: string]: string
}

function EditTemplateSended() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { companyId } = useSelector(stepOneSelector)
  const emailTemplate = useSelector(getEmailTemplateStepFive)
  const { getOneCompany, companyInfo } = useGetCompanyDetail()
  const { getSuccess, getWarning } = useNotification()

  const [companyName, setCompanyName] = useState<string>('')
  const [editingStepNumber, setEditingStepNumber] = useState(0)
  const [date, setDate] = useState<string>(emailTemplate?.date ? emailTemplate : '')
  const [textByStep, setTextByStep] = useState<TextByStep>({
    text1: emailTemplate?.textOne,
    text2: emailTemplate?.textTwo,
    text3: emailTemplate?.textThree,
    text4: emailTemplate?.textFour,
    active: emailTemplate?.active,
  })

  const [textThreeOptions, setTextThreeOptions] = useState<string[]>(emailTemplate?.textThreeOptions || [])

  const handleEditStep = (step: number, text: string) => {
    setTextByStep({ ...textByStep, [`text${step}`]: text })
  }

  const handleGetTextByStep = (step: number) => {
    switch (step) {
      case 1:
        return textByStep.text1
      case 2:
        return textByStep.text2
      case 3:
        return textByStep.text3
      case 4:
        return textByStep.text4
      default:
        return ``
    }
  }

  const handleSave = () => {
    const templateEdited = {
      textOne: textByStep.text1,
      textTwo: textByStep.text2,
      textThree: textByStep.text3,
      textFour: textByStep.text4,
      textThreeOptions,
      date: dayjs(date).format('MM/DD/YYYY'),
      active: Boolean(textByStep?.active),
    }
    dispatch(setStepFiveEmailTemplate(templateEdited))
    getSuccess('Se ha editado el texto correctamente.')
    navigate(platformAdminRoutes.Resume)
  }

  useEffect(() => {
    if (companyId) {
      getOneCompany(companyId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (companyInfo) {
      setCompanyName(companyInfo.data.name)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyInfo])

  return (
    <MainLayout>
      <Container>
        <H2 margin="0px 0px 20px 0px">Correo electrónico enviado a participantes</H2>
        {companyName && (
          <>
            <P>
              Configura aquí el correo electrónico que recibirán los evaluados para ingresar a contestar sus pruebas.
              Puedes editar únicamente los campos que tienen el icono del lápiz.
              <EditIcon />
            </P>
            <TextAreaDiv>
              <p>Hola, Nombre de Usuario. Nos alegra saludarte.</p>
              {textByStep.text1 && (
                <p style={{ fontStyle: 'italic', fontWeight: '500', color: 'gray' }}>
                  {textByStep.text1}
                  <EditIconContainer onClick={() => setEditingStepNumber(1)}>
                    <EditIcon />
                  </EditIconContainer>
                </p>
              )}
              {textByStep.text2 && (
                <p style={{ fontStyle: 'italic', fontWeight: '500' }}>
                  {textByStep.text2}
                  <EditIconContainer onClick={() => setEditingStepNumber(2)}>
                    <EditIcon />
                  </EditIconContainer>
                </p>
              )}
              {!textByStep.active && (
                <>
                  <ul>
                    <li>
                      Ingresa a este <u>enlace</u>
                    </li>
                    <li>Sigue las instrucciones para crear una contraseña y activar tu cuenta.</li>
                    <li>Encontrarás los cuestionarios por diligenciar. Haz clic para iniciar</li>
                  </ul>
                </>
              )}

              {!textByStep.active && (
                <>
                  <p>
                    La fecha límite para que actives tu cuenta y llenes los cuestionarios es
                    <view style={{ margin: '0px 12px' }}>
                      <DatePicker
                        clearDate={new Date(date) < new Date()}
                        value={date}
                        useISO8601
                        onChange={(e: any) => {
                          if (new Date(e) < new Date()) {
                            getWarning('La fecha no puede ser menor a la actual')
                          }
                          setDate(e)
                        }}
                        label="Fecha"
                        disableFullWidth={true}
                      />
                    </view>
                  </p>
                </>
              )}

              {textByStep.text3 && (
                <p style={{ fontStyle: 'italic', fontWeight: '500' }}>
                  {textByStep.text3}
                  <EditIconContainer onClick={() => setEditingStepNumber(3)}>
                    <EditIcon />
                  </EditIconContainer>
                </p>
              )}

              {textByStep.active && (
                <>
                  {textThreeOptions.length > 0 && (
                    <ul>
                      <li>
                        La fecha límite para que llenes los cuestionarios asignados es el{' '}
                        <view style={{ margin: '0px 12px' }}>
                          <DatePicker
                            clearDate={new Date(date) < new Date()}
                            value={date}
                            useISO8601
                            onChange={(e: any) => {
                              if (new Date(e) < new Date()) {
                                getWarning('La fecha no puede ser menor a la actual')
                              }
                              setDate(e)
                            }}
                            label="Fecha"
                            disableFullWidth={true}
                          />
                        </view>
                        .
                      </li>
                      {textThreeOptions.slice(1).map((option, index) => (
                        <li key={index}>{option}</li>
                      ))}
                    </ul>
                  )}
                </>
              )}

              {!textByStep.active && (
                <ul>
                  {textThreeOptions.map((option, index) => (
                    <li key={index}>{option}</li>
                  ))}
                </ul>
              )}

              <p>
                En caso de que llenes parcialmente el cuestionario, o tengas problemas de conectividad, lo puedes
                retomar más adelante. Para hacerlo, completa los siguientes pasos:
              </p>
              <ul>
                <li>Ingresa a este enlace.</li>
                <li>En el campo de usuario escribe el correo electrónico al que te llegó este mensaje.</li>
                <li>Digita la contraseña que creaste al momento de crear tu prueba.</li>
                <li>Reinicia el cuestionario pendiente.</li>
              </ul>

              {textByStep.text4 && (
                <p style={{ fontStyle: 'italic', fontWeight: '500' }}>
                  {textByStep.text4}
                  <EditIconContainer onClick={() => setEditingStepNumber(4)}>
                    <EditIcon />
                  </EditIconContainer>
                </p>
              )}

              <p>
                Puedes ingresar a Bluesite desde cualquier dispositivo. Para una mejor experiencia, por favor utiliza
                las versiones más recientes de los siguientes navegadores: Chrome, Firefox, Safari.
              </p>
            </TextAreaDiv>
          </>
        )}
        <Row alignItems="center" justifyContent="space-between">
          <BackButton onClick={() => navigate(platformAdminRoutes.Resume)} />

          <Button onClick={handleSave}>Guardar Cambios</Button>
        </Row>
      </Container>
      {editingStepNumber !== 0 && (
        <ModalEdit
          currentText={editingStepNumber}
          text={handleGetTextByStep(editingStepNumber)}
          textThreeOptions={textThreeOptions}
          setThreeOptions={(options) => setTextThreeOptions(options)}
          isOpen={editingStepNumber !== 0}
          onClose={() => setEditingStepNumber(0)}
          onEdit={(text) => {
            handleEditStep(editingStepNumber, text)
            setEditingStepNumber(0)
          }}
        />
      )}
    </MainLayout>
  )
}

export default EditTemplateSended
