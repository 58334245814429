import { useMemo, useState, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { ContainerRow, BtnGoBack } from '../styled'
import { Button } from 'views/components/UI/Buttons'
import { SendModal } from 'views/screens/Evaluated-User/pages/send-modal/send-modal'
import {
  AnswerData,
  DroppedItemData,
  QuestionsResultsDetails,
  betesaResultIdSelector,
  cleanColorOptions,
  cleanPreviousAnswers,
  Data,
} from 'views/screens/Evaluated-User/store'
import { calculateQuadrantWeight } from '../../handlers'
import { fetchSendAnswersBetesa, postBetesaAnswers } from 'views/screens/Evaluated-User/services/post-betesa-answers'
import { finishBetesaTest } from 'views/screens/Evaluated-User/services/put-betesa-finish'
import { useNotification } from 'lib/context/notification.context'
import {
  setPreviousAnswers,
  setCurrentQuestion,
  setCurrentChapter,
  setTestStarted,
  setShowChapterInfo,
  cleanDataToSend,
  cleanAllSelectedOptions,
  setBetesaResultId,
  setAllSelectedOptions,
} from '../../../../store'
import { BetesaAnswers } from 'views/screens/Evaluated-User/entities'
import useQuestions from 'views/screens/Evaluated-User/hooks/useQuestions'
import { useTranslation } from 'react-i18next'

const QuestionNavigation = ({ onContinue = () => {} }: any) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { getError, getSuccess } = useNotification()
  const {
    currentQuestion,
    totalQuestionsPerChapter,
    totalChapters,
    currentChapter,
    previousAnswers,
    dropped,
    question,
    currentSelectedOption,
    testStarted,
    multipleOptionsQuestion,
    allSelectedOptions,
  } = useSelector((state: any) => state.questions)
  const { handleStartTest } = useQuestions()

  const betesaResultId = useSelector(betesaResultIdSelector)

  const { processSelected } = useSelector((state: any) => state.evaluatedUser)
  const scoreValueRef = useRef({})
  const [openModal, setOpenModal] = useState<boolean>(false)
  const [scoreValue, setScoreValue] = useState<{ [key: number]: any }>({})
  const [updatedMultipleOptions, setUpdatedMultipleOptions] = useState<any[]>([])
  const [isLoading, setIsLoading] = useState(false)

  const droppedItems = dropped
  const currentOptions = question.options
  const chapterId = question.chapter_id
  const chapter = chapterId - 1
  const userTestId = processSelected.userTestId
  const isDisable = !onContinue() || isLoading

  const createAnswerData = () => {
    if (!question) {
      return
    }

    if (chapter <= 4) {
      const answerDataList = Object.entries(droppedItems).map(([, item]: [string, unknown], index) => {
        const itemData = item as DroppedItemData
        const optionId = itemData.id
        const currentOption = currentOptions.find((option: { id: number }) => option.id === optionId)

        const updatedAnswer = {
          ...itemData.item,
          answerId: itemData.id,
          quadrantWeight: calculateQuadrantWeight(index + 1),
          quadrant: itemData.quadrant,
        }

        return {
          questionId: question.id,
          chapterId: chapter,
          answer: updatedAnswer,
          weight: currentOption?.weight ?? 0,
          optionId: currentOption?.id ?? 0,
        }
      })

      return answerDataList
    } else if (chapter === 5 || chapter === 6) {
      const updatedAnswer = {
        ...currentSelectedOption,
      }
      return updatedAnswer
    } else if (chapter === 7) {
      const updatedAnswer = {
        ...multipleOptionsQuestion,
      }
      return updatedAnswer
    }
  }

  const setDataForEachChapter = (isBeforeFourChapter: boolean, betesaResultsId: number) => {
    dispatch(setBetesaResultId(betesaResultsId))
    dispatch(cleanPreviousAnswers())
    dispatch(cleanDataToSend())

    if (isBeforeFourChapter) {
      dispatch(cleanAllSelectedOptions())
    }

    setIsLoading(false)
    getSuccess(t('evaluatedUser.betesaQuestionnaire.betesaNotifications.successChapter'))
  }

  const sendAnswersToBackend = async (updatedPreviousAnswers: { [key: number]: AnswerData[] }) => {
    setIsLoading(true)
    if (chapter <= 3) {
      const backendData = {
        user_test_id: userTestId,
        chapter_id: chapter,
        betesa_result_id: betesaResultId,
      }

      try {
        const res: any = await postBetesaAnswers(backendData)
        if (res.status === 'OK' || res.status === 'success') {
          setDataForEachChapter(false, res.data[0].betesa_results_id)
          dispatch(setCurrentChapter(currentChapter + 1))
          dispatch(setCurrentQuestion(0))
          dispatch(setTestStarted(false))
          dispatch(setShowChapterInfo(true))
          getSuccess(t('evaluatedUser.betesaQuestionnaire.betesaNotifications.successChapter'))
        }
      } catch {
        getError('Tus respuestas no se están guardando, por favor contacta soporte.')
        setIsLoading(false)
      }
    }

    if (chapter === 4) {
      const answerData = Object.values(updatedPreviousAnswers).flat() as AnswerData[]
      const filteredAnswerData = answerData.filter((answer: AnswerData) => {
        return answer.chapterId === 5
      })

      const groupedAnswers: { [questionId: number]: AnswerData[] } = {}
      filteredAnswerData.forEach((answer: AnswerData) => {
        if (groupedAnswers.hasOwnProperty(answer.questionId)) {
          groupedAnswers[answer.questionId].push(answer)
        } else {
          groupedAnswers[answer.questionId] = [answer]
        }
      })

      const questionsResultsDetails: QuestionsResultsDetails[] = Object.entries(groupedAnswers).map(
        ([questionId, answers]) => {
          return {
            question_id: parseInt(questionId),
            answers: answers.map((answer: AnswerData) => {
              return {
                answer_id: answer.answer.answerId,
                score: answer.answer.quadrantWeight,
              }
            }),
          }
        }
      )

      const backendData: Data = {
        user_test_id: userTestId,
        chapter_id: chapter,
        betesa_result_id: betesaResultId,
        questions_results_details: questionsResultsDetails,
      }

      try {
        const res: any = await postBetesaAnswers(backendData)
        if (res.status === 'OK' || res.status === 'success') {
          setDataForEachChapter(false, res.data[0].betesa_results_id)
          dispatch(setCurrentChapter(currentChapter + 1))
          dispatch(setCurrentQuestion(0))
          dispatch(setTestStarted(false))
          dispatch(setShowChapterInfo(true))
          getSuccess(t('evaluatedUser.betesaQuestionnaire.betesaNotifications.successChapter'))
        }
      } catch {
        getError('Tus respuestas no se están guardando, por favor contacta soporte.')
      } finally {
        setIsLoading(false)
      }
    }

    if (chapter === 5) {
      const backendData = {
        user_test_id: userTestId,
        chapter_id: chapter,
        betesa_result_id: betesaResultId,
      }

      try {
        const res: any = await postBetesaAnswers(backendData)
        if (res.status === 'OK' || res.status === 'success') {
          setDataForEachChapter(false, res.data[0].betesa_results_id)
          dispatch(setCurrentChapter(currentChapter + 1))
          dispatch(setCurrentQuestion(0))
          dispatch(setTestStarted(false))
          dispatch(setShowChapterInfo(true))
          getSuccess(t('evaluatedUser.betesaQuestionnaire.betesaNotifications.successChapter'))
        }
      } catch {
        getError('Tus respuestas no se están guardando, por favor contacta soporte.')
        setIsLoading(false)
      } finally {
        setIsLoading(false)
      }
    }

    if (chapter === 6) {
      const backendData = {
        user_test_id: userTestId,
        chapter_id: chapter,
        betesa_result_id: betesaResultId,
      }

      try {
        const res: any = await postBetesaAnswers(backendData)
        if (res.status === 'OK' || res.status === 'success') {
          setDataForEachChapter(false, res.data[0].betesa_results_id)
          dispatch(setCurrentChapter(currentChapter + 1))
          dispatch(setCurrentQuestion(0))
          dispatch(setTestStarted(false))
          dispatch(setShowChapterInfo(true))
          getSuccess(t('evaluatedUser.betesaQuestionnaire.betesaNotifications.successChapter'))
        }
      } catch {
        getError('Tus respuestas no se están guardando, por favor contacta soporte.')
        setIsLoading(false)
      } finally {
        setIsLoading(false)
      }
    }

    if (chapter === 7) {
      const backendData = {
        user_test_id: userTestId,
        chapter_id: chapter,
        betesa_result_id: betesaResultId,
      }

      try {
        const res: any = await postBetesaAnswers(backendData)
        if (res.status === 'OK' || res.status === 'success') {
          setDataForEachChapter(false, res.data[0].betesa_results_id)
          dispatch(setCurrentChapter(currentChapter + 1))
          dispatch(setCurrentQuestion(0))
          dispatch(setTestStarted(false))
          dispatch(setShowChapterInfo(true))
          getSuccess(t('evaluatedUser.betesaQuestionnaire.betesaNotifications.successChapter'))
        }
      } catch {
        getError('Tus respuestas no se están guardando, por favor contacta soporte.')
        setIsLoading(false)
      } finally {
        setIsLoading(false)
      }
    }
  }

  const handleGoBack = () => {
    dispatch(setCurrentQuestion(currentQuestion - 1))
  }

  const handleBackToStart = () => {
    navigate('/')
  }

  const handleNext = async () => {
    setIsLoading(true)
    try {
      if (currentSelectedOption && Object.keys(currentSelectedOption).length > 0) {
        const newSelectedOption = { ...currentSelectedOption }
        const newAllSelectedOptions = {
          ...allSelectedOptions,
          [currentQuestion]: newSelectedOption,
        }

        const updatedOptions = newAllSelectedOptions
        dispatch(setAllSelectedOptions(updatedOptions))
        setScoreValue(updatedOptions)
        scoreValueRef.current = updatedOptions
      }

      const answerDataList = createAnswerData()
      if (answerDataList && question) {
        if (currentChapter <= 4) {
          const answerBody: BetesaAnswers = {
            betesa_result_id: betesaResultId,
            user_test_id: userTestId,
            first_questions_results: answerDataList.map((answer: any) => {
              return {
                question_id: answer.questionId,
                answer_id: answer.optionId,
                weight: answer.answer.quadrantWeight,
              }
            }),
          }

          const res: any = await fetchSendAnswersBetesa(answerBody)
          dispatch(setBetesaResultId(res.betesaResultsId))
          dispatch(setCurrentQuestion(currentQuestion + 1))
        } else if (currentChapter >= 5 && currentChapter <= 6) {
          const answerBody: BetesaAnswers = {
            betesa_result_id: betesaResultId,
            user_test_id: userTestId,
            second_questions_results: {
              question_id: answerDataList.question_id,
              answer_id: answerDataList.id,
              weight: answerDataList.weight,
            },
          }

          const res: any = await fetchSendAnswersBetesa(answerBody)
          dispatch(setBetesaResultId(res.betesaResultsId))
          dispatch(setCurrentQuestion(currentQuestion + 1))
        } else if (currentChapter === 7) {
          dispatch(setCurrentQuestion(currentQuestion + 1))
        }

        const updatedPreviousAnswers = JSON.parse(JSON.stringify(previousAnswers))
        updatedPreviousAnswers[question.id] = answerDataList
        dispatch(setPreviousAnswers(updatedPreviousAnswers))
        if (currentChapter > 7)
          setTimeout(() => {
            sendAnswersToBackend(updatedPreviousAnswers)
          }, 500)
      }
    } catch {
      getError('Tus respuestas no se están guardando, por favor contacta soporte.')
    } finally {
      setIsLoading(false)
    }
  }

  const handleFinishChapter = async () => {
    setIsLoading(true)
    try {
      if (currentSelectedOption && Object.keys(currentSelectedOption).length > 0) {
        const newSelectedOption = { ...currentSelectedOption }
        const newAllSelectedOptions = {
          ...allSelectedOptions,
          [currentQuestion]: newSelectedOption,
        }
        const updatedOptions = newAllSelectedOptions
        dispatch(setAllSelectedOptions(updatedOptions))
        setScoreValue(updatedOptions)
        scoreValueRef.current = updatedOptions
      }

      const answerDataList = createAnswerData()
      if (answerDataList && question) {
        if (currentChapter <= 4) {
          const answerBody: BetesaAnswers = {
            betesa_result_id: betesaResultId,
            user_test_id: userTestId,
            first_questions_results: answerDataList.map((answer: any) => {
              return {
                question_id: answer.questionId,
                answer_id: answer.optionId,
                weight: answer.answer.quadrantWeight,
              }
            }),
          }

          const res: any = await fetchSendAnswersBetesa(answerBody)
          dispatch(setBetesaResultId(res.betesaResultsId))
          if (answerDataList && question) {
            const updatedPreviousAnswers = JSON.parse(JSON.stringify(previousAnswers))
            updatedPreviousAnswers[question.id] = answerDataList
            dispatch(setPreviousAnswers(updatedPreviousAnswers))
            await sendAnswersToBackend(updatedPreviousAnswers)
          }
        } else if (currentChapter >= 5 && currentChapter <= 6) {
          const answerBody: BetesaAnswers = {
            betesa_result_id: betesaResultId,
            user_test_id: userTestId,
            second_questions_results: {
              question_id: answerDataList.question_id,
              answer_id: answerDataList.id,
              weight: answerDataList.weight,
            },
          }

          const res: any = await fetchSendAnswersBetesa(answerBody)
          dispatch(setBetesaResultId(res.betesaResultsId))
          if (currentQuestion >= totalQuestionsPerChapter - 1) {
            if (answerDataList && question) {
              const updatedPreviousAnswers = JSON.parse(JSON.stringify(previousAnswers))
              updatedPreviousAnswers[question.id] = answerDataList
              dispatch(setPreviousAnswers(updatedPreviousAnswers))
              await sendAnswersToBackend(updatedPreviousAnswers)
            }
          } else {
            dispatch(setCurrentQuestion(currentQuestion + 1))
          }
        }
      }
    } catch {
      getError('Tus respuestas no se están guardando, por favor contacta soporte.')
      setIsLoading(true)
    } finally {
      setIsLoading(false)
    }
  }

  const handleFinish = async () => {
    setIsLoading(true)
    const answerDataList = createAnswerData()
    if (answerDataList && question) {
      const updatedPreviousAnswers = JSON.parse(JSON.stringify(previousAnswers))
      updatedPreviousAnswers[question.id] = answerDataList
      dispatch(setPreviousAnswers(updatedPreviousAnswers))

      if (currentQuestion === totalQuestionsPerChapter - 1) {
        setTimeout(async () => {
          const questionsResults: any[] = []
          for (const answer of Object.values(updatedMultipleOptions)) {
            if (answer && Array.isArray(answer.selectedOptions)) {
              for (const selectedOption of answer.selectedOptions) {
                questionsResults.push({
                  question_id: answer.question_id,
                  answer_id: selectedOption,
                })
              }
            }
          }

          const backendData = {
            user_test_id: userTestId,
            chapter_id: chapter,
            betesa_result_id: betesaResultId,
            questions_results: questionsResults,
          }

          try {
            const res: any = await postBetesaAnswers(backendData)
            dispatch(setBetesaResultId(res.data[0].betesa_results_id))
            await finishBetesaTest(userTestId)
            dispatch(cleanPreviousAnswers())
            dispatch(setCurrentQuestion(0))
            dispatch(cleanDataToSend())
            dispatch(cleanAllSelectedOptions())
            dispatch(cleanColorOptions())
            setIsLoading(false)
            getSuccess(t('evaluatedUser.betesaQuestionnaire.betesaNotifications.endTest'))
            navigate('/')
          } catch {
            getError('Tus respuestas no se están guardando, por favor contacta soporte.')
            setIsLoading(false)
          } finally {
            setIsLoading(false)
          }
        }, 500)
      }
    }
  }

  const buttonText = useMemo(() => {
    if (!testStarted && currentQuestion === 0 && currentChapter > 0) {
      setTestStarted(true)
      return t('global.common.startTestButtonText')
    }

    if (currentQuestion === totalQuestionsPerChapter - 1 && currentChapter === totalChapters) {
      return t('global.common.endButtonText')
    }

    if (currentQuestion === totalQuestionsPerChapter - 1) {
      return t('global.common.nextChapterText')
    }

    return t('global.common.nextButtonText')
  }, [testStarted, currentQuestion, currentChapter, totalQuestionsPerChapter, totalChapters, t])

  const handleButtonOnClick = () => {
    if (buttonText === t('global.common.startTestButtonText')) {
      handleStartTest()
    }
    if (buttonText === t('global.common.nextButtonText')) {
      handleNext()
    } else if (buttonText === t('global.common.nextChapterText')) {
      handleFinishChapter()
    } else if (buttonText === t('global.common.endButtonText')) {
      setOpenModal(true)
    }
  }

  useEffect(() => {
    if (
      chapter === 7 &&
      multipleOptionsQuestion &&
      typeof multipleOptionsQuestion === 'object' &&
      multipleOptionsQuestion?.selectedOptions?.length > 0
    ) {
      setUpdatedMultipleOptions((prevState) => {
        const questionIndex = prevState.findIndex((el) => el.question_id === multipleOptionsQuestion.question_id)

        if (questionIndex !== -1) {
          const newState = [...prevState]
          newState[questionIndex] = {
            question_id: multipleOptionsQuestion.question_id,
            selectedOptions: multipleOptionsQuestion?.selectedOptions,
          }
          return newState
        } else {
          return [
            ...prevState,
            {
              question_id: multipleOptionsQuestion.question_id,
              selectedOptions: multipleOptionsQuestion?.selectedOptions,
            },
          ]
        }
      })
    }
  }, [multipleOptionsQuestion, chapter])

  useEffect(() => {}, [scoreValue])

  return (
    <>
      <ContainerRow style={{ paddingBottom: 40 }}>
        {currentQuestion === 0 && currentChapter === 1 && <BtnGoBack onClick={handleBackToStart}>{t('global.common.previousButtonText')}</BtnGoBack>}
        {currentQuestion !== 0 && <BtnGoBack onClick={handleGoBack}>{t('global.common.previousButtonText')}</BtnGoBack>}
        <div />
        <Button onClick={handleButtonOnClick} disabled={isDisable}>
          {isLoading ? t('global.common.loadingText') : buttonText}
        </Button>
      </ContainerRow>
      {currentQuestion === totalQuestionsPerChapter - 1 && currentChapter === totalChapters ? (
        <SendModal
          openModal={openModal}
          setOpenModal={setOpenModal}
          isLoading={isLoading}
          onSubmit={() => {
            if (!isLoading) handleFinish()
          }}
        />
      ) : null}
    </>
  )
}

export default QuestionNavigation
