import React, { ChangeEvent, FC, useState } from 'react'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import InputAdornment from '@mui/material/InputAdornment'
import Modal from 'views/components/UI/Modal'
import { TextField } from '@mui/material'
import BluesiteTableComponent from 'views/components/UI/BluesiteTable'
import IconMagnifying from 'assets/icons/iconMagnifying.png'
import {
  Container,
  GridContainer,
  HistoryButton,
  InputsContainer,
  MagnifyingGlass,
  SearchBox,
  SubContainer,
  Title,
} from './styled'
import { ISavedResponse, RNineBox } from 'views/screens/Platform-administrator/Analytics/constants/types'
import { useTranslation } from 'react-i18next'

interface IProps {
  data: ISavedResponse
  show: boolean
  close: (value: boolean) => void
  handlePagination: (page: number) => void
  search: (value: string) => void
  historySelectedNineBox: (nineBox: RNineBox) => void
  setSavedInput: (value: string) => void
  setIsCareerPlan: (value: boolean) => void
  setTypeNineBox: (value: string) => void
  savedNineBoxPage: number
}

const headers = [
  {
    key: 'name',
    name: 'Nombre',
  },
  {
    key: 'test',
    name: 'Prueba',
  },
  {
    key: 'type',
    name: 'Tipo',
  },
  {
    key: 'button',
    name: '',
  },
]

const HistoryNineBox: FC<IProps> = ({
  show,
  close,
  data,
  handlePagination,
  search,
  historySelectedNineBox,
  setSavedInput,
  setIsCareerPlan,
  setTypeNineBox,
  savedNineBoxPage,
}) => {
  const { t } = useTranslation()
  const [input, setInput] = useState('')

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    setInput(value)
  }

  const onSubmitSearch = () => {
    if (search && input !== '') {
      search(input)
    }
  }

  const onResetSearch = () => {
    setInput('')
    if (search) {
      search('')
    }
  }

  const handlePageChange = (page: number) => {
    if (handlePagination) {
      handlePagination(page)
    }
  }

  const handleSelectNineBox = (nineBox: RNineBox) => {
    if (nineBox?.career_plan) {
      setIsCareerPlan(true)
      setTypeNineBox('career')
    } else {
      setIsCareerPlan(false)
      setTypeNineBox('potential')
    }
    historySelectedNineBox(nineBox)
    setSavedInput(nineBox.name)
    if (search) {
      search('')
    }
  }

  const Button = ({ nineBox }: { nineBox: RNineBox }) => {
    return <HistoryButton onClick={() => handleSelectNineBox(nineBox)}>Ver</HistoryButton>
  }

  const dataArray = data?.nine_boxes?.map((item) => ({
    name: item.name,
    test: [item.is_betesa ? 'Betesa' : null, item.is_competences ? t('global.common.skillText') : null]
      .filter(Boolean)
      .join(', '),
    type: item.career_plan ? 'Para plan carrera' : 'Para potencial',
    id: item.id,
    button: <Button nineBox={item} />,
  }))

  return (
    <Modal styleContent={{ minWidth: '70%' }} show={show} setShow={close}>
      <Container>
        <SubContainer>
          <Title>Histórico de 9Box</Title>
          <InputsContainer>
            <TextField
              type="text"
              value={input}
              name="history"
              onChange={handleInputChange}
              label="Buscar 9Box"
              InputProps={
                input.length
                  ? {
                      endAdornment: (
                        <InputAdornment sx={{ cursor: 'pointer' }} onClick={onResetSearch} position="end">
                          <HighlightOffIcon />
                        </InputAdornment>
                      ),
                    }
                  : undefined
              }
              sx={{
                borderRadius: '8px 0 0 8px',
                boxShadow: '0px 3px 6px #00000029',
                width: '410px',
                '& .MuiOutlinedInput-notchedOutline': {
                  borderRadius: '8px 0 0 8px',
                },
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  onSubmitSearch()
                }
              }}
            />
            <SearchBox onClick={onSubmitSearch}>
              <MagnifyingGlass src={IconMagnifying} />
            </SearchBox>
          </InputsContainer>
          <GridContainer>
            <BluesiteTableComponent
              data={dataArray}
              totalPages={data?.total_pages}
              headers={headers}
              searchBar={false}
              showNumbers={true}
              handlePagination={handlePageChange}
              currentPage={savedNineBoxPage}
              searchLabel=""
            />
          </GridContainer>
        </SubContainer>
      </Container>
    </Modal>
  )
}

export default HistoryNineBox
