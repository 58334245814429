import {
  GraphicsContainer,
  LineGraphicsFrontLeft,
  LineGraphicsFrontRight,
  LineGraphicsBasalRight,
  LineGraphicsBasalLeft,
  FrontLeftCopy,
  FrontRightCopy,
  BasalRightCopy,
  BasalLeftCopy,
  LineGraphicsArea2,
  LineGraphicsArea,
  SubtitleGraphic,
  ContainerColumnGraphic,
  ContainerTextTableCenter,
  WrapperAlertLevelText,
} from './styled'
import * as math from 'mathjs'
import { useNameLevelAlert } from 'hooks/useNameLevelAlert'
import { Chart as ChartJS, RadialLinearScale, PointElement, LineElement, Filler, Tooltip, Legend } from 'chart.js'
import { PolarArea } from 'react-chartjs-2'
import { useTranslation } from 'react-i18next'

const plugin = {
  id: 'customCanvasBackgroundColor_3',
  beforeDraw: (chart: { width?: any; height?: any; ctx?: any }, args: any, options: { color: string }) => {
    const { ctx } = chart
    ctx.save()
    ctx.globalCompositeOperation = 'destination-over'
    ctx.fillStyle = options.color || 'transparent'
    ctx.fillRect(0, 0, chart.width, chart.height)
    ctx.restore()
  },
}

ChartJS.register(RadialLinearScale, PointElement, LineElement, Filler, Tooltip, Legend, plugin)

function getColor(value: number) {
  if (value >= 0 && value <= 40) {
    return '#CDCDCD' //cambia esto cuado se sepa que colores colocar
  } else if (value >= 41 && value <= 60) {
    return '#FF8402'
  } else if (value >= 61 && value <= 80) {
    return '#674531'
  } else if (value >= 81 && value <= 100) {
    return '#2C9430'
  } else if (value >= 101) {
    return '#BFC8D6'
  } else {
    return 'Valor fuera de rango'
  }
}

const LineCalculeHorizontal: any = ({ x, y, bottom_line = true, left_line = false, bordercolor }: any) => {
  const rx: any = left_line ? x / 2 : x / 2
  const ry: any = y / 2
  const rxValue: any = math.pow(rx, 2)
  const ryValue: any = math.pow(ry, 2)
  const width = math.sqrt(rxValue + ryValue)

  const xHeight = math.sin((45 * Math.PI) / 180) * rx
  const yHeight = math.sin((45 * Math.PI) / 180) * ry

  //const right = x < y ? (rx - ry + 3) :  (rx - ry - 3);
  const right = x > y ? Math.abs(width / 2 - xHeight) * 2 : Math.abs(width / 2 - xHeight) * -2
  //const right = Math.abs(rx - width/2) * -1;

  const top = bottom_line ? xHeight * 2 : xHeight * -2

  const a = Math.abs(xHeight - yHeight)
  const pi = Math.PI
  const Preangle = x < y ? (a / width) * (180 / pi) : (a / width) * (180 / pi) * -1
  const angle = bottom_line ? Preangle : Preangle * -1
  return (
    <LineGraphicsArea
      Bordercolor={`${bordercolor}`}
      right={`${right}%`}
      width={`${width}%`}
      top={`${top}%`}
      angle={`${angle}deg`}
    ></LineGraphicsArea>
  )
}

const LineCalculeVertical: any = ({ x, y, left_line = true, bordercolor }: any) => {
  const ry: any = y / 2
  const rx: any = x / 2
  const right = left_line ? math.sin((45 * Math.PI) / 180) * ry * 2 : math.sin((45 * Math.PI) / 180) * ry * -2
  const rxValue: any = math.pow(rx, 2)
  const ryValue: any = math.pow(ry, 2)
  const width: any = 1
  const height = math.sqrt(rxValue + ryValue)
  const xHeight = math.sin((45 * Math.PI) / 180) * rx
  const yHeight = math.sin((45 * Math.PI) / 180) * ry

  const top = x > y ? Math.abs(height / 2 - yHeight) * 2 : Math.abs(height / 2 - yHeight) * -2

  const a = Math.abs(xHeight - yHeight) / height
  const pi = Math.PI
  const Preangle = y > x ? a * (180 / pi) * -1 : (a / width) * (180 / pi) * 1
  const angle = left_line ? Preangle : (Preangle - 1) * -1

  return (
    <LineGraphicsArea2
      Bordercolor={`${bordercolor}`}
      right={`${right}%`}
      width={`${width}px`}
      height={`${height}%`}
      top={`${top}%`}
      angle={`${angle}deg`}
    ></LineGraphicsArea2>
  )
}
export const GraphicPolar = ({
  name,
  width,
  simple = false,
  nameUser = 'Andrea',
  title = '',
  Dominance,
  dominationData = '',
  IdealDominance,
  AlertLevel,
  IdealAlertLevel,
  userName,
  isDevelopmentPlan,
  showScore,
  showQuadrantName,
  Color = '#C6016B',
  IdealColor = '#2D749C',
  Profile,
  fontSize,
  lineGraphic = false,
  // eslint-disable-next-line @typescript-eslint/no-shadow
  IdealProfile = {},
}: any) => {
  const { t } = useTranslation()
  const FI = Profile.FI_raw_score > 100 ? math.round(Profile.FI_raw_score / 3) : Profile.FI_raw_score
  const BI = Profile.BI_raw_score > 100 ? math.round(Profile.BI_raw_score / 3) : Profile.BI_raw_score
  const BD = Profile.BD_raw_score > 100 ? math.round(Profile.BD_raw_score / 3) : Profile.BD_raw_score
  const FD = Profile.FD_raw_score > 100 ? math.round(Profile.FD_raw_score / 3) : Profile.FD_raw_score
  const { FI: IdealFi, FD: IdealFd, BI: IdealBi, BD: IdealBd } = IdealProfile

  const array = [Profile.FD, Profile.BD, Profile.BI, Profile.FI]

  const data = array.map(function (num) {
    if (num > 100) return 100
    else return num
  })
  const quadrantMapping = ['FD', 'BD', 'BI', 'FI']
  const backgroundColorArray = ['#D0CFCF', '#D0CFCF', '#D0CFCF', '#E8E8E8']

  const domIndex = quadrantMapping.indexOf(dominationData)
  if (domIndex !== -1) {
    backgroundColorArray[domIndex] = '#DD7AAF'
  }
  const data2 = {
    labels: [],
    datasets: [
      {
        data: data,
        backgroundColor: backgroundColorArray,
        borderWidth: 1,
      },
    ],
  }

  const options: any = {
    responsive: true,
    maintainAspectRatio: false,
    aspectRatio: 1,
    color: '#fff',
    scales: {
      r: {
        max: 100,
        min: 0,
        grid: {
          display: true,
        },
        ticks: {
          display: false,
        },
      },
    },
    elements: {
      arc: {
        borderWidth: 0.5,
      },
    },
    tooltip: {
      enabled: false,
    },
    plugins: {
      customCanvasBackgroundColor_3: {
        color: '#fff',
      },
      tooltip: {
        enabled: false,
      },
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
    },
  }

  const parsedAlertLevel = useNameLevelAlert(AlertLevel)

  return (
    <ContainerColumnGraphic>
      <SubtitleGraphic style={{ fontSize: fontSize ? 12 : '' }}>{title}</SubtitleGraphic>
      <GraphicsContainer width={width}>
        {!isDevelopmentPlan && !simple && !showScore && lineGraphic ? <></> : <></>}
        {!isDevelopmentPlan && !simple && !showScore && !lineGraphic ? (
          <>
            <LineCalculeHorizontal bordercolor={Color} bottom_line={true} x={BI} y={BD} />
            <LineCalculeHorizontal bordercolor={Color} bottom_line={false} x={FI} y={FD} />
            <LineCalculeVertical bordercolor={Color} left_line={true} x={BI} y={FI} />
            <LineCalculeVertical bordercolor={Color} left_line={false} x={BD} y={FD} />
          </>
        ) : (
          <></>
        )}
        {IdealProfile ? (
          <>
            <LineCalculeHorizontal bordercolor={IdealColor} bottom_line={true} x={IdealBi} y={IdealBd} />
            <LineCalculeHorizontal bordercolor={IdealColor} bottom_line={false} x={IdealFi} y={IdealFd} />
            <LineCalculeVertical bordercolor={IdealColor} left_line={true} x={IdealBi} y={IdealFi} />
            <LineCalculeVertical bordercolor={IdealColor} left_line={false} x={IdealBd} y={IdealFd} />
          </>
        ) : (
          <></>
        )}
        {isDevelopmentPlan ? null : (
          <>
            <LineGraphicsFrontLeft
              width={width ? `${parseInt(width) / 7}px` : '30px'}
              borderColor={getColor(Profile.FI_raw_score ? Profile.FI_raw_score : Profile.FI)}
            >
              {Profile.FI_raw_score ? Profile.FI_raw_score : Profile.FI}
            </LineGraphicsFrontLeft>
            <LineGraphicsFrontRight
              width={width ? `${parseInt(width) / 7}px` : '30px'}
              borderColor={getColor(Profile.FD_raw_score ? Profile.FD_raw_score : Profile.FD)}
            >
              {Profile.FD_raw_score ? Profile.FD_raw_score : Profile.FD}
            </LineGraphicsFrontRight>
            <LineGraphicsBasalRight
              width={width ? `${parseInt(width) / 7}px` : '30px'}
              borderColor={getColor(Profile.BD_raw_score ? Profile.BD_raw_score : Profile.BD)}
            >
              {Profile.BD_raw_score ? Profile.BD_raw_score : Profile.BD}
            </LineGraphicsBasalRight>
            <LineGraphicsBasalLeft
              width={width ? `${parseInt(width) / 7}px` : '30px'}
              borderColor={getColor(Profile.BI_raw_score ? Profile.BI_raw_score : Profile.BI)}
            >
              {Profile.BI_raw_score ? Profile.BI_raw_score : Profile.BI}
            </LineGraphicsBasalLeft>
          </>
        )}
        {showQuadrantName ? (
          <>
            <FrontLeftCopy>
              <p style={{ color: dominationData === 'FI' ? '#C6016B' : '#2D749C' }}>{t('betesaReport.graph.frontal')}</p>
              <span style={{ color: dominationData === 'FI' ? '#C6016B' : '#2D749C' }}>{t('betesaReport.graph.left')}</span>
            </FrontLeftCopy>
            <FrontRightCopy>
              <p style={{ color: dominationData === 'FD' ? '#C6016B' : '#2D749C' }}>{t('betesaReport.graph.frontal')}</p>
              <span style={{ color: dominationData === 'FD' ? '#C6016B' : '#2D749C' }}>{t('betesaReport.graph.right')}</span>
            </FrontRightCopy>
            <BasalLeftCopy>
              <p style={{ color: dominationData === 'BI' ? '#C6016B' : '#2D749C' }}>{t('betesaReport.graph.basal')}</p>
              <span style={{ color: dominationData === 'BI' ? '#C6016B' : '#2D749C' }}>{t('betesaReport.graph.left')}</span>
            </BasalLeftCopy>
            <BasalRightCopy>
              <p style={{ color: dominationData === 'BD' ? '#C6016B' : '#2D749C' }}>{t('betesaReport.graph.basal')}</p>
              <span style={{ color: dominationData === 'BD' ? '#C6016B' : '#2D749C' }}>{t('betesaReport.graph.right')}</span>
            </BasalRightCopy>
          </>
        ) : null}
        <PolarArea
          height={isDevelopmentPlan ? '450px' : '220px'}
          width={isDevelopmentPlan ? '450px' : '220px'}
          data={data2}
          options={options}
        />
      </GraphicsContainer>
      <p style={{ marginLeft: '60%' }}>{name}</p>
      <WrapperAlertLevelText>
        {Dominance && (
          <ContainerTextTableCenter width="100%">
            <div className="Subtitle">Dominancia deseada: {IdealDominance}</div>
            <div className="Subtitle">
              {`Dominancia de ${nameUser}:`}
              <p>{Dominance}</p>
            </div>
          </ContainerTextTableCenter>
        )}
        {AlertLevel && (
          <ContainerTextTableCenter width="100%">
            <div className="Subtitle" style={{ fontSize: fontSize ? fontSize : '' }}>
              {t('betesaReport.adjustmentLevelPage.idealAlertLevel')} {IdealAlertLevel}
            </div>
            <div className="Subtitle" style={{ fontSize: fontSize ? fontSize : '' }}>
              {t('betesaReport.adjustmentLevelPage.idealAlertLevelOf')} {userName}:
              <p
                style={{
                  fontSize: fontSize ? fontSize : '',
                  width: 'max-content',
                  textAlign: 'right',
                  marginLeft: '2px',
                }}
              >
                {t(parsedAlertLevel)}
              </p>
            </div>
          </ContainerTextTableCenter>
        )}
      </WrapperAlertLevelText>
    </ContainerColumnGraphic>
  )
}
