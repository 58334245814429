import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { IDataBetesaResults } from 'views/screens/Evaluated-User/store'
import { SubContainer } from '../../styled'
import BrainMetricsContainer from 'views/containers/BrainMetrics'
import getBetesaResult from 'services/results/betesa-results/get-betesa-result-by-test-id'
import { userSelector } from 'ducks/user'
import Loader from 'views/components/UI/Loader'

interface Props {
  testId: number
}

const ResultsTalents = ({ testId }: Props) => {
  const [isLoading, setIsLoading] = useState(false)
  const { userId } = useSelector(userSelector)
  const [userData, setUserData] = useState<IDataBetesaResults | null>(null)

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true)
      try {
        const data = await getBetesaResult(testId, userId)


        const betesaData = {
          fi: data.data.score_detail.development_scores.FI_raw_score,
          bi: data.data.score_detail.development_scores.BI_raw_score,
          bd: data.data.score_detail.development_scores.BD_raw_score,
          fd: data.data.score_detail.development_scores.FD_raw_score,
          dominantQuadrant: data.data.score_detail.thought_structure,
          childhood: data.data.childhood_and_youth,
        }

        setUserData(betesaData)
        setIsLoading(false)
      } catch (error) {
        console.error(error)
      }
    }
    fetchData()
  }, [testId, userId])

  return (
    <SubContainer width="100%" alignItems="center">
      {isLoading ? (
        <Loader />
      ) : (
        <BrainMetricsContainer
          showPolarArea
          result={{
            FI: userData?.fi || 0,
            BI: userData?.bi || 0,
            BD: userData?.bd || 0,
            FD: userData?.fd || 0,
          }}
          dominantQuadrant={userData?.dominantQuadrant || ''}
          childhood={userData?.childhood}
        />
      )}
    </SubContainer>
  )
}

export default ResultsTalents
