import { useState } from 'react'
import {
  GraphicsContainer,
  LineGraphicsFrontLeft,
  LineGraphicsFrontRight,
  LineGraphicsBasalRight,
  LineGraphicsBasalLeft,
  LineGraphicsArea2,
  LineGraphicsArea,
  SubtitleGraphic,
  ContainerColumnGraphic,
  ContainerTextTableCenter,
  BrakeFrontLeft,
  BrakeFrontRight,
  BrakeBasalLeft,
  BrakeBasalRight,
  ContainerCategorizer,
  Categorizer,
  Infocategorizer,
} from './styled'
import * as math from 'mathjs'
import { Chart as ChartJS, RadialLinearScale, ArcElement, Tooltip, Legend } from 'chart.js'
import calculateHomologateScore from 'utils/homologateSingleScore'
import { BETESA_MAX_GENERAL_SCORE, BETESA_MAX_SCORE_PER_CHAPTER } from 'constants/global.constants'
import { useTranslation } from 'react-i18next'

// eslint-disable

const plugin = {
  id: 'pluginCustom',
  beforeDraw: (chart: { width?: any; height?: any; ctx?: any }, args: any, options: { color: string }) => {
    const { ctx } = chart
    ctx.save()
    ctx.globalCompositeOperation = 'destination-over'
    ctx.fillStyle = options.color || 'transparent'
    ctx.fillRect(0, 0, chart.width, chart.height)
    ctx.restore()
  },
}

const LineCalculeHorizontal: any = ({ x, y, bottom_line = true, left_line = false, bordercolor }: any) => {
  const rx: any = left_line ? x / 2 : x / 2
  const ry: any = y / 2
  const rxValue: any = math.pow(rx, 2)
  const ryValue: any = math.pow(ry, 2)
  const width = math.sqrt(rxValue + ryValue)

  const xHeight = math.sin((45 * Math.PI) / 180) * rx
  const yHeight = math.sin((45 * Math.PI) / 180) * ry

  const right = x > y ? Math.abs(width / 2 - xHeight) * 2 : Math.abs(width / 2 - xHeight) * -2

  const top = bottom_line ? xHeight * 2 : xHeight * -2

  const a = Math.abs(xHeight - yHeight)
  const pi = Math.PI
  const Preangle = x < y ? (a / width) * (180 / pi) : (a / width) * (180 / pi) * -1
  const angle = bottom_line ? Preangle : Preangle * -1
  return (
    <LineGraphicsArea
      Bordercolor={`${bordercolor}`}
      right={`${right}%`}
      width={`${width}%`}
      top={`${top}%`}
      angle={`${angle}deg`}
    ></LineGraphicsArea>
  )
}

const LineCalculeVertical: any = ({ x, y, left_line = true, bordercolor }: any) => {
  const ry: any = y / 2
  const rx: any = x / 2
  const right = left_line ? math.sin((45 * Math.PI) / 180) * ry * 2 : math.sin((45 * Math.PI) / 180) * ry * -2
  const rxValue: any = math.pow(rx, 2)
  const ryValue: any = math.pow(ry, 2)
  const width: any = 1
  const height = math.sqrt(rxValue + ryValue)
  const xHeight = math.sin((45 * Math.PI) / 180) * rx
  const yHeight = math.sin((45 * Math.PI) / 180) * ry

  const top = x > y ? Math.abs(height / 2 - yHeight) * 2 : Math.abs(height / 2 - yHeight) * -2

  const a = Math.abs(xHeight - yHeight) / height
  const pi = Math.PI
  const Preangle = y > x ? a * (180 / pi) * -1 : (a / width) * (180 / pi) * 1
  const angle = left_line ? Preangle : (Preangle - 1) * -1

  return (
    <LineGraphicsArea2
      Bordercolor={`${bordercolor}`}
      right={`${right}%`}
      width={`${width}px`}
      height={`${height}%`}
      top={`${top}%`}
      angle={`${angle}deg`}
    ></LineGraphicsArea2>
  )
}

interface PropsCategoryzer {
  isDominant?: boolean
  isDeficient?: boolean
  brake?: number
  name: string
  result: number
  childhood: number
}

const Categorizers = (props: PropsCategoryzer) => {
  const {t} = useTranslation()
  const { isDominant, isDeficient, brake, name, result, childhood } = props
  let cuadrante

  if (name === t('global.common.betesa.quadrants.FI')) {
    cuadrante = t('global.common.betesa.quadrantsAbbr.FI')
  } else if (name === t('global.common.betesa.quadrants.FD')) {
    cuadrante = t('global.common.betesa.quadrantsAbbr.FD')
  } else if (name === t('global.common.betesa.quadrants.BD')) {
    cuadrante = t('global.common.betesa.quadrantsAbbr.BD')
  } else if (name === t('global.common.betesa.quadrants.BI')) {
    cuadrante = t('global.common.betesa.quadrantsAbbr.BI')
  } else {
    cuadrante = 'Cuadrante-' + name
  }
  const [showInfo, setShowInfo] = useState(false)

  const childhoodScore = {
    baseScore: childhood,
    maximumScore: BETESA_MAX_GENERAL_SCORE.childHood,
    maxScorePerChapter: BETESA_MAX_SCORE_PER_CHAPTER.childHood,
  }

  const homologateScore = calculateHomologateScore(
    childhoodScore.baseScore,
    childhoodScore.maximumScore,
    childhoodScore.maxScorePerChapter
  )

  return (
    <ContainerCategorizer>
      {showInfo && (
        <Infocategorizer>
          <div>{name}</div>
          <div>{t('evaluatedUser.analytics.results')} {result}</div>
          {brake ? <div>{t('evaluatedUser.analytics.gap')} {brake}</div> : ''}
          {childhood ? <div>{t('evaluatedUser.analytics.youth')} {homologateScore}</div> : ''}
        </Infocategorizer>
      )}

      <Categorizer
        background={isDominant ? '#C6016B' : '#FFFFFF'}
        color={isDominant ? '#FFFFFF' : '#000'}
        onMouseEnter={() => {
          if (!showInfo) {
            setShowInfo(true)
          }
        }}
        onMouseLeave={() => {
          if (showInfo) {
            setTimeout(() => {
              setShowInfo(false)
            }, 700)
          }
        }}
      >
        {isDominant && t('betesaReport.graph.dominance')}
        {isDeficient && t('betesaReport.graph.minor')}

        {!isDominant && !isDeficient && `${t('global.common.quadrantText')} ${'-'} ${cuadrante}`}
      </Categorizer>
    </ContainerCategorizer>
  )
}

// Main
const GraphicPolar = ({
  name,
  width,
  title = '',
  Dominance,
  IdealDominance,
  AlertLevel,
  profileIdeal,
  FI,
  BI,
  BD,
  FD,
  dominantQuadrant,
  childhood,
}: any) => {
  const { t } = useTranslation()
  const values = [FI, BI, BD, FD]
  values.sort((a, b) => a - b)
  const userColor = '#2D749C'

  const getColor = (value: number) => {
    if (value <= 40) {
      return '#F00101'
    } else if (value <= 60) {
      return '#FF9A02'
    } else if (value <= 80) {
      return '#674531'
    } else if (value > 80 && value <= 99) {
      return '#2C9430'
    } else if (value > 99) {
      return '#9E9E9E'
    } else {
      return 'gray'
    }
  }

  const profileDataValidate = {
    fi: FI,
    ColorFi: getColor(FI),
    fd: FD,
    ColorFd: getColor(BI),
    bi: BI,
    ColorBi: getColor(BD),
    bd: BD,
    ColorBd: getColor(FD),
    Color: '#C6016B',
  }

  const { fi, ColorFi, fd, ColorFd, bi, ColorBi, bd, ColorBd } = profileDataValidate

  const { FI: IdealFi, FD: IdealFd, BI: IdealBi, BD: IdealBd } = profileIdeal

  return (
    <ContainerColumnGraphic>
      <SubtitleGraphic>{title}</SubtitleGraphic>
      <GraphicsContainer width={width}>
        <>
          <LineCalculeHorizontal
            bordercolor={profileDataValidate.Color}
            bottom_line={true}
            x={BI > 100 ? 99 : BI}
            y={BD > 100 ? 99 : BD}
          />
          <LineCalculeHorizontal
            bordercolor={profileDataValidate.Color}
            bottom_line={false}
            x={FI > 100 ? 99 : FI}
            y={FD > 100 ? 99 : FD}
          />
          <LineCalculeVertical
            bordercolor={profileDataValidate.Color}
            left_line={true}
            x={BI > 100 ? 99 : BI}
            y={FI > 100 ? 99 : FI}
          />
          <LineCalculeVertical
            bordercolor={profileDataValidate.Color}
            left_line={false}
            x={BD > 100 ? 99 : BD}
            y={FD > 100 ? 99 : FD}
          />
          <>
            <LineCalculeHorizontal
              bordercolor={userColor}
              bottom_line={true}
              x={IdealBi > 100 ? 99 : IdealBi}
              y={IdealBd > 100 ? 99 : IdealBd}
            />
            <LineCalculeHorizontal
              bordercolor={userColor}
              bottom_line={false}
              x={IdealFi > 100 ? 99 : IdealFi}
              y={IdealFd > 100 ? 99 : IdealFd}
            />
            <LineCalculeVertical
              bordercolor={userColor}
              left_line={true}
              x={IdealBi > 100 ? 99 : IdealBi}
              y={IdealFi > 100 ? 99 : IdealFi}
            />
            <LineCalculeVertical
              bordercolor={userColor}
              left_line={false}
              x={IdealBd > 100 ? 100 : IdealBd}
              y={IdealFd > 100 ? 100 : IdealFd}
            />
          </>
        </>

        <LineGraphicsFrontLeft width={width ? `${parseInt(width) / 7}px` : '30px'} borderColor={getColor(fi)}>
          {fi}
        </LineGraphicsFrontLeft>
        <LineGraphicsFrontRight width={width ? `${parseInt(width) / 7}px` : '30px'} borderColor={getColor(fd)}>
          {fd}
        </LineGraphicsFrontRight>
        <LineGraphicsBasalRight width={width ? `${parseInt(width) / 7}px` : '30px'} borderColor={getColor(bd)}>
          {bd}
        </LineGraphicsBasalRight>
        <LineGraphicsBasalLeft width={width ? `${parseInt(width) / 7}px` : '30px'} borderColor={getColor(bi)}>
          {bi}
        </LineGraphicsBasalLeft>
        {true && ( // Object.keys(profileIdeal).length > 0
          <>
            <BrakeFrontLeft width={width ? `${parseInt(width) / 5.5}px` : '30px'} borderColor={ColorFi}>
              <Categorizers
                name={t('global.common.betesa.quadrants.FI')}
                result={FI}
                brake={parseInt((IdealFi - FI < 0 ? -(IdealFi - FI) : IdealFi - FI).toFixed(2))}
                isDominant={dominantQuadrant === 'FI'}
                isDeficient={dominantQuadrant === 'BD'}
                childhood={childhood?.FI}
              />
            </BrakeFrontLeft>
            <BrakeFrontRight width={width ? `${parseInt(width) / 5.5}px` : '30px'} borderColor={ColorFd}>
              <Categorizers
                name={t('global.common.betesa.quadrants.FD')}
                result={FD}
                brake={parseInt((IdealFd - FD < 0 ? -(IdealFd - FD) : IdealFd - FD).toFixed(2))}
                isDominant={dominantQuadrant === 'FD'}
                isDeficient={dominantQuadrant === 'BI'}
                childhood={childhood?.FD}
              />
            </BrakeFrontRight>
            <BrakeBasalLeft width={width ? `${parseInt(width) / 5.5}px` : '30px'} borderColor={ColorBi}>
              <Categorizers
                name={t('global.common.betesa.quadrants.BI')}
                result={BI}
                brake={parseInt((IdealBi - BI < 0 ? -(IdealBi - BI) : IdealBi - BI).toFixed(2))}
                isDominant={dominantQuadrant === 'BI'}
                isDeficient={dominantQuadrant === 'FD'}
                childhood={childhood?.BI}
              />
            </BrakeBasalLeft>
            <BrakeBasalRight width={width ? `${parseInt(width) / 5.5}px` : '30px'} borderColor={ColorBd}>
              <Categorizers
                name={t('global.common.betesa.quadrants.BD')}
                result={BD}
                brake={parseInt((IdealBd - BD < 0 ? -(IdealBd - BD) : IdealBd - BD).toFixed(2))}
                isDominant={dominantQuadrant === 'BD'}
                isDeficient={dominantQuadrant === 'FI'}
                childhood={childhood?.BD}
              />
            </BrakeBasalRight>
          </>
        )}
      </GraphicsContainer>
      <p>{name}</p>
      {Dominance && (
        <ContainerTextTableCenter width="100%">
          <div className="Subtitle">Dominancia deseada: {IdealDominance}</div>
          <div className="Subtitle">
            Dominancia del usuario<p>{Dominance}</p>
          </div>
        </ContainerTextTableCenter>
      )}
      {AlertLevel && (
        <ContainerTextTableCenter width="100%">
          <div className="Subtitle">Nivel de alerta deseado: {profileIdeal}</div>
          <div className="Subtitle">
            Nivel de alerta del usuario:<p>{AlertLevel}</p>
          </div>
        </ContainerTextTableCenter>
      )}
    </ContainerColumnGraphic>
  )
}

export default GraphicPolar

ChartJS.register(RadialLinearScale, ArcElement, Tooltip, Legend, plugin)
