/* eslint-disable camelcase */
import { AxiosResponse } from 'axios'
import axios from 'lib/axios'
import { BetesaSkill, Behavior, CreateBetesaSkill, UpdateBetesaSkill, IUser, CreateUserBatch } from 'types/common'

type ResponseCreateBetesaSkill = {
  data: {
    betesaSkill: BetesaSkill
    behaviors: Behavior[]
    status: 'OK' | 'error'
  }
}

/**
 * @description service to create a new betesa skill
 * @param {string} name
 * @param {number} company_id
 * @param {number} quadrant_id
 * @param {string} behaviorsDescription
 * @example createBetesaSkill('name', 1, 1, ['behavior1', 'behavior2'])
 * */
export async function createBetesaSkill({ name, company_id, quadrant_id, behaviorsDescription }: CreateBetesaSkill) {
  const PATH = `${process.env.REACT_APP_MAIN_API}/betesa_skill`
  try {
    const response: ResponseCreateBetesaSkill = await axios.post(PATH, {
      name,
      company_id,
      quadrant_id,
      behaviorsDescription,
    })
    return response.data
  } catch (error) {
    console.error(error)
    return { status: 'error' }
  }
}

export async function updateBetesaSkill({
  id,
  name,
  company_id,
  quadrant_id,
  behaviorsDescription,
}: UpdateBetesaSkill) {
  const PATH = `${process.env.REACT_APP_MAIN_API}/betesa_skill/update/${id}`
  try {
    const response: ResponseCreateBetesaSkill = await axios.put(PATH, {
      name,
      company_id,
      quadrant_id,
      behaviorsDescription,
    })
    return response.data
  } catch (error) {
    console.error(error)
    return { status: 'error' }
  }
}

interface ResponseGetBetesaSkills {
  data: {
    status: 'OK' | 'error'
    total_results: string
    total_pages: number
    betesaSkills: BetesaSkill[]
  }
}

interface FiltersGetBetesaSkills {
  id?: number
  name?: string
  search?: string
  companyId?: number
  quadrantId?: number
  limit?: number
  page?: number
}

export async function getBetesaSkills({
  id,
  name,
  companyId,
  quadrantId,
  search,
  limit,
  page,
}: FiltersGetBetesaSkills) {
  let PATH = `${process.env.REACT_APP_MAIN_API}/betesa_skill`

  if (id) PATH += `/${id}`
  if (name) PATH += `?name=${name}`
  if (companyId) PATH += `${PATH.includes('?') ? '&' : '?'}company_id=${companyId}`
  if (quadrantId) PATH += `${PATH.includes('?') ? '&' : '?'}quadrant_id=${quadrantId}`
  if (search) PATH += `${PATH.includes('?') ? '&' : '?'}search=${search}`
  if (limit) PATH += `&limit=${limit}`
  if (page) PATH += `&page=${page}`

  try {
    const response: ResponseGetBetesaSkills = await axios.get(PATH)
    return response.data
  } catch (error) {
    console.error(error)
    return { status: 'error', total_results: '0', total_pages: 0, betesaSkills: [] }
  }
}

export const getDetailInfoRoleFamily = async (id: any) => {
  const res = await axios
    .get(`/role_family/settings/${id}`)
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      return { ok: false, message: error.message }
    })
  return res
}

interface FiltersGetUsers {
  statusInfo?: boolean
  role?: number[]
  userId?: number
  companyId?: number
  search?: string
  document?: string
  documentsArray?: string[]
  email?: string
  process_id?: number
  limit?: string
  page?: string
}

interface DataSendExam {
  users_data: UserDataProps[]
  company_id: number
  process_id: number
  type: string
  count?: number
  email_template: {
    textOne?: string
    textTwo?: string
    textThree?: string
    textFour?: string
    textThreeOptions?: string[]
    date?: string
    logo?: string
  }
}

interface UserDataProps {
  user_id: number
  language?: string
}

export const sendTestsToUsers = async (data: DataSendExam) => {
  const PATH = `/exam/send`
  const res = await axios
    .post(PATH, data)
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      return { status: 'error', message: error.message }
    })
  return res
}

interface ResponseGetUsers {
  status: string
  users: IUser[]
  total_pages: number
  total_results: number
}

export const GetInfoThisUser = async () => {
  const PATH = `/user/one?active=false`
  const res = await axios
    .get(PATH)
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      return { status: 'error', message: error.message }
    })
  return res
}

interface BodyUsersBatch {
  users: CreateUserBatch[]
}

interface ResponseCreateUsersBatch {
  status: 'OK' | 'error'
  data: {
    failedUsers: CreateUserBatch[]
    createdUsers: CreateUserBatch[]
    faild_users_file: string
  }
}

export const createMultipleUsersService = async (data: BodyUsersBatch) => {
  const PATH = `/process/create/users`
  const res: ResponseCreateUsersBatch = await axios
    .post(PATH, data)
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      return { status: 'error', message: error.message }
    })
  return res
}

const getUsers = async (filters: FiltersGetUsers): Promise<ResponseGetUsers> => {
  let PATH = `/user`

  if (filters.page) PATH += `?page=${filters.page}`
  if (filters.limit) PATH += `${PATH.includes('?') ? '&' : '?'}limit=${filters.limit}`

  delete filters.page
  delete filters.limit

  try {
    const response: AxiosResponse<ResponseGetUsers> = await axios.post(PATH, filters)
    return response.data
  } catch (error) {
    console.error('Error getting users', error)
    return { status: 'error', users: [], total_pages: 0, total_results: 0 }
  }
}

export const updateLanguage = async (processId: number, userId: number, language: string) => {
  const PATH = `/process/language/update/${userId}`

  const body = {
    process_id: processId,
    language,
  }
  try {
    const response = await axios.put(PATH, body)
    return response.data
  } catch (error) {
    console.error('Error updating language', error)
    return { status: 'error' }
  }
}

export default getUsers
