import jsPDF from 'jspdf'
import { useState, useEffect, FC, useRef } from 'react'
import { fetchReportBetesa } from 'services/report'
import { ReportBetesaSchema } from 'views/components/report/ReportBetesaSchema'
import { ReportSchema } from 'views/components/report/skill-report-schema'
import { Resume } from 'services/skills-report/resume'
import { Groups } from 'services/skills-report/groups'
import { GeneralTextByDimensions } from 'services/skills-report/general-text-by-dimensions'
import { DimensionScore } from 'services/skills-report/dimension-score'
// import postReportBetesa from 'services/report/fetchPostReport'
import FetchPercentils from 'services/report/fetchPercentils'
import { ReportData } from '../../../../components/preview-generate-report/store'
import useCompanyLogo from 'hooks/cobranding/get-company-logo/get-company-logo-hook'
import { useDispatch } from 'react-redux'
import { FIRST_TOKEN } from 'constants/global.constants'
import { IdealProfile } from 'services/skills-report/ideal-profile'
import { fetchSendBase64 } from './services/fetch-send-base64'

const GenerateReportsPdf: FC = () => {
  const dispatch = useDispatch()
  const [betesa, setBetesa] = useState({} as any)
  const [resume, setResume] = useState({} as any)
  const [groups, setGroups] = useState({} as any)
  const [idealProfile, setIdealProfile] = useState({} as any)
  const [generalTextByDimensions, setGeneralTextByDimensions] = useState({} as any)
  const [dimensionScore, setDimensionScore] = useState({} as ReportData)
  const [isReady, setIsReady] = useState(false)
  const [percentilData, setPercentilData] = useState({} as any)
  const [hasCalledGetLogo, setHasCalledGetLogo] = useState(false)
  const reportTemplateRef = useRef<HTMLDivElement>(null)
  const searchParams = new URLSearchParams(window.location.search)
  const userId = searchParams.get('user_id')
  const testId = searchParams.get('test_id')
  const userTestId = searchParams.get('user_test_id')
  const params = Object.fromEntries(searchParams)
  const token: string = params.token
  const isAdminForReport: boolean = searchParams.get('is_admin_report') === 'true'
  const [type] = useState(searchParams.get('type') ?? '')
  const dni = searchParams.get('dni')
  const firstName = searchParams.get('first_name')
  const lastName = searchParams.get('last_name')
  const tokenMidasoft = searchParams.get('token_midasoft') ?? ''

  localStorage.setItem(FIRST_TOKEN, token)
  const { getCompanyLogo } = useCompanyLogo()

  const [userInfo, setUserInfo] = useState({
    userId: 0,
    testId: 0,
    userTestId: 0,
    token: '',
    type: '',
  })

  const { process_id: processId } = type === 'skills' ? resume : betesa

  useEffect(() => {
    if (userId && testId && userTestId && token && type) {
      const newUserInfo = {
        userId: Number(userId),
        testId: Number(testId),
        userTestId: Number(userTestId),
        token,
        type,
      }

      setUserInfo(newUserInfo)
    }
  }, [userId, testId, userTestId, token, type])

  useEffect(() => {
    setTimeout(() => {
      if (!hasCalledGetLogo && token && processId) {
        setHasCalledGetLogo(true)
        getCompanyLogo({ processId: processId, isBase64: true, token })
      }
    }, 100)
  }, [dispatch, getCompanyLogo, processId, token, hasCalledGetLogo])

  useEffect(() => {
    setTimeout(() => {
      setIsReady(false)
      if (type === 'betesa' && token && userId && testId) {
        fetchReportBetesa(Number(userId), Number(testId))
          .then((res: any) => {
            if (res.data) {
              setBetesa(res.data)
              setIsReady(true)
            }
          })
          .catch((err) => {
            setIsReady(false)
            console.error(err)
          })
      } else if (type === 'skills' && token && userTestId && userId) {
        Promise.all([
          Resume(token, Number(userTestId)),
          Groups(token, Number(userTestId), false),
          GeneralTextByDimensions(token),
          FetchPercentils(token, Number(userId), Number(testId)),
        ])
          .then(([resResume, resGroups, resGeneralTextByDimensions, resPercentilData]) => {
            setResume(resResume.data)
            setGroups(resGroups.data)
            setGeneralTextByDimensions(resGeneralTextByDimensions.data)
            setPercentilData(resPercentilData)
            setIsReady(true)
          })
          .catch((err) => {
            setIsReady(false)
            console.error(err)
          })
      }
    }, 100)
  }, [token, type, userId, testId, userTestId])

  const generatePDF = async (reportTemplateRefLocal: React.RefObject<HTMLDivElement>) => {
    return new Promise(async (resolve) => {
      const doc = new jsPDF({
        orientation: 'p',
        unit: 'px',
        format: [595, 842],
        compress: true,
        precision: 2,
      })
      doc.html(reportTemplateRefLocal.current !== null ? reportTemplateRefLocal.current : '', {
        async callback(pdfDoc: any) {
          const numberOfPages = pdfDoc.internal.pages.length - 1
          if (numberOfPages > 1) {
            pdfDoc.deletePage(numberOfPages)
          }
  
          const pdfBlob = await pdfDoc.output('blob')
          const pdfBlobWithType = new Blob([pdfBlob], { type: 'application/pdf' })
  
          // Create a FileReader object
          const reader = new FileReader()
          // Convert the blob to base64
          reader.onloadend = async () => {
            if (reader.result) {
              const base64String = reader.result.toString();
  
              const nameFile = `${firstName}_${lastName}_${userTestId}_${type === 'betesa' ? 'Betesa' : '3DSkils'}.pdf`
              const body = {
                docto_ident: dni ?? '',
                nombre: firstName ?? '',
                apellido: lastName ?? '',
                file: base64String ?? '',
                filetype: 'application/pdf',
                nameFile,
                tokenMidasoft,
              }
              await fetchSendBase64(body)
              resolve(base64String);
            }
          }
          reader.readAsDataURL(pdfBlobWithType)
        },
      })
    })
  }

  const handleGeneratePdf = async (reportTemplate: React.RefObject<HTMLDivElement>) => {
    if (userId && testId) {
      setTimeout(async () => {
        await generatePDF(reportTemplate)
      }, 12000)
    }
  }

  useEffect(() => {
    if (isReady) {
      setTimeout(async () => {
        if (type === 'skills') {
          await DimensionScore(String(token), userInfo.userId, userInfo.testId).then((res) => {
            setDimensionScore(res.data)
          })
        }
        handleGeneratePdf(reportTemplateRef)
      }, 500)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isReady])

  useEffect(() => {
    if (isReady && isAdminForReport) {
      setTimeout(async () => {
        await IdealProfile(token, Number(userTestId)).then((res) => {
          setIdealProfile(res.data)
        })
      }, 100)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isReady])

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        width: '794px',
        alignSelf: 'center',
        height: '100%',
        overflow: 'scroll',
        margin: '0 auto',
      }}
    >
      {type === 'skills' && isReady && resume && (
        <div ref={reportTemplateRef}>
          <ReportSchema
            resume={resume}
            groups={groups}
            generalTextByDimensions={generalTextByDimensions}
            dimensionScore={dimensionScore}
            isAdmin={isAdminForReport}
            idealProfile={isAdminForReport ? idealProfile : null}
            percentil={percentilData}
            token={String(token)}
          />
        </div>
      )}
      {type === 'betesa' && isReady && betesa && (
        <div ref={reportTemplateRef}>
          <ReportBetesaSchema data={betesa} isAdmin={isAdminForReport} />
        </div>
      )}
    </div>
  )
}

export default GenerateReportsPdf
