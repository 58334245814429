import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`

export const InputsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
`

export const InputContainer = styled.div`
  width: 48%;
  margin-top: 10px;
  margin-bottom: 20px;
`

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 25px;
`

export const ErrorContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-;
  margin-top: 10px;
`

export const Error = styled.span`
  color: red;
  font-size: 12px;
`

export const TextArea = styled.textarea`
  font-family: 'Public Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  margin-top: 20px;
  width: 100%;
  height: 120px;
  padding: 15px;
  background: #ffffff;
  border: 1px solid rgba(145, 158, 171, 0.2);
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  resize: none;
  outline: none;
`
